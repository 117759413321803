<section>
  <div class="wrapper">
    <h3> {{ 'commun.jt' | translate }} {{ 'article.Prod.Pro' | translate }}</h3>
    <div *ngIf="showAlert" class="alert alert-success" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-check-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <span class="alert-text">
            {{ 'commun.jd' | translate }}
          </span>
        </div>
      </div>
    </div>
    <br>
    <div class="alert alert-danger" role="alert" *ngIf="tailleInvalid">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-check-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <span class="alert-text">
            {{ 'commun.fv' | translate }}
          </span>
        </div>
      </div>
    </div>
    <br>
    <form clrForm>











      <div class="wrapper1-client">
        <clr-input-container class="clrInput">
          <label> {{ 'login.VotreNom' | translate }} *</label>
          <input placeholder=" {{ 'login.VotreNom' | translate }}" clrInput [(ngModel)]="model.name" name="name"
            required />
          <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
        </clr-input-container>

        <!-- <clr-input-container class="clrInput">
          <label>{{ 'article.Prod.Réference' | translate }} </label>
          <input placeholder="{{ 'article.Prod.Réference' | translate }} " [(ngModel)]="model.reference"
            name="reference" clrInput />
        </clr-input-container> -->
        <!-- <clr-input-container class="clrInput">
          <label>Code à barre</label>
          <input placeholder="code à barre" [(ngModel)]="model.bar_code" name="bar_code" clrInput />
        </clr-input-container> -->

        <div class="cmb-wrapper">
          <!-- <clr-input-container *ngIf="model.categoryId" class="clrInput" class="clrInput">
                <label> {{ 'article.Mcatg.catg' | translate }} *</label>
                <input clrInput name="nameCategory" readonly [ngModel]="model.categoryId.name" />
                <clr-control-helper class="mt-0">
                  <button class="btn btn-link btn-sm mt-0" (click)="editCategory()">
                    {{ 'commun.mod' | translate }} {{ 'article.Mcatg.catg' | translate }}
                  </button>
                </clr-control-helper>
              </clr-input-container> -->
          <clr-combobox-container class="clrInput">
            <label> {{ 'article.Mcatg.catg' | translate }} *</label>
            <clr-combobox name="categoryId" required [clrLoading]="loading"
              (clrInputChange)="onSearchChange($event,'category')" placeholder="Select category">
              <clr-options *ngIf="categories">
                <clr-option *ngFor="let item of categories;" clrValue={{item.name}}
                  (click)="itemChanged(item,'category')">{{item?.name}}
                </clr-option>
              </clr-options>
              <clr-control-error> {{ 'reg.ob' | translate }} </clr-control-error>
            </clr-combobox>
          </clr-combobox-container>
          <clr-control-helper>
            <button class="btn btn-link btn-sm" (click)="AddCategoriePopUp()" style="margin-left: 92px;">
              {{ 'commun.jt' | translate }} {{ 'article.Mcatg.catg' | translate }}
            </button>
          </clr-control-helper>
        </div>

        <!-- <div class="cmb-wrapper">

          <clr-combobox-container class="clrInput">
            <label> {{ 'article.Marque.Mrq' | translate }} *</label>
            <clr-combobox name="markId" required [clrLoading]="loading" [ngModel]="selectedMark"
              (clrInputChange)="onSearchChange($event,'mark')" placeholder="Select marks">
              <clr-options *ngIf="marks">
                <clr-option *ngFor="let item of marks;" clrValue={{item.name}}
                  (click)="itemChanged(item.id,'mark')">{{item?.name}}
                </clr-option>
              </clr-options>
              <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
            </clr-combobox>
          </clr-combobox-container>
          <clr-control-helper>
            <button class="btn btn-link btn-sm" (click)="AddMarquePopUp()" style="margin-left: 92px;">
              {{ 'commun.jt' | translate }} {{ 'article.Marque.Mrq' | translate }}
            </button>
          </clr-control-helper>

        </div> -->

         <div class="cmb-wrapper">

          <clr-combobox-container class="clrInput">
            <label> {{ 'article.unites.unite' | translate }} *</label>
            <clr-combobox name="unityId" required [clrLoading]="loading" [ngModel]="selectedUnity"
              (clrInputChange)="onSearchChange($event,'unity')" placeholder="Select marks">
              <clr-options *ngIf="unities">
                <clr-option *ngFor="let item of unities;" clrValue={{item.name}}
                  (click)="itemChanged(item.id,'unity')">{{item?.name}}
                </clr-option>
              </clr-options>
              <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
            </clr-combobox>
          </clr-combobox-container>
          <clr-control-helper>
            <button class="btn btn-link btn-sm" (click)="AddUnityPopUp()" style="margin-left: 92px;">
              {{ 'commun.jt' | translate }} {{ 'article.unites.unite' | translate }}
            </button>
          </clr-control-helper>

        </div> 


        <!-- <clr-input-container class="clrInput">

          <label>Nombre de palettes *</label>
          <input type="number" placeholder="Nombre de palettes" clrInput [(ngModel)]="model.numberOfPalettes" name="palette" required />
          <clr-control-error>Nombre de palettes est requis.</clr-control-error>
        </clr-input-container>
        
        <clr-input-container class="clrInput">
          <label>Nombre de produits dans paquet  *</label>
          <input type="number" placeholder="Nombre de produits dans paquet *" clrInput [(ngModel)]="model.numberOfProductsInPackage" name="packet" required />
          <clr-control-error>Nombre de paquets est requis.</clr-control-error>

        </clr-input-container> -->
        

<!-- =======
        </clr-input-container>
        


       
        
      </div>






























      <div class="wrapper2-client">
        <clr-input-container class="clrInput">
          <label> {{ 'article.Prod.stocksecur' | translate }}</label>
          <input clrInput type="number" [(ngModel)]="model.stock_security" name="stock_security" min="0" />
        </clr-input-container>
>>>>>>> c7149b4f0db249a76daafc78b9c370c6b297c100 -->
        <clr-combobox-container class="clrInput">
          <label> {{ 'article.taxe.Taxe' | translate }} % *</label>
          <clr-combobox name="taxId" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event,'tax')"
            [ngModel]="selectedTax" placeholder="Select taxs">
            <clr-options *ngIf="taxs">
              <clr-option *ngFor="let item of taxs;" clrValue={{item.percentage}} (click)="itemChanged(item.id,'tax')">
                {{item?.name}} ({{item?.percentage}} %)
              </clr-option>
            </clr-options>
            <clr-control-error> {{ 'reg.ob' | translate }} </clr-control-error>
          </clr-combobox>
        </clr-combobox-container>

    
        
      </div>






      <div class="wrapper2-client">
        <!-- <clr-input-container class="clrInput">
          <label> {{ 'article.Prod.stocksecur' | translate }}</label>
          <input clrInput type="number" [(ngModel)]="model.stock_security" name="stock_security" min="0" />
        </clr-input-container> -->
     


 


      </div>
      <!-- <div *ngIf="!configuration.price_calc" class="wrapper2-client">

        <clr-input-container class="clrInput">
          <label> {{ 'article.Prod.Prixbuy' | translate }} *</label>
          <input clrInput type="number" [(ngModel)]="model.priceBuy" name="price" min="0" required />
          <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
        </clr-input-container>
        <clr-input-container class="clrInput">
          <label> {{ 'article.Prod.Prix' | translate }} *</label>
          <input clrInput type="number" [(ngModel)]="model.price" name="price" min="0" required />
          <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
        </clr-input-container>
      </div>
      <div *ngIf="configuration.price_calc" class="wrapper2-client">
        <clr-input-container class="clrInput">
          <label> Prix achat totale *</label>
          <input clrInput type="number" [(ngModel)]="model.priceBuyProduct" name="price" min="0" required />
        </clr-input-container>
        <clr-input-container class="clrInput">
          <label> Remise produit *</label>
          <input clrInput type="number" [(ngModel)]="model.remise_product" name="remise_product" min="0" required />
        </clr-input-container>
        <clr-input-container class="clrInput">
          <label> Pourcentage *</label>
          <input clrInput type="number" [(ngModel)]="model.percentage" name="percentage" min="0" required />
        </clr-input-container>

      </div> -->




















      <clr-step-title> {{ 'commun.ig' | translate }} </clr-step-title>
      <clr-step-content>
        <div class="clr-row" *ngIf="!pictureDetails">
          <div class="clr-col-12 clr-col-sm-12	clr-col-md-4	clr-col-lg-4	clr-col-xl-2">
            <a (click)="fileInput.click()" class="card">
              <clr-icon class="plus-icon" shape="plus-circle"></clr-icon>
            </a>
            <input hidden type="file" #fileInput (change)="picked($event)">
          </div>
          <div *ngFor="let item of pictureArray ;let i=index"
            class="clr-col-12 clr-col-sm-3	clr-col-md-4	clr-col-lg-4	clr-col-xl-2   img">
            <div class="card clickable" (click)="pictureDetailAction(item,i)">
              <div class="card-img container">
                <div class="clr-row clr-justify-content-between">
                  <div class="clr-col-4">
                    <span class="label label-success" *ngIf="item.defaults == true"> {{ 'commun.prc' | translate }}
                    </span>
                  </div>
                  <div class="clr-justify-content-end">
                    <span>
                      <button type="button" class="btn btn-icon btn-warning btn-sm delete-icon" aria-label="warning"
                        (click)="deletePicAction(item)">
                        <clr-icon shape="times"></clr-icon>
                      </button>
                    </span>
                  </div>
                </div>
                <img [src]="item.url">
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="pictureDetails">
          <div class="clr-col-12 clr-col-sm-12 clr-col-md-6 clr-col-lg-4 clr-col-xl-3">
            <div class="card">
              <div class="card-img container">
                <img [src]="pictureString.url" class="img-class">
              </div>
            </div>
          </div>
          <form clrForm>
            <clr-checkbox-container>
              <label> {{ 'commun.ig' | translate }} {{ 'commun.prc' | translate }}</label>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="defaults" [(ngModel)]="pictureArray[pictureIndex].defaults"
                  [disabled]="desableDefCheck" />
                <label>*{{ 'commun.icp' | translate }}</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
        
            <button class="btn btn-primary btn-sm" type="submit" (click)="submitPictureDetails()">{{ 'reg.e' | translate
              }}</button>
          </form>
        </div>
      </clr-step-content>












































































      <br><br>

      <label style="font-weight: bold;" class="clrInput">
        Afficher dans ecommerce <input type="checkbox" name="showInEcommerce" [(ngModel)]="model.showInEcommerce"> 
      </label>
    </form>
    <div class="champOb">
      <span><strong>* : {{ 'reg.ob' | translate }}</strong></span>
    </div>

    <div class="btn-group btn-outline-primary">
      <button class="btn" routerLink="/catalog/product">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
          class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
          <path
            d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
        </svg> {{ 'login.tt' | translate }}
      </button>
      <button class="btn" (click)="submit()" *ngIf="!creerNouveau"

        [disabled]=" !model.categoryId || !model.name || !model.taxId ">

        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-plus" viewBox="-1 -4 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> {{ 'reg.e' | translate }}
      </button><!--!model.unityId--> 
      <button class="btn" (click)="annuler()" *ngIf="creerNouveau">
        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-plus" viewBox="-1 -4 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> {{ 'commun.crn' | translate }}
      </button>
    </div>
  </div>
</section>
<app-add-category-modal *ngIf="showAddCategory" (closed)="CloseAction()" (saved)="AddCategory($event)">
</app-add-category-modal>
<app-add-marque-modal *ngIf="showAddMarque" (closed)="CloseAction()" (saved)="AddMarque($event)"></app-add-marque-modal>
<app-add-unity-modal *ngIf="showAddUnite" (closed)="CloseAction()" (saved)="AddUnity($event)"></app-add-unity-modal>
<app-add-tax-modal *ngIf="showAddTaxe" (closed)="CloseAction()" (saved)="AddTaxe()"></app-add-tax-modal>