<div class="alert alert-danger" role="alert" *ngIf="alertError">
    <div class="alert-items">
        <div class="alert-item static">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            <span class="alert-text">
                {{alertMsg}}
            </span>
        </div>
    </div>
</div>
<div [formGroup]="productCommandeForm">
    <clr-combobox-container>
        <label>{{'pack.produit' | translate}} / Service *</label>
        <clr-combobox [class.custom-disabled]="editElement" [(ngModel)]="productName" name="name" required
            [clrLoading]="loading" formControlName="productName" (clrInputChange)="onSearchChangeProduct($event)"
            placeholder="Nom de produit">
            <clr-options>
                <clr-option *ngFor="let item of commandProduct;" (click)="productChanged(item)" clrValue={{item.name}}>
                    {{item?.name}}
                </clr-option>
            </clr-options>
        </clr-combobox>
        <clr-control-error>{{'pack.champobligatoire' | translate}}</clr-control-error>
    </clr-combobox-container>
    <clr-input-container>
        <label>{{'pack.quantite' | translate}} *</label>
        <input clrInput type="number" min="1" [(ngModel)]="elementSelected.quantity" name="quantity"
            formControlName="quantity" />
    </clr-input-container>
    <br><br>
    <button class="btn btn-outline btn-sm" (click)="addProduct()"
        [disabled]="(!elementSelected.productId ) || !elementSelected.quantity">
        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-plus" viewBox="-1 -4 16 16">
            <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>{{'pack.valider' | translate}}
    </button>
    <clr-datagrid *ngIf="listCommandProduct.length>0" class="overflow-x" (clrDgRefresh)="refresh()">
        <clr-dg-column>
            {{'pack.produit' | translate}}
        </clr-dg-column>
        <clr-dg-column>
            {{'pack.quantite' | translate}}
        </clr-dg-column>
        <clr-dg-row *ngFor="let item of listCommandProduct;let i=index">
            <clr-dg-action-overflow>
                <button class="action-item" (click)="EditProduct(item)">{{'sales.modification' |
                    translate}}</button>
                <button class="action-item" (click)="DeleteProduct(item)">{{'pack.suppression' |
                    translate}}</button>
            </clr-dg-action-overflow>
            <clr-dg-cell>{{item.name}}</clr-dg-cell>
            <clr-dg-cell>{{item.quantity}}</clr-dg-cell>
        </clr-dg-row>
    </clr-datagrid>
</div>
<br>
<div class="btn-group btn-outline-primary">
    <button *ngIf="!listProduct" class="btn" routerLink="../">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
            class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
            <path
                d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
        </svg> {{'pack.retour' | translate}}
    </button>
    <button *ngIf="listProduct?.length > 0" class="btn" routerLink="../..">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
            class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
            <path
                d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
        </svg> {{'pack.retour' | translate}}
    </button>
    <button class="btn" [disabled]="saved" (click)="submit()" *ngIf="listCommandProduct.length > 0">
        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-plus" viewBox="-1 -4 16 16">
            <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> {{'pack.enregistrer' | translate}}
    </button>
</div>