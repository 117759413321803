import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessGuard } from '../auth/access.guard';
import { AuthGuard } from '../auth/auth.guard';
import { CommandeAddComponent } from './commande/commande-add/commande-add.component';
import { CommandeComponent } from './commande/commande.component';
import { CommandeEditComponent } from './commande/commande-edit/commande-edit.component';

const routes: Routes = [
  { path: 'commande', component: CommandeComponent, data: { abonnements: ["trial", "facturation_only", "facturation_caisse", "premuim", "livraison"] }, canActivate: [AuthGuard, AccessGuard] },
  { path: 'commande/add', component: CommandeAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_caisse", "premuim", "livraison"] }, canActivate: [AuthGuard, AccessGuard] },
  { path: 'commande/edit/:id', component: CommandeEditComponent, data: { abonnements: ["trial", "facturation_only", "facturation_caisse", "premuim", "livraison"] }, canActivate: [AuthGuard, AccessGuard] },
];
 
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommandeRoutingModule { }
