import { Component, HostListener, OnInit } from '@angular/core';
import { PictureAccount, PictureObject, Contact, PostUser } from '../../user';
import { SettingService } from '../../../setting/setting.service';
import { QuotationService } from '../../../sales/quotation/quotation.service';
import { ClrLoadingState } from '@clr/angular';
import { NgForm } from '@angular/forms';
import { ConfigService } from 'src/app/shared/config/config.service';
import { Crates } from 'src/app/crates/crates';
import { CratesService } from 'src/app/crates/crates.service';
import { FilterDto } from 'src/app/filter.dto';
import { Entreprise } from '../../entreprise';
import { Points } from 'src/app/sales-point/points';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { Truck } from 'src/app/transport/truck/truck';
import { TruckService } from 'src/app/transport/truck/truck.service';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from '../../user.service';
import * as intlTelInput from 'intl-tel-input';
import { Autorisation } from '../../autorisation/autorisation';
import { AutorisationService } from '../../autorisation/autorisation.service';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  pictureArray: PictureObject[] = [];
  showAlert: boolean;
  contact: Contact = new Contact()
  validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
  fileSize: number;
  url: string
  formatInvalid: boolean;
  tailleInvalid: boolean
  noImage: boolean;
  success: boolean;
  usedMail: boolean;
  addNew: boolean;
  userArray = []
  noCnx: boolean
  erreurMsg: string
  idAbonnement: number;
  id_entreprise: number
  current_user: number
  real_id: number
  crates: Crates[]
  points: Points[] = []
  loading: boolean = false
  selectedCrates: Crates
  selectedPoint: Points;
  filter: FilterDto = new FilterDto()
  saisie: string
  entrepriseArray: Entreprise[] = []
  showAddCaisse: boolean = false
  showAddPoint: boolean = false
  showAddTruck: boolean = false
  trucks: Truck[] = []
  selectedTruck: Truck
  type_abn: string = ''
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link: string = ""
  dialCode: string
  phoneModel: string
  isValidNumber: boolean
  contactTel: string


  constructor(private settingService: SettingService, private quotationService: QuotationService,
    private configService: ConfigService, private cratesService: CratesService,
    private salespointService: SalesPointService, private truckService: TruckService,
    private router: Router, private _autorisationService: AutorisationService) {
  }
  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.current_user = parseInt(getValueFromToken("id"));
    this.type_abn = getValueFromToken("type_abn");
    this.quotationService.getUser({}).subscribe(
      data => {
        this.userArray = data[0];
        this.real_id = data[1] + 1;
      })
    this.getCrates({ where: { "entrepriseId": this.id_entreprise } });
    this.getEntrepriseAccounts({ where: { "id": this.id_entreprise } });
    this.getPoints({ where: { "entrepriseId": this.id_entreprise } })
    this.idAbonnement = parseInt(getValueFromToken('idAbonnement'))
    this.getTrucks({ where: { "entrepriseId": this.id_entreprise } })
  }
  ngAfterViewInit(): void {
    const inputElement = document.getElementById("phone")
    if (inputElement) {
      const iti = intlTelInput(inputElement, {
        initialCountry: 'TN',
        separateDialCode: true,
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.0/js/utils.js'
      });
      this.dialCode = iti.getSelectedCountryData().dialCode
      inputElement.addEventListener('countrychange', () => {
        this.dialCode = iti.getSelectedCountryData().dialCode
      });
      inputElement.addEventListener('change', () => {
        if (this.phoneModel.trim()) {
          if (!iti.isValidNumber()) {
            this.isValidNumber = false
          } else {
            this.isValidNumber = true
            this.contactTel = this.dialCode + this.phoneModel
          }
        }
        this.verif_tel()
      })
    }
    this.showtab()
  }
  showtab() {
    var elements = Array.from(document.getElementsByClassName('clr-col-md-10'))
    elements.forEach(el => {
      if (el && el instanceof HTMLElement && el.style) {
        (el as HTMLElement).style.maxWidth = '65%';
        (el as HTMLElement).style.flex = '0 0 65%';
      }
    })
  }
  verif_tel() {
    const phoneNumberRegex = /^\d{8}$/;
    const valid = phoneNumberRegex.test(this.contact?.tel?.toString());
    if (valid) {
      return true
    }
    return false
  }
  AddPointPopUp() {
    this.showAddPoint = true
  }
  getNewTrucks() {
    this.CloseAction()
    this.getTrucks({ where: { "entrepriseId": this.id_entreprise } })
  }
  getTrucks(filter: FilterDto) {
    this.truckService.getTrucks(filter).subscribe(
      data => {
        this.trucks = data[0]
      }
    )
  }
  truckChange(item: Truck) {
    this.selectedTruck = item
  }

  AddCaissePopUp() {
    this.showAddCaisse = true
  }
  CloseAction() {
    this.showAddCaisse = false
    this.showAddPoint = false
    this.showAddTruck = false
  }
  getCaisses() {
    this.CloseAction()
    this.getCrates({ where: { "entrepriseId": this.id_entreprise } })
  }

  getEntrepriseAccounts(filter: FilterDto) {
    this.quotationService.getEntreprise(filter).subscribe(
      data => {
        this.entrepriseArray = data[0]
      }
    )
  }

  itemChange(item) {
    this.selectedCrates = item
  }
  pointChange(item) {
    this.selectedPoint = item
  }

  getCrates(filter) {
    this.cratesService.getCrates(filter).subscribe(
      data => {
        this.crates = data[0];
      }
    )
  }
  getPoints(filter: FilterDto) {
    this.salespointService.getPoints(filter).subscribe(
      data => {
        this.points = data[0]
      }
    )
  }
  getSalesPoint() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise }
    this.getPoints(filter);
    this.CloseAction();
  }
  AddTruckPopUp() {
    this.showAddTruck = true
  }
  onSearchCrates(args) {
    this.filter = new FilterDto()
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.take = 10;
      this.filter.where = { "entrepriseId": this.id_entreprise, name: { type: "like", value: this.saisie }, active: true }
    }
    else {
      this.filter.take = 15;
      this.filter.where = { active: true, "entrepriseId": this.id_entreprise }
    }
    this.filter.order = { "createdAt": 'DESC' };
    this.filter.select = ["id", "name", "createdAt"];
    this.getCrates(this.filter)
  }
  onSearchPoint(args) {
    this.filter = new FilterDto()
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.take = 10
      this.filter.where = { "entrepriseId": this.id_entreprise, name: { type: "like", value: this.saisie }, active: true }
    } else {
      this.filter.take = 15
      this.filter.where = { active: true, "entrepriseId": this.id_entreprise }
    }
    this.filter.order = { "createdAt": 'DESC' }
    this.filter.select = ["id", "name", "createdAt"]
    this.getPoints(this.filter)
  }

  onSearchTruck(args) {
    this.filter = new FilterDto()
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.take = 10
      this.filter.where = { "entrepriseId": this.id_entreprise, mark: { type: "like", value: this.saisie }, active: true }
    } else {
      this.filter.take = 15
      this.filter.where = { active: true, "entrepriseId": this.id_entreprise }
    }
    this.filter.order = { "createdAt": 'DESC' }
    this.filter.select = ["id", "mark", "createdAt"]
    this.getTrucks(this.filter)
  }
  submit(userForm: PostUser, form: NgForm) {
    this.validateBtnState = ClrLoadingState.LOADING;
    const existsInBd = this.userArray.filter(element => element.email === userForm.email)
    const index_entrep = this.entrepriseArray.filter(x => x.email == userForm.email)
    if (existsInBd.length > 0 || index_entrep.length > 0) {
      this.usedMail = true
      this.erreurMsg = 'Cette adresse mail a été utilisée !'
      this.validateBtnState = ClrLoadingState.DEFAULT;
    } else {
      userForm.entrepriseId = this.id_entreprise
      userForm.createdBy = this.current_user
      this.contact.firstName = userForm.name
      this.contact.email = userForm.email
      userForm.contactId = this.contact
      userForm.picture = this.url
      if (userForm.role == 'writer') {
        userForm.cratesId = this.selectedCrates
      } else if (userForm.role == "responsable" || userForm.role == "carrier") {
        userForm.pointId = this.selectedPoint
      }
      if (userForm.role == "carrier") {
        userForm.truckId = this.selectedTruck
      }
      // if (this.idAbonnement != 0) {
      //   userForm["abonnementId"] = this.idAbonnement
      // }
      let autorisationModel = new Autorisation()
      this._autorisationService.addAutorisation(autorisationModel)
        .subscribe(data => {
          userForm.autorisationId = data.id
          userForm.real_id = this.real_id
          userForm.tel = this.contactTel
          this.settingService.addUser(userForm)
            .subscribe(
              data => {
                this.success = true
                this.showAlert = true
                this.addNew = true
                this.validateBtnState = ClrLoadingState.SUCCESS;
                setTimeout(() => {
                  this.success = false
                  this.router.navigate(["/user/users"])
                }, 3000)
              },
              err => {
                console.error('Observer got an error: ' + err)
                this.noCnx = true
                this.erreurMsg = 'Erreur de téléversement d' + 'image , vérifier votre connexion !'
                this.validateBtnState = ClrLoadingState.DEFAULT;
                this.success = false
              }
            )
        })
    }
  }


  picked(event) {
    this.tailleInvalid = false
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      if (file.size > 50000) {
        this.tailleInvalid = true;
        this.erreurMsg = 'La taille de ce fichier est invalide !'
        return
      }
      this.handleInputChange(file);
    }
    else {
      this.noImage = true
      this.erreurMsg = 'Aucune image sélectionnée !'

    }
    let pictureAccount: PictureAccount = new PictureAccount()
  }

  handleInputChange(files) {
    this.formatInvalid = false
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.formatInvalid = true
      this.erreurMsg = 'Format de fichier invalide !'
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e) {
    const picture: PictureObject = new PictureObject()
    picture.base64Data = e.target.result;
    picture.url = e.target.result;
    this.pictureArray[0] = picture
    this.url = picture.url
  }

  resetForm(form: NgForm) {
    this.addNew = false;
    form.resetForm();
    this.showAlert = false
    this.url = undefined
    this.pictureArray = []
  }
  showVideoDemo() {
    this.videoDemo = true
    this.modalVideoDemo = true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo = false
      setTimeout(() => {
        this.videoDemo = false
      }, 300);
    }
  }
}
