import { Component, OnInit } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { tokenGetter, UserService } from 'src/app/user/user.service';
import { BlogsService } from '../blogs.service';
import {Blog} from 'src/app/blogs/blog'

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {
  filter: FilterDto<Blog> = new FilterDto<Blog>()
  blogs: Blog[] = []
  userRole: string
  isAdmin: boolean = false
  showAlert: boolean;
  showDetail: boolean;
  selectedIdBlog: number;
  constructor(private blogsService: BlogsService, private userService: UserService) {
    this.userRole = jwtDecode(tokenGetter())["role"]
  }

  ngOnInit(): void {
    this.isAdmin = this.userRole == "admin"
    this.filter.where = { "active": true }
    this.getBlogs()
  }
  getBlogs() {
    this.blogsService.getBlogs(this.filter).subscribe(
      data => {
        this.blogs = data[0]
      }
    )
  }
  detailAction(idSelected: number) {
    this.selectedIdBlog = idSelected
    this.showDetail = true;
  }
  deleteAction(idSelected: number) {
    this.selectedIdBlog = idSelected
    this.showAlert = true;
  }
  closeAction() {
    this.showAlert = false;
    this.showDetail = false;
  }
  saved() {
    this.filter.where = { "active": true }
    this.getBlogs()
    this.showAlert = false;
  }
}
