<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title"> Détails Blog   </h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="close()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <clr-tabs>
          <clr-tab>
            <button clrTabLink> {{ 'article.dt' | translate }} </button>
            <clr-tab-content>
              <br>
              <div class="clr-row">
                <div class="clr-col-3">
                  <span><b> Title</b></span><br>
                  <span><b> Description</b></span><br>
                </div>
                <div class="clr-col">
                  <span>{{blog?.title}}</span><br>
                  <span>{{blog?.description}}</span><br>
                </div>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink> {{ 'commun.ig' | translate }}</button>
            <clr-tab-content>
              <br>
              <div class="clr-row" *ngIf="blog?.picture">
                <div class="clr-col-12 clr-col-md-6  clr-col-lg-6">
                  <div class="card-img img">
                    <img [src]="blog.picture" />
                  </div>
                </div>
              </div>
            </clr-tab-content>
          </clr-tab>
        </clr-tabs>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>