import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import {Blog} from 'src/app/blogs/blog'
import { BlogsService } from '../../blogs.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {
  @Output() closed=new EventEmitter<boolean>();
  @Input() idBlog: number;
  filter: FilterDto<Blog> = new FilterDto<Blog>();
  blog: Blog = new Blog()

  constructor(private blogsService:BlogsService) { }

  ngOnInit(): void {
    this.getBlog()
  }
  getBlog(){
    this.filter.where={ "id": this.idBlog }
    this.blogsService.getBlogs(this.filter).subscribe(
      data => {
        this.blog = data[0][0]
      }
    )
  }
  close() {
    this.closed.emit(true)
  }
}
