import { BlogsService } from './../../blogs.service';
import { Component, OnInit } from '@angular/core';
import {Blog} from 'src/app/blogs/blog'
import { FilterDto } from 'src/app/filter.dto';

@Component({
  selector: 'app-blog-add',
  templateUrl: './blog-add.component.html',
  styleUrls: ['./blog-add.component.css']
})
export class BlogAddComponent implements OnInit {
  model: Blog = new Blog();
  showAlertSuccess : boolean = false 
  showAlertError : boolean = false 
  filter: FilterDto<Blog> = new FilterDto<Blog>()
  real_id: number
  filename: string;
  filesize: number;
  tailleInvalid: boolean;
  msgAlert: string
  constructor(private blogsService: BlogsService) { }

  ngOnInit(): void {
    this.blogsService.getBlogs(this.filter).subscribe(
      data => {
        this.real_id = data[1] + 1
      }
    )
  }

  picked(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.filename = file.name;
      this.filesize = file.size;
      if (this.filesize > 10000000) { //size of file 
        this.tailleInvalid = true;
        return
      }
      this.tailleInvalid = false;

      this.handleInputChange(file);
    }
    else {
      alert("No file selected");
    }
  }
  handleInputChange(files) {
    var file = files;
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  async _handleReaderLoaded(e) {
    this.model.picture = await e.target.result;
  }
  async deletePicAction() {
    this.model.picture = ""
  }

  submit(){
    this.model.real_id = this.real_id
    this.blogsService.addBlog(this.model).subscribe(
      data => {
        this.showAlertSuccess = true 
        this.showAlertError = false 
        this.msgAlert = "Blog ajouté avec succès"
      }, err => {
        this.showAlertError = true
        this.showAlertSuccess = false 
        this.msgAlert = "Erreur lors de l'ajout du blog"
      }
    )
  }
}
