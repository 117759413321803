<clr-header class="header" style="background-color: #593dae;">
  <div *ngIf=arabicLanguage class="toggle-arabic">
    <button type="button" (click)="OpenSidebarArabic()" class="header-hamburger-trigger-arabic" aria-label="Open">
      <span></span>
    </button>
  </div>
  <div class="branding">
    <a routerLink="dashboard" class="nav-link">
      <img type="image/icon" class="icon" src="assets\LOGO.png" width="150" height="45px" alt="clr-icon" />
    </a>
  </div>
  <div class="header-nav">
    <a routerLink="dashboard" class="active nav-link nav-text"> {{ 'commun.dash' | translate }}</a>
  </div>
  <div class="clr-align-self-center">
    <form class="search size-style">
      <label for="search_input" style="margin-bottom: 0;">
        <input id="search_input" type="text" placeholder=" {{ 'commun.rech' | translate }}"
          (input)="onSearchChange($event.target.value)">
      </label>
    </form>
  </div>
  
  <div class="header-actions header-actions--overflow" id="menuLang">
    <div class="header-actions header-actions--overflow">
    <!-- <select  style="background-color: white;" class="form-control mr-sm-2"  class="btn btn-outline"   id="languages" name="languages"
         (change)="changeEventLang($event)">
        <option   [hidden]="true"> {{this.currentLang}} </option>
        <option value="fr"><img src="assets\fr.png"   class="modeMobile"/></option>
        <option value="en"><img src="assets\en.png"   class="modeMobile"/></option>
    </select> -->
    <div class="languages" id="languages" (click)="setMenuLang()">
      <!-- <a style="padding-right:10px;" (click)="clicklang('fr')" type="image/icon"><img src="assets\fr.png" style="cursor:pointer; width:48px;"/></a>
      <a (click)="clicklang('en')" type="image/icon"><img src="assets\en.png" style="cursor:pointer; width:48px;"/></a>
      <a (click)="clicklang('ar')" type="image/icon"><img src="assets\ar.png" style="width:48px; margin-left:12px; cursor: pointer;"/></a> -->
      <!-- <div class="language-icon" id="fr"><img src="assets\fr.png"/><span>Français</span><i class="fas fa-chevron-down"></i></div>
      <div class="language-icon" id="en"><img src="assets\en.png"/><span>English</span><i class="fas fa-chevron-down"></i></div>
      <div class="language-icon" id="ar"><img src="assets\ar.png"/><span>عربي</span><i class="fas fa-chevron-down"></i></div> -->
      <div class="language-icon" [ngClass]="{'showMenu': openMenuLang}" id="fr"><img src="assets\fr.png"/><span>Français</span></div>
      <div class="language-icon" [ngClass]="{'showMenu': openMenuLang}" id="en"><img src="assets\en.png"/><span>English</span></div>
      <div class="language-icon" [ngClass]="{'showMenu': openMenuLang}" id="ar"><img src="assets\ar.png"/><span>عربي</span></div>
      <div class="languages-menu" [ngClass]="{'ShowMenuLanguages' : openMenuLang}">
        <a class="language" (click)="clicklang('fr')" ><img src="assets\fr.png" /><span>Français</span></a>
        <a class="language" (click)="clicklang('en')" ><img src="assets\en.png" style="height: 13px;"/><span>English</span></a>
      </div>
    </div>
  </div>
    <!-- <clr-dropdown [clrCloseMenuOnItemClick]="false" style="padding-top: 10px;">
      <span class="label">
        <button clrDropdownTrigger aria-label="Dropdown demo button">
          <span class="modMobile">{{nameComplet}}</span>
          <img type="image/icon" class="icon" [src]="pictureConfig" width="36px" height="36px"
                        alt="clr-icon" />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle"
            viewBox="-2 -3 18 18">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
            <path fill-rule="evenodd"
              d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
          </svg>
        </button>
      </span>
      <clr-dropdown-menu *clrIfOpen id="drop-down-menu-btn">
        <a (click)="navigateToProfile()">
          <img type="image/icon" *ngIf="picture != ''" class="icon" [src]="picture" alt="clr-icon" width="36px"
            height="36px" />
          <span class="nav-text" style="color: #000;padding-right:0px;margin-right:0px;"><b>{{nameComplet}}</b></span>
        </a>
        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
        <button *ngIf="isAdmin" class="btn btn-link" style="padding-right:0px;margin-right:0px;"
          routerLink="setting/company">
          {{'Paramtrage.pr'|translate }}</button>
        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
        <button class="btn btn-link" style="padding-right:0px;margin-right:0px;" (click)="logout()"> {{
          'commun.deconx' | translate }}</button>
      </clr-dropdown-menu>
    </clr-dropdown> -->
  </div>
  <div class="dropdown-profile" id="profile">
    <a class="nav-link-profile" (click)="showMenuProfile()" [ngClass]="{'showMenu': showProfile}">
      <img [src]="picture" alt="">
      <span>{{name}}</span>
    </a>
    <div class="dropdown-profile-menu" [ngClass]="{'showMenu': showProfile}">
      <div class="dropdown-media">
        <img [src]="picture" alt="">
        <div (click)="navigateToProfile()" class="media-body">
          <span class="media-content"><span>{{name}}</span> <br> <span>{{familyName}}</span></span>
          <!-- <span class="media-content">{{familyName}}</span> -->
        </div>
      </div>
      <a class="dropdown-item" routerLink="setting/company">
        <!-- <i class="mr-2" data-feather="settings"></i> -->
        Paramètres
      </a>
      <a class="dropdown-item text-danger" (click)="logout()">
        <!-- <i class="mr-2" data-feather="log-out"></i> -->
        Déconnexion
      </a>
    </div>
  </div>
</clr-header>