import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryClientService } from '../category-client.service';
import { categoryClient } from '../categoryClient';

@Component({
  selector: 'app-category-client-delete',
  templateUrl: './category-client-delete.component.html',
  styleUrls: ['./category-client-delete.component.css']
})
export class CategoryClientDeleteComponent {
  @Output() saved = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<boolean>();
  @Input() category: categoryClient;

  constructor(private categoryClientService: CategoryClientService) { }

  valideAction() {
    this.categoryClientService.deleteCategoryClient(this.category.id).subscribe(
      res => {
        this.saved.emit(true);
      }
    );
  }
  closeAction() {
    this.closed.emit(true);
  }
}
