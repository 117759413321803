import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../catalog/product/product';
import { FilterDto } from '../filter.dto';
import { TransactionType } from '../setting/type/type';
import { Bank, Buying, BuyingDetail, Operation, OperationDetails, Provider, Store, Transaction } from './buying';

@Injectable({
  providedIn: 'root'
})
export class BuyingService {

  constructor(private http: HttpClient) { }
  getBuying(search: FilterDto): Observable<[Buying[], number]> {
    return this.http.get(environment.api + '/buying?filter=' + JSON.stringify(search)) as Observable<[Buying[], number]>;
  }

  getTransactions(filter: FilterDto<Transaction>): Observable<[Transaction[], number]> {
    return this.http.get(environment.api + '/transactions?filter=' + JSON.stringify(filter)) as Observable<[Transaction[], number]>
  }

  getOperations(filter: FilterDto<Operation>): Observable<[Operation[], number]> {
    return this.http.get(environment.api + '/operations?filter=' + JSON.stringify(filter)) as Observable<[Operation[], number]>
  }
  getOperationsDetails(filter: FilterDto<OperationDetails>): Observable<[OperationDetails[], number]> {
    return this.http.get(environment.api + '/operation-details?filter=' + JSON.stringify(filter)) as Observable<[OperationDetails[], number]>
  }
  getProviders(search: FilterDto<Provider>): Observable<Provider> {
    return this.http.get(environment.api + '/providers?filter=' + JSON.stringify(search)) as Observable<Provider>;
  }

  getProducts(search: FilterDto<Product>): Observable<Product> {
    return this.http.get(environment.api + '/products?filter=' + JSON.stringify(search)) as Observable<Product>;
  }
  getAllBanks(filter: FilterDto): Observable<[Bank[], number]> {
    return this.http.get(environment.api + '/banks?filter=' + JSON.stringify(filter)) as Observable<[Bank[], number]>
  }
  getBanks(search: FilterDto<Bank>): Observable<Bank> {
    return this.http.get(environment.api + '/banks?filter=' + JSON.stringify(search)) as Observable<Bank>;
  }
  getStore(search: FilterDto<Store>): Observable<Store> {
    return this.http.get(environment.api + '/stores?filter=' + JSON.stringify(search)) as Observable<Store>;
  }
  addBuying(buying: Buying): Observable<Buying> {
    return this.http.post<Buying>(environment.api + '/buying', buying);
  }
  getBuyingUpdate(search: FilterDto): Observable<Buying> {
    return this.http.get(environment.api + '/buying?filter=' + JSON.stringify(search)) as Observable<Buying>;
  }

  updateBuying(buyingId: number, buying: Buying): Observable<Buying> {
    return this.http.patch<Buying>(environment.api + '/buying/' + JSON.stringify(buyingId), buying);
  }
  addBuyingDetail(BuyingDetail: BuyingDetail): Observable<BuyingDetail> {
    return this.http.post<BuyingDetail>(environment.api + '/buyingdetail', BuyingDetail);
  }
  updateBuyingDetail(detailProductId: number, BuyingDetail: BuyingDetail): Observable<BuyingDetail> {
    return this.http.patch<BuyingDetail>(environment.api + '/buyingdetail/' + JSON.stringify(detailProductId), BuyingDetail);
  }
  getBuyingDetail(search: FilterDto): Observable<[Buying[], number]> {
    return this.http.get(environment.api + '/buying?filter=' + JSON.stringify(search)) as Observable<[Buying[], number]>;
  }
  deleteBuying(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/buying/' + JSON.stringify(ID)) as Observable<Buying[]>;
  }
  getTransactionTypes(search: FilterDto<TransactionType>): Observable<TransactionType> {
    return this.http.get(environment.api + '/transaction-types?filter=' + JSON.stringify(search)) as Observable<TransactionType>;
  }
  postOperation(operation: Operation): Observable<Operation> {
    return this.http.post<Operation>(environment.api + '/operation', operation)
  }
  patchBuying(ID: number, buying: Buying): Observable<Buying> {
    return this.http.patch<Buying>(environment.api + '/buying/' + JSON.stringify(ID), buying);
  }



  sendMail(mail) {
    return this.http.post(environment.api + '/send', mail)
  }


}