import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { categoryClient } from '../categoryClient';

@Component({
  selector: 'app-category-client-detail',
  templateUrl: './category-client-detail.component.html',
  styleUrls: ['./category-client-detail.component.css']
})
export class CategoryClientDetailComponent implements OnInit {
  @Input() category: categoryClient;
  @Output() closed = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }
  closeAction() {
    this.closed.emit(true);
  }
}
