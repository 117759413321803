import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivitesComponent } from './activites/activites.component';
import { AuthGuard } from './auth/auth.guard';
import { InformationComponent } from './core/information/information.component';
import { SearchComponent } from './core/search/search.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { ActivationAccountComponent } from './user/activation-account/activation-account.component';
import { LoginComponent } from './user/login/login.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { SignInComponent } from './user/sign-in/sign-in.component';


const routes: Routes = [
  { path: 'user/login', component: LoginComponent },
  { path: 'user/reset-password', component: ResetPasswordComponent },
  { path: 'user/activateAccount', component: ActivationAccountComponent },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'sing-in', component: SignInComponent
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]
  },
  {
    path: '', component: DashboardComponent, canActivate: [AuthGuard]
  },
  {
    path: 'search', component: SearchComponent
  },
  {
    path: 'activites', component: ActivitesComponent
  },
  {
    path: 'catalog',
    loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule)
  },
  {
    path: 'finance',
    loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule)
  },
  {
    path: 'transport',
    loadChildren: () => import('./transport/transport.module').then(m => m.TransportModule)
  },
  {
    path: 'buying',
    loadChildren: () => import('./buying/achat.module').then(m => m.AchatModule)
  },
  {
    path: 'stock',
    loadChildren: () => import('./stock/stock.module').then(m => m.StockModule)
  },
  {
    path: 'sales',
    loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule)
  },

  {
    path: 'informations', component: InformationComponent
  },
  {
    path: 'parts',
    loadChildren: () => import('./parts/parts.module').then(m => m.PartsModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  {
    path: 'sales-point',
    loadChildren: () => import('./sales-point/sales-point.module').then(m => m.SalesPointModule)
  },
  {
    path: 'crates',
    loadChildren: () => import('./crates/crates.module').then(m => m.CratesModule)
  },
  {
    path:"carte-fidelite",
    loadChildren:()=>import('./carte-fidelite/carte-fidelite.module').then(m=>m.CarteFideliteModule)
  },
  {
    path: 'news',
    loadChildren: ()=> import('./news/news.module').then(m => m.NewsModule)
  },
  {
    path:'promotions',
    loadChildren: ()=> import('./promotion/promotion.module').then(m => m.PromotionModule)
  },
  {
    path:'blogs',
    loadChildren: ()=> import('./blogs/blogs.module').then(m => m.BlogsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
