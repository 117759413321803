import { Component, HostListener,AfterViewInit, OnInit } from '@angular/core';
import { Customer, Address } from '../../parts';
import { Contact,User } from '../../../user/user';
import { PartsService } from '../../parts.service';
import { StockService } from 'src/app/stock/stock.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { HttpClient } from '@angular/common/http';
import * as intlTelInput from 'intl-tel-input';

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.css']
})
export class CustomerAddComponent implements OnInit,AfterViewInit {

  customerObject = new Customer()
  userObject=new User()

  contact = new Contact()
  adresse = new Address()
  adressArray :Address[]=[]
  contactArray = []
  allContact = []
  allAdress = []
  showAlert: boolean;
  showDetail: boolean;
  showDetailAdr: boolean;
  usedMail: boolean
  addNew: boolean = false;
  fromOrder: boolean;
  fromQuot: boolean;
  customerForm: any;
  initaStep: string
  id_entreprise: number
  real_id: number
  userRole: string
  isAdmin: boolean = false
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""  
  phoneModel : string
  dialCode : string 
  isValidNumber : boolean


  constructor(private router: ActivatedRoute, private http: HttpClient,
    private partsService: PartsService, private stockService: StockService,
    private formBuilder: UntypedFormBuilder) {


    this.customerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      mf: [''],
      ville: [''],
      state:[''],
      city:[''],
      geoCode:[''],
      description: [''],
      tel:[''],
      email:[''],
      nameSociete:['']
    });

  }
  ngAfterViewInit(): void {
    const inputElement = document.getElementById("phone")
    if(inputElement){
      const iti = intlTelInput(inputElement,{
        initialCountry : 'TN',
        separateDialCode:true,
        utilsScript:'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.0/js/utils.js'
      });
      this.dialCode = iti.getSelectedCountryData().dialCode
      inputElement.addEventListener('countrychange', () => {
        this.dialCode = iti.getSelectedCountryData().dialCode
      });
      inputElement.addEventListener('change',()=>{
        if(this.phoneModel.trim()){
          if(!iti.isValidNumber()){
            this.isValidNumber = false 
          }else {
            this.isValidNumber = true 
            this.contact.tel = parseInt(this.phoneModel)
          }
        }
        this.verif_tel()
      })
    }
    this.showtab()
  }


  showtab(){
    var elements = Array.from(document.getElementsByClassName('clr-col-md-10'))
    elements.forEach(el=>{ if (el && el instanceof HTMLElement && el.style) {
      (el as HTMLElement).style.maxWidth='65%';
      (el as HTMLElement).style.flex='0 0 65%';
    }})
  }
  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.router.queryParamMap.subscribe(
      params => {
        if (params["params"].fromQuot == 'true') {
          this.fromOrder = false
          this.fromQuot = true
        } else if (params["params"].fromOrder == 'true') {
          this.fromOrder = true
          this.fromQuot = false
        }
      }
    );
    this.partsService.getContacts({ where: { "entrepriseId": this.id_entreprise } })
    .subscribe(
      data => {
        this.allContact = data[0]
      }
    )
    this.partsService.getCustomers({
      where: {
        "entrepriseId": this.id_entreprise
      }
    }).subscribe(
      data => {
        this.real_id = data[1] + 1;
      }
    )
  }

  verif_tel() {
    const phoneNumberRegex = /^\d{8}$/;
    const valid = phoneNumberRegex.test(this.contact?.tel?.toString());
    if (valid) {
      return true
    }
    return false
  }

  loadAdresse() {
    this.stockService.getAdress({ where: { "entrepriseId": this.id_entreprise } }).subscribe(
      data => {
        this.allAdress = data[0]
      }
    )
  }

  addContact() {
    const existsInBd = this.allContact.filter(element => element.email === this.contact.email && element.email != undefined)
    const existsInTab = this.contactArray.filter(element => element.email === this.contact.email && element.email != undefined)
    if (existsInBd.length > 0 || existsInTab.length > 0 && this.contact.email) {
      this.usedMail = true
    }
    else {
      this.contact.entrepriseId = this.id_entreprise
      // this.contact.firstName = this.customerObject.name
      this.contact.firstName = this.userObject.name
      this.contactArray.push(this.contact)
      this.contact = new Contact()
      this.phoneModel = ""
      // this.customerObject.contacts = this.contactArray
      
      this.showDetail = true;
      this.usedMail = false
    }
  }

  addAdresse() {
    this.adresse.entrepriseId = this.id_entreprise
    this.adressArray.push(this.adresse)
    // this.adressArray[0]=this.adresse
    this.adresse = new Address()
    this.customerObject.adress = this.adressArray
    this.showDetailAdr = true;
  }

  deleteContact(item) {
    this.contactArray.splice(this.contactArray.indexOf(item), 1);
  }

  deleteAdresse(item) {
    this.adressArray.splice(this.adressArray.indexOf(item), 1);
    this.showDetailAdr = false;
  }
  submit() {
    this.customerObject.customerType = "default customer"
    // this.customerObject.entrepriseId = this.id_entreprise
    // this.customerObject.real_id = this.real_id;
    this.userObject.entrepriseId = this.id_entreprise
    this.userObject.real_id = this.real_id;
    this.customerObject.byAdmin = this.isAdmin
    this.userObject.adress=this.adressArray
    this.userObject.contactId=this.contactArray[0]
    
    
    this.partsService.addCustomer(this.userObject).subscribe(
      data => {
        this.showAlert = true;
        this.addNew = true;
      }
    )
    console.log("this.userObject",this.userObject)
  }


  resetForm() {
    this.addNew = false;
    this.allContact = []
    this.allAdress = []
    this.adressArray = []
    this.contactArray = []
    this.customerObject.contacts = []
    this.customerObject.adress = []
    this.showAlert = false
    this.showDetail = false
    this.showDetailAdr = false
    this.usedMail = false
    this.customerForm.reset()
    this.initaStep = 'step1'
  }
  keyPressNom(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyPressMF(event) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  // onKeyPress(event : any ){
  //   var regesExp = new RegExp("^[0-9]+$");
  //   var input = String.fromCharCode( !event.charCode ? event.where : event.charCode);
  //   if(!regesExp.test(input)){
  //     event.preventDefault();
  //     return false
  //   }else
  //     return true 
    
  // }

  onKeyPress(event: KeyboardEvent) {
    const regExp = new RegExp("^[0-9]*$"); // Allow only digits
    const input = String.fromCharCode(event.charCode);
    if (!regExp.test(input)) {
        event.preventDefault(); // Prevent input if not valid
    }
}

  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
