import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { tokenGetter } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = tokenGetter()
    if (token.length > 0) {
      // let userRole = jwtDecode(token)["role"]
      // let abonne = jwtDecode(token)["abonee"]
      // let triale = jwtDecode(token)["triale"]
      // let reste_triale = jwtDecode(token)["reste_triale"]
      // let active_account = jwtDecode(token)["active_account"]
      // let active_abn = jwtDecode(token)["active_abn"]
      // let silver = jwtDecode(token)["silver"]
      // if (active_account === true) {
      //   if (state.url) {
      //     if ((state.url.indexOf("/sales/invoice") != -1 ||
      //       state.url.indexOf("/sales/quotation") != -1 ||
      //       state.url.indexOf("crates") != -1 ||
      //       state.url.indexOf("caisse") != -1)
      //       && silver == true) {
      //       this.router.navigate(["/dashboard"])
      //       return false
      //     }
      //     return true
      //   }
      // }
      // else if (active_account === true) {
      //   if (state.url) {
      //     if (state.url.indexOf("/sales/invoice") != -1 ||
      //       state.url.indexOf("/sales/quotation") != -1 ||
      //       state.url.indexOf("crates") != -1 ||
      //       state.url.indexOf("/caisse") != -1
      //       && silver == true) {
      //       this.router.navigate(["/dashboard"])
      //       return false
      //     }
      //     if (state.url == "/user/login") {
      //       return true
      //     }
      //     return true
      //   }
      // }
      return true
    }else {
      this.router.navigate(['/user/login']);
      return false;
    }
  }
}

