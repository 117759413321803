import { Component, HostListener, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import jwtDecode from 'jwt-decode';
import { CoreService } from 'src/app/core/core.service';
import { FilterDto } from 'src/app/filter.dto';
import { Points } from 'src/app/sales-point/points';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { SettingService } from 'src/app/setting/setting.service';
import { FilterService } from 'src/app/shared/filter.service';
import { Store } from 'src/app/stock/stock';
import { UserService, tokenGetter, getValueFromToken } from 'src/app/user/user.service';
import { WhereFindOperator } from 'src/app/whereFindOperator';
import { Product, QuantityProduct } from './product';
import { ProductService } from './product.service';
import { Autorisation } from 'src/app/user/autorisation/autorisation';


import { Category } from '../category/category';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  searchString: string;
  showAlert: boolean;
  ascSort: ClrDatagridSortOrder;
  filter: FilterDto<Product> = new FilterDto<Product>()
  whereFindOperator: WhereFindOperator<Product> = new WhereFindOperator<Product>()
  products: QuantityProduct[] = [];
  tmpList: QuantityProduct[] = []
  showProduct: QuantityProduct[] = []
  selectedIdProduit: number;
  pathName: string
  showDetail: boolean;
  currentLang: string;
  lg = "rtl"
  x = false;
  y: string;
  id_entreprise: number
  devise: string
  userRole: string
  store_id: number
  isAdmin: boolean = false
  currentPage: number = 1
  pageSize: number = 5
  total: number
  nbr_page: number
  insertIndex: number = 0
  search_text: string
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link: string = ""
  userAutorisation: Autorisation = new Autorisation()
  userId: number
  constructor(public translate: TranslateService, private productService: ProductService,
    private filterService: FilterService, private serviceCore: CoreService,
    private salesPointService: SalesPointService, private settingService: SettingService,
    private userService: UserService) {
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.pathName = window.location.pathname
    if (this.pathName == '/search') {
      this.filter = new FilterDto<Product>()
      window.addEventListener('find', (evt: any) => {
        this.searchString = evt.detail
        this.refresh()
      });
    } else {
      this.searchString = undefined
    }
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.y = localStorage.getItem('y');
    this.translate.use(this.currentLang);
  }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.isAdmin = this.userRole == "admin"
    this.filter.where = {}
    this.getStoreId();
    this.serviceCore.getEntrepriseData({
      where: { "id": this.id_entreprise },
      relations: ["deviseId"]
    }).subscribe(
      data => {
        this.devise = data[0][0].deviseId.code
      }
    )
    // this.filter = new FilterDto()

    // this.filter.select = ["id", "reference", "name", "forBuying", "categoryId",  "real_id", "favorite"]

    // this.filter.where = { "entrepriseId": this.id_entreprise,"active":true }
    // this.getProducts(this.filter);
    this.ascSort = ClrDatagridSortOrder.ASC;
    if (this.pathName == '/search') {
      this.filter = new FilterDto<Product>()
      this.filter.where = {}
      this.searchString = this.serviceCore.getSearchStream()
    } else {
      this.searchString = undefined
    }


  }

  search_product(event:Event) {
    let saisie = ((event.target as HTMLInputElement).value)
    if (this.search_text.length == 0) {
      this.products = []
      this.total = 1
      this.filter.where = {}
      this.getPaginatedData(1)
    } else {
      this.products = []
      this.tmpList.filter(x => {
        if (x.product.name.toLowerCase().indexOf(this.search_text.toLowerCase().trim()) != -1) {
          this.products.push(x);
        }
      })  
      this.total = this.products.length
      if (this.total > 0) {
        this.nbr_page = Math.ceil(this.total / this.pageSize)
      } else {
        this.nbr_page = 1
      }
      this.showProduct = this.products.slice(this.insertIndex, this.insertIndex + this.pageSize)
      if (this.products.length == 0 && saisie[saisie.length - 1] !== ' ') {
        this.filter.order = { "id": "ASC" }
        this.filter.take = 5;
        this.filter.where = Object.assign(this.filter.where.valueOf(), {
          name: {
            type: "like",
            value: "*" + this.search_text.trim() + "*"
          }
        })
        this.getProducts(this.store_id,this.filter)
      }
      console.log(" this.showProduct 1= ",this.showProduct)
    }
  }
  getPaginatedData(currentPage) {
    this.filter.skip = (currentPage - 1) * this.pageSize
    this.insertIndex = this.filter.skip ? this.filter.skip : 0
    this.getProducts(this.store_id, this.filter)
  }
  getStoreId() {
    if (this.isAdmin) {
      let filter = new FilterDto()
      filter.where = { "entrepriseId": this.id_entreprise, "name": "point de vente principale" }
      filter.relations = ["storeId"]
      this.salesPointService.getPoints(filter).subscribe(
        data => {
          if (data[1] > 0) {
            this.store_id = data[0][0].storeId.id
            // this.filter = new FilterDto()

            // this.filter.select =  ["id", "name", "categoryId",  "unityId", "taxId", "real_id"]**
   
         
            this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
            this.filter.relations = ["categoryId","unityId", "taxId"];
            this.filter.take = 5;
            this.getProducts(this.store_id, this.filter);
          }
        }
      )
    } else {
      const id_user = parseInt(getValueFromToken("id"))
      let filter = new FilterDto()
      filter.where = { "id": id_user, "entrepriseId": this.id_entreprise }
      if (this.userRole == "writer") {
        filter.relations = ["cratesId", "autorisationId", "cratesId.pointid", "cratesId.pointid.storeId"]
        this.settingService.getUserInfo(filter).subscribe(
          data => {
            this.userAutorisation = data[0][0].autorisationId
            if (data[1] > 0) {
              this.store_id = data[0][0].cratesId.pointid.storeId.id
              // this.filter = new FilterDto()

              this.filter.select = ["id", "name", "categoryId",  "unityId", "taxId", "real_id"]

              // this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
              this.filter.take = 5;
              this.getProducts(this.store_id, this.filter);
            }
          }
        )
      } else if (this.userRole == "responsable" || this.userRole == "carrier") {
        filter.relations = ["pointId", "pointId.storeId", "autorisationId"]
        this.settingService.getUserInfo(filter).subscribe(
          data => {
            this.userAutorisation = data[0][0].autorisationId
            if (data[1] > 0) {
              this.store_id = data[0][0].pointId.storeId.id
              // this.filter = new FilterDto()

              this.filter.select = ["id", "name", "categoryId",  "unityId", "taxId", "real_id"]

              // this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
              this.filter.take = 5;
              this.getProducts(this.store_id, this.filter);
            }
          }

        ) 

      }
    }
  }
  makeFavorite(item: any) {
    let model = new Product()
    model.favorite = true;
    this.productService.editProductById(item, model).subscribe(
      data => {
        // this.filter = new FilterDto()

        this.filter.select = ["id", "name", "categoryId",  "unityId", "taxId", "real_id"]

        // this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
        this.filter.take = 5;
        this.getProducts(this.store_id, this.filter);
      }
    )
  }
  removeFavorite(item: any) {
    let model = new Product()
    model.favorite = false;
    this.productService.editProductById(item, model).subscribe(
      data => {
        // this.filter = new FilterDto()

        this.filter.select = ["id", "name", "unityId", "taxId", "real_id"]

        // this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
        this.filter.take = 5;
        this.getProducts(this.store_id, this.filter);
      }
    )
  }

  refresh(state?: ClrDatagridStateInterface) {
    this.filter.where = { active: true }
    if (state) {
      this.filter = this.filterService.bind(state);
    }
    if (this.searchString) {
      this.filter.where = Object.assign(this.filter.where.valueOf(), { name: { type: "like", value: "*" + this.searchString + "*" } })
    }
    this.filter.where = Object.assign(this.filter.where.valueOf(), { active: true })

    // this.filter.select = ["id", "name",  "real_id"]

    this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.filter.take = 5;
    this.filter.relations = ["categoryId"];
    this.getProducts(this.store_id, this.filter);
  }
  // getAllProducts(store_id) {
  //   let filter = new FilterDto()

  //   this.filter.select = ["id", "reference", "name", "forBuying", "categoryId",  "real_id", "favorite"]

  //   this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
  //   this.productService.getInfoProducts(store_id, filter).subscribe(
  //     data => {
  //       this.tmpList = data[0]
  //     }
  //   )
  // }


  
  // getProducts(store_id: number, filter: FilterDto<Product>) {
  //   if (!this.products[this.insertIndex]) {
  //     filter.where = Object.assign(filter.where.valueOf(), {
  //       "entrepriseId": this.id_entreprise, "active": true, "enDestockage": false
  //     })
  //     this.productService.getInfoProducts(store_id, filter).subscribe(
  //       data => {
  //         console.log("daaataa",data)
         
  //         for (let i = 0; i < data[0].length; i++) {
  //           this.products[this.insertIndex + i] = data[0][i];
  //           this.productService.getProductImage(data[0][i].product.id).subscribe(
  //               (imageData:string) => {
                
  //                 // Assurez-vous que `imageData` est une URL ou un chemin d'image valide
  //                 this.products[this.insertIndex + i].image = imageData;
  //                 console.log("imageData",imageData)
  //               },
  //               err => console.error('Error fetching image:', err)
  //             );
       
  //         }
  //         this.tmpList = this.products
  //         this.showProduct = this.products.slice(this.insertIndex, this.insertIndex + this.pageSize)
  //          console.log(" this.showProduct 2 = ",this.showProduct)
  //         this.total = data[1]
  //         if (this.total > 0) {
  //           this.nbr_page = Math.ceil(this.total / this.pageSize)
  //         } else {
  //           this.nbr_page = 1
  //         }
  //       },
  //       err => console.error('Observer got an error: ', err),
  //     );
  //   } else {
  //     this.showProduct = this.products.slice(this.insertIndex, this.insertIndex + this.pageSize)
  //   }
   
  // }

  getProducts(store_id: number, filter: FilterDto<Product>) {
    if (!this.products[this.insertIndex]) {
          filter.where = Object.assign(filter.where.valueOf(), {
            "entrepriseId": this.id_entreprise, "active": true, "enDestockage": false
          })
  
      // Appeler le service pour obtenir les produits
      this.productService.getInfoProducts(store_id, filter).subscribe(
        data => {
          console.log("Dataaaa received:", data);
  
          // Traitement des produits reçus
          for (let i = 0; i < data[0].length; i++) {
            this.products[this.insertIndex + i] = data[0][i];
  
            // Assurez-vous que data[0][i].product.id est valide
            const productId = data[0][i].product.id;
            console.log(`Récupération de l'image pour le produit ID: ${productId}`);
  
            this.productService.getProductImage(productId).subscribe(
              (imageData: string) => {
          
                this.products[this.insertIndex + i].image = imageData;
              },
              err => {
                console.error(`Erreur lors de la récupération de l'image pour le produit ID ${productId}:`, err);
                this.products[this.insertIndex + i].image = 'chemin/vers/image/par/default.jpg';
              }
            );
          }
  
          // Mettre à jour la liste temporaire et les produits affichés
          this.tmpList = this.products;
          this.showProduct = this.products.slice(this.insertIndex, this.insertIndex + this.pageSize);
          console.log("Show product++:", this.showProduct);
  
          // Mettre à jour le total et le nombre de pages
          this.total = data[1];
          this.nbr_page = this.total > 0 ? Math.ceil(this.total / this.pageSize) : 1;
        },
        err => console.error('Error fetching products:', err)
      );
    } else {
      // Si les produits existent déjà, afficher ceux-ci
      this.showProduct = this.products.slice(this.insertIndex, this.insertIndex + this.pageSize);
    }
  }
  
  
  
  
  
  // getProducts(store_id: number, filter: FilterDto<Product>) {
  //   // Vérifiez si les produits à cet index n'existent pas déjà
  //   if (!this.products[this.insertIndex]) {
  //     // Ajoutez des conditions supplémentaires au filtre si nécessaire
  //     filter.where = Object.assign(filter.where || {}, {
  //       "entrepriseId": this.id_entreprise,
  //       "active": true,
  //       // "enDestockage": false  // Décommentez si nécessaire
  //     });
  
  //     // Récupérez les informations des produits
  //     this.productService.getInfoProducts(store_id, filter).subscribe(
  //       data => {
  //         console.log("Data received:", data);
  
  //         // Traitez chaque produit reçu
  //         data[0].forEach((productInfo, index) => {
  //           const productIndex = this.insertIndex + index;
  //           this.products[productIndex] = productInfo;
  
  //           // Récupérez l'image pour chaque produit par son ID
  //           this.productService.getProductImage(productInfo.product.id).subscribe(
  //             imageData => {
  //               // Assurez-vous que `imageData` est une URL ou un chemin d'image valide
  //               this.products[productIndex].image = imageData;
  //             },
  //             err => console.error('Error fetching image:', err)
  //           );
  //         });
  
  //         // Mettez à jour la liste temporaire et l'affichage
  //         this.tmpList = [...this.products]; // Utilisez la copie pour éviter les modifications accidentelles
  //         this.showProduct = this.tmpList.slice(this.insertIndex, this.insertIndex + this.pageSize);
  //         console.log("Products to display:", this.showProduct);
  
  //         // Mettez à jour le total des produits et le nombre de pages
  //         this.total = data[1];
  //         this.nbr_page = this.total > 0 ? Math.ceil(this.total / this.pageSize) : 1;
  //       },
  //       err => console.error('Error fetching product info:', err)
  //     );
  //   } else {
  //     // Si les produits existent déjà, les afficher directement
  //     this.showProduct = this.products.slice(this.insertIndex, this.insertIndex + this.pageSize);
  //   }
  // }
  
  



  deleteAction(idSelected: number) {
    this.selectedIdProduit = idSelected
    this.showAlert = true;
  }
  detailAction(idSelected: number) {
    this.selectedIdProduit = idSelected
    this.showDetail = true;
  }
  closeAction() {
    this.showAlert = false;
    this.showDetail = false;
  }

  saved() {
    this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    // this.getProducts(this.store_id, this.filter)
    this.getPaginatedData(0)
    this.showAlert = false;
  }
  showVideoDemo() {
    this.videoDemo = true
    this.modalVideoDemo = true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo = false
      setTimeout(() => {
        this.videoDemo = false
      }, 300);
    }
  }
}
