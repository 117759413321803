<div class="dashboard">
    <div class="dashboard-icons">
        <button class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-speedometer2" viewBox="0 0 16 16">
                <path
                    d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                <path fill-rule="evenodd"
                    d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z" />
            </svg>
        </button>
        <button class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart3"
                viewBox="0 0 16 16">
                <path
                    d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
        </button>
        <button class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear"
                viewBox="0 0 16 16">
                <path
                    d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                <path
                    d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
            </svg>
        </button>
        <button class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calculator"
                viewBox="0 0 16 16">
                <path
                    d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                <path
                    d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
            </svg>
        </button>
        <button class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                    d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
        </button>
        <button class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3"
                viewBox="0 0 16 16">
                <path
                    d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                <path
                    d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </svg>
        </button>
        <button class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-person-badge-fill" viewBox="0 0 16 16">
                <path
                    d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
            </svg>
        </button>
        <button class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-microsoft"
                viewBox="0 0 16 16">
                <path
                    d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
            </svg>
        </button>
        <button class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-hourglass-split" viewBox="0 0 16 16">
                <path
                    d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
            </svg>
        </button>
        <button class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list"
                viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
            </svg>
        </button>
    </div>
    <app-slider *ngIf="userRole=='admin' || userRole=='responsable'"></app-slider>
    <div class="statistics">
        <div class="statistics-1">
            <div class="statistic statistic-1">
                <div class="stat">
                    <div class="stat-icon">
                        <div class="stat-circle">
                            <span><b>$</b></span>
                        </div>
                        <div class="stat-dropdown">
                            <i class="fas fa-ellipsis-v" (click)="setDropCaisse()" id="fasCaisse"></i>
                            <div class="dropdown-menu-end" [ngClass]="{'show' : detailsCaisse}"
                                aria-labelledby="cardOpt6">
                                <a class="dropdown-item" href="javascript:void(0);"
                                    (click)="routerLinkCaisse()">Details</a>
                            </div>
                        </div>
                    </div>
                    <span class="stat-text">Caisse du jour</span>
                    <div class="stat-value">
                        <span class="stat-real-value">{{caisse_jour.toFixed(3)}}</span>
                        <span class="stat-devise-value">{{devise}}</span>
                    </div>
                </div>
            </div>
            <div class="statistic statistic-2">
                <div class="stat">
                    <div class="stat-icon">
                        <div class="stat-circle">
                            <span><b>$</b></span>
                        </div>
                        <div class="stat-dropdown">
                            <i class="fas fa-ellipsis-v" (click)="setDropDepenses()" id="fasDepenses"></i>
                            <div class="dropdown-menu-end" [ngClass]="{'show' : detailsDepenses}"
                                aria-labelledby="cardOpt6">
                                <a class="dropdown-item" href="javascript:void(0);"
                                    (click)="routerLinkDepenses()">Details</a>
                            </div>
                        </div>
                    </div>
                    <span class="stat-text">Dépenses</span>
                    <div class="stat-value">
                        <span class="stat-real-value">{{total_depense.toFixed(3)}}</span>
                        <span class="stat-devise-value">{{devise}}</span>
                    </div>
                </div>
            </div>
            <div class="statistic statistic-2">
                <div class="stat">
                    <div class="stat-circle"><span><b>$</b></span></div>
                    <span class="stat-text">Achats en crédit</span>
                    <div class="stat-value">
                        <span class="stat-real-value">{{total_buying_credit.toFixed(3)}}</span>
                        <span class="stat-devise-value">{{devise}}</span>
                    </div>
                </div>
            </div>
            <div class="statistic statistic-3">
                <div class="stat">
                    <div class="stat-icon">
                        <img src="../../../assets/wallet-info.png" alt="">
                        <div class="stat-dropdown">
                            <i class="fas fa-ellipsis-v" (click)="setDropDevis()" id="fasDevis"></i>
                            <div class="dropdown-menu-end" [ngClass]="{'show' : detailsDevis}"
                                aria-labelledby="cardOpt6">
                                <a class="dropdown-item" href="javascript:void(0);" (click)="routerLinkDevis()"
                                    routerLink="/sales/quotation">Details</a>
                            </div>
                        </div>
                    </div>
                    <span class="stat-text">Nombre des devis</span>
                    <div class="stat-value">
                        <span class="stat-real-value">{{nbr_devis}}</span>
                    </div>
                </div>
            </div>
            <div class="statistic statistic-4">
                <div class="stat">
                    <div class="stat-icon">
                        <img src="../../../assets/wallet.png" alt="">
                        <div class="stat-dropdown">
                            <i class="fas fa-ellipsis-v" (click)="setDropFactures()" id="fasFactures"></i>
                            <div class="dropdown-menu-end" [ngClass]="{'show' : detailsFactures}"
                                aria-labelledby="cardOpt6">
                                <a class="dropdown-item" href="javascript:void(0);"
                                    (click)="routerLinkFactures()">Details</a>
                            </div>
                        </div>
                    </div>
                    <span class="stat-text">Nombre des factures</span>
                    <div class="stat-value">
                        <span class="stat-real-value">{{nbr_invoice}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="statistics-1">
            <div class="statistic statistic-1">
                <div class="stat">
                    <div class="stat-icon">
                        <div class="stat-circle">
                            <span><b>$</b></span>
                        </div>
                        <div class="stat-dropdown">
                            <i class="fas fa-ellipsis-v" (click)="setDropTtVentes()" id="fasTtVentes"></i>
                            <div class="dropdown-menu-end" [ngClass]="{'show' : detailsTtVentes}"
                                aria-labelledby="cardOpt6">
                                <a class="dropdown-item" href="javascript:void(0);"
                                    (click)="routerLinkTtVentes()">Details</a>
                            </div>
                        </div>
                    </div>
                    <span class="stat-text">Totale des ventes</span>
                    <div class="stat-value">
                        <span class="stat-real-value">{{total_sales.toFixed(3)}}</span>
                        <span class="stat-devise-value">{{devise}}</span>
                    </div>
                </div>
            </div>
            <div class="statistic statistic-2">
                <div class="stat">
                    <div class="stat-icon">
                        <div class="stat-circle">
                            <span><b>$</b></span>
                        </div>
                        <div class="stat-dropdown">
                            <i class="fas fa-ellipsis-v" (click)="setDropTtAchats()" id="fasTtAchats"></i>
                            <div class="dropdown-menu-end" [ngClass]="{'show' : detailsTtAchats}"
                                aria-labelledby="cardOpt6">
                                <a class="dropdown-item" href="javascript:void(0);"
                                    (click)="routerLinkTtAchats()">Details</a>
                            </div>
                        </div>
                    </div>
                    <span class="stat-text">Totale des achats </span>
                    <div class="stat-value">
                        <span class="stat-real-value">{{totale_buying.toFixed(3)}}</span>
                        <span class="stat-devise-value">{{devise}}</span>
                    </div>
                </div>
            </div>
            <div class="statistic statistic-2">
                <div class="stat">
                    <div class="stat-circle"><span><b>$</b></span></div>
                    <span class="stat-text">Vente en crédit </span>
                    <div class="stat-value">
                        <span class="stat-real-value">{{total_sales_credit.toFixed(3)}}</span>
                        <span class="stat-devise-value">{{devise}}</span>
                    </div>
                </div>
            </div>
            <div class="statistic statistic-3">
                <div class="stat">
                    <div class="stat-icon">
                        <img src="../../../assets/wallet-info.png" alt="">
                        <div class="stat-dropdown">
                            <i class="fas fa-ellipsis-v" (click)="setDropNbrVentes()" id="fasNbrVentes"></i>
                            <div class="dropdown-menu-end" [ngClass]="{'show' : detailsNbrVentes}"
                                aria-labelledby="cardOpt6">
                                <a class="dropdown-item" href="javascript:void(0);"
                                    (click)="routerLinkNbrVentes()">Details</a>
                            </div>
                        </div>
                    </div>
                    <span class="stat-text">Nombre des ventes </span>
                    <div class="stat-value">
                        <span class="stat-real-value">{{nbr_orders}}</span>
                    </div>
                </div>
            </div>
            <div class="statistic statistic-4">
                <div class="stat">
                    <div class="stat-icon">
                        <img src="../../../assets/wallet.png" alt="">
                        <div class="stat-dropdown">
                            <i class="fas fa-ellipsis-v" (click)="setDropNbrAchats()" id="fasNbrAchats"></i>
                            <div class="dropdown-menu-end" [ngClass]="{'show' : detailsNbrAchats}"
                                aria-labelledby="cardOpt6">
                                <a class="dropdown-item" href="javascript:void(0);"
                                    (click)="routerLinkNbrAchats()">Details</a>
                            </div>
                        </div>
                    </div>
                    <span class="stat-text">Nombre des achats </span>
                    <div class="stat-value">
                        <span class="stat-real-value">{{nbr_buyings}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="charts">
            <div class="chartAffaire" style="width:100%; height:auto">
                <canvas id="barCanvas" aria-label="chart" role="img"></canvas>
            </div>
        </div>
    </div>
</div>