<section>
  <div class="wrapper">
  <h3>Ajouter Société de livraison</h3>
  <div *ngIf="showAlert" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          L'ajout d'une société a été effectué avec succès ..
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="usedMail" class="alert alert-danger" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          cette adresse email est déja utilisé ...
        </span>
      </div>
    </div>
  </div>
  <br>
  <form clrStepper clrInitialStep={{initaStep}} (ngSubmit)="submit()" style="margin-top: 10px;">
    <clr-stepper-panel ngModelGroup="step1">
      <clr-step-title>Société</clr-step-title>
      <clr-step-content>
        <form clrForm>
          <clr-input-container>
            <label>Nom *</label>
            <input name="name" clrInput [(ngModel)]="companyObject.name" />
          </clr-input-container>
          <clr-textarea-container>
            <label>Description</label>
            <textarea clrTextarea name="description" [(ngModel)]="companyObject.description"></textarea>
          </clr-textarea-container>
        </form>
        <button clrStepButton="next" *ngIf="companyObject.name">Suivant</button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step2">
      <clr-step-title>Contacts</clr-step-title>
      <clr-step-content>
        <h3>Contacts de Société</h3>
        <div *ngIf="showDetail" class="alert alert-success" role="alert">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                L'ajout d'un Contact a été effectué avec succès ..
              </span>
            </div>
          </div>
        </div>
        <form clrForm>

          <clr-input-container>
            <label>Nom *</label>
            <input clrInput [(ngModel)]="contact.firstName" name="firstName" />
          </clr-input-container>

          <clr-input-container>
            <label>Téléphone *</label>
            <input type="number" clrInput [(ngModel)]="contact.tel" name="tel"
              pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" />
            <clr-control-error *clrIfError="'required'">Numéro de téléphone est obligatoire</clr-control-error>
            <clr-control-error *clrIfError="'pattern'">Numéro de téléphone est incorrect</clr-control-error>
          </clr-input-container>

          <!-- <clr-input-container>
            <label>Fax</label>
            <input type="number" clrInput [(ngModel)]="contact.fax" name="fax" pattern="[0-9]{8}" />
            <clr-control-error>Numéro de fax est incorrect </clr-control-error>
          </clr-input-container> -->

          <clr-input-container>
            <label>Email</label>
            <input clrInput type="text" class="form-control" name="email" [(ngModel)]="contact.email" #email="ngModel"
              [ngClass]="{ 'valid': email.valid}" email />
            <clr-control-error>Adresse mail non valide</clr-control-error>
          </clr-input-container>

          <br>
          <button class="btn btn-sm" type="submit" (click)="addContact()"
            *ngIf="contact.firstName && contact.tel">Valider</button>
          <clr-datagrid *ngIf="showDetail && contactArray.length > 0">
            <clr-dg-column>
              Nom de Société
            </clr-dg-column>
            <clr-dg-column>
              Téléphone
            </clr-dg-column>
            <!-- <clr-dg-column>
              Fax
            </clr-dg-column> -->
            <clr-dg-column>
              Email
            </clr-dg-column>
            <clr-dg-row *ngFor="let item of contactArray">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="deleteContact(item)">Suppression</button>
              </clr-dg-action-overflow>

              <clr-dg-cell *ngIf="item.firstName">{{item.firstName}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.firstName"><span class="label label-warning">non disponible</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.tel">{{item.firstName}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.tel"><span class="label label-warning">non disponible</span></clr-dg-cell>

              <!-- <clr-dg-cell *ngIf="item.fax">{{item.firstName}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.fax"><span class="label label-warning">non disponible</span></clr-dg-cell> -->

              <clr-dg-cell *ngIf="item.email">{{item.firstName}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.email"><span class="label label-warning">non disponible</span></clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </form>
        <button clrStepButton="next" (click)="loadAdresse()"
          *ngIf="contact.tel && contact.firstName || contactArray.length > 0">Suivant</button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step3">
      <clr-step-title>Adresse</clr-step-title>
      <clr-step-content>
        <h3>Adresses de Société</h3>
        <div *ngIf="showDetailAdr" class="alert alert-success" role="alert">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                L'ajout d'une Addresse a été effectué avec succès ..
              </span>
            </div>
          </div>
        </div>
        <form clrForm>
          <clr-input-container>
            <label>Numéro</label>
            <input type="number" clrInput [(ngModel)]="adresse.number" name="adrName" />
          </clr-input-container>
          <clr-input-container>
            <label>Route</label>
            <input type="text" clrInput [(ngModel)]="adresse.street" name="street" />
          </clr-input-container>
          <clr-input-container>
            <label>Code Postale</label>
            <input type="number" clrInput [(ngModel)]="adresse.geoCode" name="geoCode" />
          </clr-input-container>
          <clr-input-container>
            <label>Ville</label>
            <input type="text" clrInput [(ngModel)]="adresse.city" name="city" />
          </clr-input-container>
          <clr-input-container>
            <label>Gouvernorat</label>
            <input type="text" clrInput [(ngModel)]="adresse.state" name="state" />
          </clr-input-container>
          <br>
          <button class="btn btn-sm" type="submit" (click)="addAdresse()"
            *ngIf="adresse.number && adresse.street && adresse.city">
            Valider
          </button>
          <clr-datagrid *ngIf="showDetailAdr && adressArray.length > 0">
            <clr-dg-column>
              Numéro
            </clr-dg-column>
            <clr-dg-column>
              Route
            </clr-dg-column>
            <clr-dg-column>
              Code Postale
            </clr-dg-column>
            <clr-dg-column>
              Ville
            </clr-dg-column>
            <clr-dg-column>
              Gouvernorat
            </clr-dg-column>
            <clr-dg-row *ngFor="let item of adressArray">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="deleteAdresse(item)">Suppression</button>
              </clr-dg-action-overflow>
              <clr-dg-cell *ngIf="item.number">{{item.number}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.number"><span class="label label-warning">non disponible</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.street">{{item.street}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.street"><span class="label label-warning">non disponible</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.geoCode">{{item.geoCode}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.geoCode"><span class="label label-warning">non disponible</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.city">{{item.city}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.city"><span class="label label-warning">non disponible</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.state">{{item.state}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.state"><span class="label label-warning">non disponible</span></clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </form>
        <button clrStepButton="next"
          *ngIf="adresse.number && adresse.street && adresse.city || adressArray.length > 0">Suivant</button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step4">
      <clr-step-title>Récapitulatif</clr-step-title>
      <clr-step-content>
        <h3 *ngIf="companyObject">Détails de Société</h3>
        <div class="overflow-x" *ngIf="companyObject.name">
          <table class="table">
            <thead>
              <tr>
                <th>Nom de Société</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{companyObject.name}}</td>
                <td *ngIf="companyObject.description">{{companyObject.description}}</td>
                <td *ngIf="!companyObject.description"><span class="label label-warning">Non disponible</span></td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 *ngIf="companyObject.contacts">Contacts</h3>
        <div class="overflow-x" *ngIf="companyObject.contacts">
          <table class="table">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Téléphone</th>
                <!-- <th>Fax</th> -->
                <th>Email</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of companyObject.contacts">
              <tr>
                <td *ngIf="item.firstName">{{item.firstName}}</td>
                <td *ngIf="!item.firstName"><span class="label label-warning">Non disponible</span></td>

                <td *ngIf="item.tel">{{item.tel}}</td>
                <td *ngIf="!item.tel"><span class="label label-warning">Non disponible</span></td>

                <!-- <td *ngIf="item.fax">{{item.fax}}</td>
                <td *ngIf="!item.fax"><span class="label label-warning">Non disponible</span></td> -->

                <td *ngIf="item.email">{{item.email}}</td>
                <td *ngIf="!item.email"><span class="label label-warning">Non disponible</span></td>

              </tr>
            </tbody>
          </table>
        </div>
        <h3 *ngIf="companyObject.adress">Adresses</h3>
        <div class="overflow-x" *ngIf="companyObject.adress">
          <table class="table">
            <thead>
              <tr>
                <th>Numéro</th>
                <th>Route</th>
                <th>Code Postale</th>
                <th>Ville</th>
                <th>Gouvernorat</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of companyObject.adress">
              <tr>
                <td *ngIf="item.number">{{item.number}}</td>
                <td *ngIf="!item.number"><span class="label label-warning">Non disponible</span></td>

                <td *ngIf="item.street">{{item.street}}</td>
                <td *ngIf="!item.street"><span class="label label-warning">Non disponible</span></td>


                <td *ngIf="item.geoCode">{{item.geoCode}}</td>
                <td *ngIf="!item.geoCode"><span class="label label-warning">Non disponible</span></td>

                <td *ngIf="item.city">{{item.city}}</td>
                <td *ngIf="!item.city"><span class="label label-warning">Non disponible</span></td>

                <td *ngIf="item.state">{{item.state}}</td>
                <td *ngIf="!item.state"><span class="label label-warning">Non disponible</span></td>

              </tr>
            </tbody>
          </table>
        </div>
        <br>
        <button class="btn btn-outline-primary" clrStepButton="submit" *ngIf="companyObject.name && !addNew">
          <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-plus" viewBox="-1 -4 16 16">
            <path
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg> Enregitrer
        </button>
      </clr-step-content>
    </clr-stepper-panel>
  </form>

  <div class="btn-group btn-outline-primary">
    <button class="btn" routerLink="../">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> Retour
    </button>
    <button class="btn" (click)="resetForm()" *ngIf="addNew">
      <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-plus" viewBox="-1 -4 16 16">
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg> Créer nouveau
    </button>
  </div>
</div>
</section>
