import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryClientService } from '../category-client.service';
import { categoryClient } from '../categoryClient';

@Component({
  selector: 'app-category-client-edit',
  templateUrl: './category-client-edit.component.html',
  styleUrls: ['./category-client-edit.component.css']
})
export class CategoryClientEditComponent implements OnInit {

  id: number;
  category: categoryClient;
  showMsg: boolean;
  constructor(private categoryClientService: CategoryClientService, 
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = +params.get('id');
      this.categoryClientService.getCategoryClientById(this.id)
        .subscribe(
          data => {
            this.category = data
          })
    });
  }
  submit(category: categoryClient) {
    this.categoryClientService.editCategoryClient(this.id, category)
      .subscribe(
        data => {
          this.showMsg = true;
        })
  }
}
