
<div class="row">
    <div class="col" >

<div class="wrapper">
<h3> {{ 'Relation.Fournisseurs.Nfs' | translate }}</h3>
<button *ngIf="(userRole != 'carrier' && userAutorisation == null) || isAdmin || userAutorisation.addProvider" type="button" class="btn  btn-success btn-sm" style="font-size: x-small;  
text-align:center" routerLink="/parts/provider/add">
    <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-plus">
        <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
    </svg>{{ 'commun.crn' | translate }}
</button>
<a routerLink="/provider/printList" *ngIf="(userRole != 'carrier' && userAutorisation == null) || isAdmin || userAutorisation.printProvider"
 target="_blank" target="_blank">
    <button type="button" class="btn  btn-success btn-sm" style="font-size: x-small;  
  text-align:center">
        {{ 'commun.impliste' | translate }}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer"
            viewBox="0 -4 16 16">
            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            <path
                d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
        </svg>
    </button>
</a>
<div class="filter">
    <clr-combobox-container>
        <label class="carrier-label">{{ 'sharedSales.recherche' | translate }}</label>
        <clr-combobox [(ngModel)]="type" name="name">
            <clr-options *ngIf="types">
                <clr-option *ngFor="let item of types;" clrValue={{translateItem(item)}} (click)="typeChanged(item)">
                    {{translateItem(item)}}
                </clr-option>
            </clr-options>
        </clr-combobox>
    </clr-combobox-container>
    <div class="date-picker" *ngIf="type == translateItem('date')">
        <label for="datepicker">{{ 'sharedSales.date' | translate }}</label><br>
        <input type="date" id="datepicker" (change)="searchWithDate($event)" [(ngModel)]="date_search">
    </div>
    <div *ngIf="type == translateItem('nom')">
        <input type="text" id="search_text" (input)="search_provider($event)" placeholder="{{ 'Relation.Fournisseurs.nfr' | translate }}"
            [(ngModel)]="search_text">
    </div>
</div>
<div> 
    <table class="table table-hover">
        <thead>
            <tr>
                <th style="text-align: center;">{{ 'commun.id' | translate }}</th>
                <th style="text-align: center;">{{ 'Relation.Fournisseurs.nfr' | translate }}</th>
                <th style="text-align: center;">{{ 'commun.dtc' | translate }}</th>
                <th style="text-align: center;"></th>
                <!-- <th style="text-align: center;"></th>
                <th style="text-align: center;"></th> -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of showProvider; let i = index" 
            [ngClass]="{'selected-row': selectedRow === i}" 
            (click)="selectRow(i); displayDetails(item)">
                <td scope="row">{{item?.real_id}}</td>
                <td>
                    {{item?.name}}
                </td>
                <td>
                    {{item?.createdAt | date:'dd/MM/yyyy à HH:mm'}}
                </td>
                <td>
                    <button style="border:none;" type="button" (click)="detailAction(item)"
                        class="btn btn-sm btn-outline-info">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                            class="bi bi-info-circle">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </button>
                </td>
                <!-- <td>
                    <button *ngIf="(userRole != 'carrier' && userAutorisation == null) || isAdmin || userAutorisation.editProvider" routerLink="/parts/provider/edit/{{item.id}}" style="font-size: x-small;  
                    text-align:center; border:none" type="button" class="btn btn-sm btn-outline-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                            class="bi bi-pencil-square">
                            <path
                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                    </button>
                </td>
                <td>
                    <button *ngIf="(userRole != 'carrier' && userAutorisation == null) || isAdmin || userAutorisation.deleteProvider" style="border:none"
                        (click)="deleteAction(item)" type="button" class="btn btn-sm btn-outline-danger">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                            class="bi bi-trash3">
                            <path
                                d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                    </button>
                </td> -->
            </tr>
        </tbody>
    </table>
    <div class="container">
        <div class="page">
            <ngb-pagination (pageChange)="getPaginatedData($event)" [collectionSize]="total" [(page)]="currentPage" [maxSize]="5" [boundaryLinks]="true"
                [pageSize]="pageSize" [rotate]="true" class="d-flex justify-content-center" style="display:none;">
            </ngb-pagination>
        </div>
        <div class="nbr-page">
            <span class="float-md-left">Page: {{ currentPage }} / {{ nbr_page }}</span>
        </div>
    </div>
</div>
</div>

</div>
 




<div class="col"  [style.width.%]="100 - colWidth" *ngIf="showDetails" style="background-color: #f4f4f4; min-height: 600px;">
  <div class="action-row">
  
    
    <div class="button-container">

      <button  type="button"
      [routerLink]="['/buying/add']" 
                class="btn btn-sm " style="border: none;">
               
          Nouvelle facture
          
              </button>
              <button   type="button"
             
                class="btn btn-sm" style="border: none;">
             
          Nouveau devis
                
              </button>
              <button *ngIf="(userRole != 'carrier' && userAutorisation == null) || isAdmin || userAutorisation.editProvider" [routerLink]="['/parts/provider/edit', selecteditem.id]"  
              type="button" class="btn btn-sm " style="border: none;">
                       Modifer
                    </button>
              <button *ngIf="(userRole != 'carrier' && userAutorisation == null) || isAdmin || userAutorisation.deleteProvider" 
                        (click)="deleteAction(selecteditem)" type="button"  class="btn btn-sm " style="border: none;">
                       Supprimer
                    </button>
              
    </div>
    
  
    <button class="close-buttonA" (click)="closeDetails()">X</button>
  </div>
  
  

  

  <div class=" invoice-new-template">
   
        

        <div class="invoice-header">
            <div class="name-box" style="display: flex; justify-content: space-between;">
                <span style="text-align: start;">Référence Fournisseur N° {{selecteditem?.id}}</span>
                <span style="text-align: end;">Matricule Fiscale : {{selecteditem?.mf}}</span>
            </div>
            

                    <div class="invoice-number">
                        <h1>{{selecteditem?.name}}</h1> 
          
                    </div>
        
                   
                
       <br>

            
           
        </div>






        <nav >
            <a [routerLink]="['/info']" (click)="showSection('info')" [class.active]="activeSection === 'info'" class="nav-link">Info</a>
            <a [routerLink]="['/factures']" (click)="showSection('factures')" [class.active]="activeSection === 'factures'" class="nav-link">Factures</a>
            <a [routerLink]="['/devis']" (click)="showSection('devis')" [class.active]="activeSection === 'devis'" class="nav-link">Devis</a>
            <a [routerLink]="['/notes']" (click)="showSection('notes')" [class.active]="activeSection === 'notes'" class="nav-link">Notes</a>
        </nav>
        

        <section *ngIf="activeSection === 'info'" id="info">
            <h4>Description :</h4>
            <span> {{selecteditem?.description}}</span>

            <h4>Contacts:</h4>
            <div class="info-section">
                <p><strong>Nom:</strong> {{selecteditem?.contacts[0].firstName}}</p>
                <p><strong>Téléphone:</strong> {{selecteditem?.contacts[0].tel}}</p>
                <p><strong>Fax:</strong> {{selecteditem?.contacts[0].fax}}</p>
                <p><strong>Email:</strong> {{selecteditem?.contacts[0].email}}</p>
            </div>
            <h4>Adresses:</h4>
            <div class="info-section">
                <p><strong>Numéro:</strong> {{selecteditem?.adress[0].number}}</p>
                <p><strong>Route:</strong> {{selecteditem?.adress[0].street}}</p>
                <p><strong>Code postal:</strong> {{selecteditem?.adress[0].geoCode}}</p>
                <p><strong>Ville:</strong> {{selecteditem?.adress[0].city}}</p>
                <p><strong>Gouvernorat:</strong> {{selecteditem?.adress[0].state}}</p>
            </div>
        </section>
        

        <section *ngIf="activeSection === 'factures'" class="factures">
            <h4>Factures</h4>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Numéro</th>
                        <th>Date de Facture</th>
                        <th>Échéance</th>
                        <th>Total HT</th>
                        <th>Total TTC</th>
                        <th>Statut</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="factures.length === 0">
                        <td colspan="6">Aucune facture pour ce fournisseur</td>
                    </tr>
                    <tr *ngFor="let facture of factures">
                        <td>{{facture.real_id}}</td>
                        <td>{{facture.createdAt         | date:'dd/MM/yyyy'}}</td>
                        <td>{{facture.dateExpiration       | date:'dd/MM/yyyy'}}</td>
                        <td>{{facture.totalPrice | currency}}</td>
                        <td></td>
                        <td><span *ngIf="!facture.isCompleted" class="label label-warning"> {{ 'commun.nv' | translate
                        }}</span>
                      <span *ngIf="facture.isCompleted" class="label label-info"> {{ 'commun.vl' | translate
                        }}</span></td>
                    </tr>
                </tbody>
            </table>
        </section>

        <section *ngIf="activeSection === 'devis'" class="devis">
            <h4>Devis</h4>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Numéro</th>
                        <th>Date de Devis</th>
                        <th>Échéance</th>
                        <th>Total HT</th>
                        <th>Total TTC</th>
                        <th>Statut</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="devis.length === 0">
                        <td colspan="6">Aucun devis pour ce fournisseur</td>
                    </tr>
                    <tr *ngFor="let devisItem of devis">
                        <td>{{devisItem.numero}}</td>
                        <td>{{devisItem.date | date:'dd/MM/yyyy'}}</td>
                        <td>{{devisItem.echeance | date:'dd/MM/yyyy'}}</td>
                        <td>{{devisItem.totalHT | currency}}</td>
                        <td>{{devisItem.totalTTC | currency}}</td>
                        <td>{{devisItem.statut}}</td>
                    </tr>
                </tbody>
            </table>
        </section>

        <section *ngIf="activeSection === 'notes'" class="notes">
            <h4>Notes</h4>
            <button class="btnnote" (click)="addComment()">Nouveau commentaire</button>
            <div *ngIf="showCommentForm">
                <textarea placeholder="Écrire un commentaire..."></textarea>
                <button class="btnnote" (click)="saveComment()">Enregistrer</button>
                <button  class="btnnote" (click)="cancelComment()">Annuler</button>
            </div>
        </section>
    </div>


  
  




</div>





</div>

<app-provider-detail *ngIf="showDetail" (closed)="closeAction()" [providerId]="providerId" (saved)="refresh($event)">
</app-provider-detail>
<app-provider-delete *ngIf="showAlert" (closed)="closeAction()" [providerId]="providerId" (saved)="refresh($event)">
</app-provider-delete>