import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { environment } from "src/environments/environment"
import { EtatCommande } from "./etat-commande"
import { FilterDto } from "src/app/filter.dto"


@Injectable({
  providedIn: 'root'
})
export class EtatCommandeService {

  constructor(private http: HttpClient) { }

  getEtatsCommande(filter: FilterDto): Observable<[EtatCommande[], number]> {
    return this.http.get(environment.api + "/etatCommande?filter=" + JSON.stringify(filter)) as Observable<[EtatCommande[], number]>
  }

  addEtatCommande(model: EtatCommande): Observable<EtatCommande> {
    return this.http.post<EtatCommande>(environment.api + "/etatCommande", model)
  }

  updateEtatCommande(id: number, model: EtatCommande): Observable<EtatCommande> {
    return this.http.patch<EtatCommande>(environment.api + "/etatCommande/" + JSON.stringify(id), model)
  }

  deleteEtatCommande(id: number) {
    return this.http.delete(environment.api + "/etatCommande/" + JSON.stringify(id))
  }
}