import { Crates } from "../crates/crates";
import { Points } from "../sales-point/points";
import { Abonnement } from "../setting/setting";
import { Truck } from "../transport/truck/truck";
import { Autorisation } from "./autorisation/autorisation";
import { Entreprise } from "./entreprise";
import {  Address } from '../parts/parts';
export class User {
  constructor(
    public id?: number,
    public name?: string,
    public nameSociete?: string, 
    public email?: string,
    public oldPassword?: string,
    public password?: string,
    public repassword?: string,
    public siretNumber?: number,
    public fonction?: string,
    public categoryCustomerId?: number & CategoryCustomer | null,
    public picture?: string,
    public active?: boolean,
    public resToken?: string,
    public contactId?: Contact,
    public abonnementId?: Abonnement[] | number,
    public role?: string,
    public createdBy?: number,
    public createdAt?: string,
    public updatedBy?: number,
    public entrepriseId?: Entreprise & number,
    public real_id?: number,
    public deviseId?: number,
    public mf?: string,
    public cratesId?: Crates,
    public tel?: string,
    public pointId?: Points,
    public truckId?: Truck,
    public autorisationId?: Autorisation & number | null,
    public adress?: Address[]
  ) { }
}
export class Devise {
  public id: number;
  public name?: string;
  public code?: string;
  public active?: boolean
}
export class PostUser {
  constructor(
    public id?: number,
    public name?: string,
    public email?: string,
    public tel?: string,
    public oldPassword?: string,
    public password?: string,
    public repassword?: string,
    public abonnementId?: Abonnement[] | number,
    public picture?: string,
    public active?: boolean,
    public contactId?: Contact,
    public role?: string,
    public entrepriseId?: number,
    public createdBy?: number | Entreprise,
    public updatedBy?: number,
    public real_id?: number,
    public cratesId?: Crates,
    public pointId?: Points,
    public truckId?: Truck,
    public autorisationId? : Autorisation & number
  ) { }
}

export class PictureObject {
  picture: string;
  url: string;
  url2: string;
  constructor(
    public base64Data?: string,
  ) { }
}

export class PictureAccount {
  constructor(
    public url?: string,
    public cloudName?: string,
    public apiKey?: string,
    public apiSecret?: string,
  ) { }
}



export class Contact {
  public id?: number;
  public firstName?: string
  public lastName?: string;
  public tel?: number;
  public fax?: number;
  public email?: string;
  public createdAt?: string;
  public updatedAt?: string;
  public createdBy?: number;
  public active?: boolean;
  public providerId?: number;
  public customerId?: number;
  public carrierId?: number;
  public userId?: number;
  public updatedBy?: number;
  public entrepriseId?: number

}


export class News {
  constructor(
    public id?: number,
    public content?: string,
    public name?: string,
    public active?: boolean,
    public createdAt?: Date,
    public updatedAt?: Date,
    public pictures?: NewsPicture[]
  ) { }
}

export class NewsPicture {
  constructor(
    public id?: number,
    public url?: string,
    public defaults?: boolean,
    public createdAt?: string,
    public updatedAt?: string,
    public createdBy?: number,
    public updatedBy?: number,
    public active?: boolean,
    public newsId?: number & News
  ) { }
}

export class CategoryCustomer{
  constructor(
    public id?: number,
    public name?: string,
  ) { }
}