import { Component, HostListener, OnInit } from '@angular/core';
import { ClrDatagridStateInterface, ClrDatagridSortOrder } from '@clr/angular';
import jwtDecode from 'jwt-decode';
import { CoreService } from 'src/app/core/core.service';
import { FilterDto } from 'src/app/filter.dto';
import { FilterService } from 'src/app/shared/filter.service';
import { UserService, tokenGetter, getValueFromToken } from 'src/app/user/user.service';
import { WhereFindOperator } from 'src/app/whereFindOperator';
import { Providers } from '../parts';
import { PartsService } from '../parts.service';
import { Autorisation } from 'src/app/user/autorisation/autorisation';
import { TranslateService } from '@ngx-translate/core';
import { BuyingService } from '../../buying/buying.service';
import { Buying } from '../../buying/buying';
import { StockService } from '../../stock/stock.service';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css']
})
export class ProviderComponent implements OnInit {
  filter: FilterDto<Providers> = new FilterDto<Providers>()
  whereFindOperator = new WhereFindOperator()
  descSort: ClrDatagridSortOrder;
  providerFilter: boolean
  descProvider: boolean
  showAlert: boolean; 
  providerArray: Providers[] = []
  showProvider: Providers[] = []
  tmpList: Providers[] = []
  count: number
  loading: boolean
  providerObject: Providers;
  providerId: number;
  searchString: string;
  pathName: string;
  showDetail: boolean;
  id_entreprise: number
  userRole: string
  currentPage: number = 1
  nbr_page: number = 1
  total: number
  pageSize: number = 5
  insertIndex: number = 0
  type: string
  types: string[] = ["date", "nom"]
  date_search: Date
  search_text: string
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""  
  userAutorisation : Autorisation = new Autorisation()
  userId : number
  isAdmin : boolean = false 

  constructor(private userService : UserService, private partsservice: PartsService, 
    private serviceCore: CoreService, private translate:TranslateService, private buyingService: BuyingService, private stockservice:StockService) {
    this.pathName = window.location.pathname
    this.descSort = ClrDatagridSortOrder.ASC;
    if (this.pathName == '/search') {
      this.filter = new FilterDto<Providers>()
      window.addEventListener('find', (evt: any) => {
        this.searchString = evt.detail
        this.refresh()
      });
    } else {
      this.searchString = undefined
    }
  }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
   

    this.userRole = jwtDecode(tokenGetter())["role"]
    this.userId = parseInt(jwtDecode(tokenGetter())["id"])
    this.isAdmin = this.userRole == "admin"
    if(!this.isAdmin){
      this.getUserAutorisation()
    }
    this.descSort = ClrDatagridSortOrder.ASC;
    if (this.pathName == '/search') {
      this.filter = new FilterDto<Providers>()
      this.filter.where={}
      this.searchString = this.serviceCore.getSearchStream()
    } else {
      this.searchString = undefined
    }
    this.filter.where={}
    this.filter.take = this.pageSize
    this.getProviders();
   
  }

  getUserAutorisation(){
    this.userService.getUserAutorisation(this.userId).subscribe(
      data => {
        this.userAutorisation = data[0][0].autorisationId
      }
    )
  }
  getProviders() {
    if (!this.providerArray[this.insertIndex]){
      this.filter.where =Object.assign(this.filter.where.valueOf(),{ 
        "entrepriseId": this.id_entreprise 
      })
      this.filter.order = { "id": "DESC" }
      this.filter.relations = ["contacts", "adress"]
      this.partsservice.getProviders(this.filter).subscribe(
        data => {
          for (let i = 0; i < data[0].length; i++) {
            this.providerArray[this.insertIndex + i] = data[0][i];
          }
          this.tmpList = this.providerArray
          this.showProvider = this.providerArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
          this.total = data[1]
          if (this.total > 0) {
            this.nbr_page = Math.ceil(this.total / this.pageSize)
          } else {
            this.nbr_page = 1
          }
        }
      )
    } else {
      this.showProvider = this.providerArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
    }
  }
  getPaginatedData(currentPage) {
    this.filter.skip = (currentPage - 1) * this.pageSize
    this.insertIndex = this.filter.skip ? this.filter.skip : 0
    this.getProviders()
  }
  searchWithDate(event:Event) {
    let date_search = (event.target as HTMLInputElement).value
    if (date_search !== '' && date_search.split('-')[0].startsWith('2')) {
      let date_debut = new Date(date_search).setHours(0, 0, 1)
      let date_end = new Date(date_search).setHours(23, 59, 59)
      this.filter.where = {
        createdAt: {
          type: "between",
          value: [new Date(date_debut), new Date(date_end)],
        },
      };
      this.initialVariables()
      this.getProviders()
    } else if (date_search == '') {
      this.filter.where = {}
      this.initialVariables()
      this.getProviders()
    }
  }
  initialVariables(){
    this.providerArray = []
    this.insertIndex = 0
    this.currentPage=1
    this.filter.skip = 0
  }
  search_provider(event:Event) {
    let saisie = ((event.target as HTMLInputElement).value)
    if (this.search_text.length == 0) {
      this.providerArray = []
      this.total = 1
      this.filter.where = {}
      this.getPaginatedData(1)
    } else {
      this.providerArray = []
      this.tmpList.filter(x => {
        if (x.name.toLowerCase().indexOf(this.search_text.toLowerCase().trim()) != -1) {
          this.providerArray.push(x);
        }
      })  
      this.total = this.providerArray.length
      if (this.total > 0) {
        this.nbr_page = Math.ceil(this.total / this.pageSize)
      } else {
        this.nbr_page = 1
      }
      this.showProvider = this.providerArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
      if (this.providerArray.length == 0 && saisie[saisie.length - 1] !== ' ') {
        this.filter.order = { "id": "ASC" }
        this.filter.take = this.pageSize;
        this.filter.where = { name: {
          type: "like",
          value: "*" + this.search_text.trim() + "*"
        }}
        this.getProviders()
      }
    }
  }
  refresh(state?: ClrDatagridStateInterface) {
    // if (state) {
    //   this.filter = this.filterService.bind(state);
    // }
    // if (state?.filters) {
    //   this.whereFindOperator.key = this.filterService.dateSearch(state)
    // }
    // if (!this.whereFindOperator.key) { this.whereFindOperator.key = undefined }
    // let data = { active: true }
    // if (!state?.filters) {
    //   this.filter.where = data
    // }
    // else {
    //   this.filter.where = Object.assign(this.filter.where.valueOf(), data)
    //   if (this.whereFindOperator.key) {
    //     this.filter.where = Object.assign(this.filter.where.valueOf(), this.whereFindOperator.key)
    //   }
    // }
    // if (this.searchString) {
    //   this.filter.where = Object.assign(this.filter.where.valueOf(), { name: { type: "like", value: "*" + this.searchString + "*" } })
    // }
    // this.filter.order = { "id": "DESC" }
    // this.filter.where = { "entrepriseId": this.id_entreprise }
    // this.partsservice.getProviders(this.filter).subscribe(
    //   data => {
    //     this.providerArray = data[0];
    //     this.count = data[1];
    //   }
    // )
  }
  deleteAction(item) {
    this.providerId = item.id
    this.showAlert = true;
  }
  detailAction(item) {
    this.providerId = item.id
    this.showDetail = true;
  }
  closeAction() {
    this.showAlert = false;
    this.showDetail = false;
  }

  openProvFilter() {
    this.providerFilter = true
  }
  openDescription() {
    this.descProvider = true
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
  translateItem(item: string): string {
    return this.translate.instant(`sharedSales.${item}`);
  }

  /*************************************************************************** */
  

  
  activeSection: string = 'info'; // Default to 'factures'

  devis: any[] = [];
  showCommentForm: boolean = false;

  showSection(section: string) {
    this.activeSection = section;
  }

  addComment() {
    this.showCommentForm = true;
  }

  saveComment() {
    // Implement saving logic here
    this.showCommentForm = false;
  }

  cancelComment() {
    this.showCommentForm = false;
  }

  /********************************************************************** */

  selectedRow: number | null = null;
  showDetails:boolean=false
selectRow(index: number) {
    this.selectedRow = index;
    this.showDetails=true
}
closeDetails(){
  this.showDetails=false
  this.selecteditem = null;
  this.selectedRow=null
}

  selecteditem:any
  allContact = []
  allAdress = []
  displayDetails(item: any): void {

    this.selecteditem = item;
    this.search.order = { "id": 'DESC' };
  
    this.search.where = { "providerId": { "id": item.id } };
    this.initialVariables()
    this.getBuying(this.search)
    console.log(item)





 
  }







/*************************************************************** */
search: FilterDto= new FilterDto();
factures: any = []
buying: Buying[] = [];



getBuying(search: FilterDto) {

 
    console.log("helllo")
    if (this.isAdmin) {
      this.search.where = Object.assign(this.search.where.valueOf(), {
        "entrepriseId": this.id_entreprise, "active": true
      })
      console.log("helllo1")
    } if (this.userRole == "writer") {
      this.search.where = Object.assign(this.search.where.valueOf(), {
        "entrepriseId": this.id_entreprise, "active": true,
      })
      console.log("helll2o")
    } else if (this.userRole == "responsable" || this.userRole == "carrier") {
      this.search.where = Object.assign(this.search.where.valueOf(), {
        "entrepriseId": this.id_entreprise, "active": true,
      })
      console.log("helllo3")
    }
    this.search.where = Object.assign(this.search.where.valueOf(),{ 
      "entrepriseId": this.id_entreprise 
    })

  
    this.buyingService.getBuying(this.search).subscribe(
      data => {
 

      
        this.factures = data[0]
        console.log("factures==== ",this.factures )
 
       
      }
    )
  

}



}
