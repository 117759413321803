<section>
  <div class="wrapper">
    <h3> {{ 'sales.commande' | translate }} </h3>
    <div *ngIf="showAlertSuccess" class="alert alert-success" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
              viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <span class="alert-text">
            {{msgAlert}}
          </span>
        </div>
      </div>
      <button type="button" class="close" (click)="showAlertSuccess=false" aria-label="Close">
        <clr-icon aria-hidden="true" shape="close"></clr-icon>
      </button>
    </div>
    <div *ngIf="showAlertError" class="alert alert-danger" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-exclamation-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
          </div>
          <span class="alert-text">
            {{msgAlert}}
          </span>
        </div>
      </div>
      <button type="button" class="close" (click)="showAlertError=false" aria-label="Close">
        <clr-icon aria-hidden="true" shape="close"></clr-icon>
      </button>
    </div>
    <button *ngIf="isAdmin || (userAutorisation == null && userRole != 'writer') || userAutorisation.addSales==true" type="button" class="btn  btn-success btn-sm" style="font-size: x-small;  
      text-align:center" routerLink="/commande/add">
      <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-plus">
        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg>{{ 'commun.crn' | translate }}
    </button>
    <div class="filter">
      <clr-combobox-container>
        <label class="carrier-label">{{ 'sharedSales.recherche' | translate }}</label>
        <clr-combobox [(ngModel)]="type" name="name">
          <clr-options *ngIf="types">
            <clr-option *ngFor="let item of types;" clrValue={{translateItem(item)}} (click)="typeChanged(item)">
              {{ translateItem(item) }}
            </clr-option>
          </clr-options>
        </clr-combobox>
      </clr-combobox-container>
      <div class="date-picker" *ngIf="type == translateItem('date')">
        <label for="datepicker">{{ 'commun.Date' | translate }} </label><br>
        <input type="date" id="datepicker" (change)="searchWithDate($event)" [(ngModel)]="date_search">
      </div>
      <clr-combobox-container *ngIf="type == translateItem('client')">
        <label class="carrier-label">{{ 'sales.client' | translate }}</label>
        <clr-combobox name="name">
          <clr-options *ngIf="customers">
            <clr-option *ngFor="let item of customers;" clrValue={{item.name}} (click)="customerChanged(item)">
              {{item.name}}
            </clr-option>
          </clr-options>
        </clr-combobox>
      </clr-combobox-container>
    </div>
    <div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th style="text-align: center;">{{ 'commun.id' | translate }}</th>
          <th style="text-align: center;">{{ 'ventes.cl' | translate }}</th>
          <th style="text-align: center;">{{ 'commun.etat' | translate }}</th>
          <th style="text-align: center;">{{ 'sharedSales.datelim' | translate }}</th>
          <th style="text-align: center;"></th>
          <th style="text-align: center;"></th>
          <th style="text-align: center;"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let commande of showCommande; index as i ">
          <td scope="row">{{commande.real_id}}</td>
          <td>
            <span *ngIf="commande?.customerId?.name">{{commande.customerId?.name}}</span>
            <span *ngIf="!commande?.customerId?.name" class="label label-danger"> {{ 'commun.nd' | translate }} </span>
          </td>




          <td>
            <div class="etatWithChange" *ngIf="commande?.etatCommandeId?.coefficient!==5">
              <span class="label label-danger" *ngIf="commande?.etatCommandeId?.coefficient!=5">{{ commande.etatCommandeId?.name }}</span>
              <div class="etatSwich">
                <button class="changeEtat">
                  <i class="fa fa-chevron-down" style="font-size:15px" style="color:#493f71"></i>
                </button>
                <div class="etat-dropdown">
                  <a class="etatItem" *ngFor="let item of etatCommandes" (click)="changeEtatCommande(commande, item)" [ngClass]="{'etatActiveItem' : commande.etatCommandeId?.id===item.id}">{{item.name}}</a>
                </div>
              </div>
            </div>
            <span *ngIf="commande?.etatCommandeId?.coefficient==5" class="label label-success"> {{ commande.etatCommandeId?.name }}</span>
          </td>







          
          <td>
            <span *ngIf="commande.createdAt">{{commande.createdAt | date:'dd/MM/yyyy à HH:mm'}}</span>
            <span *ngIf="!commande.createdAt" class="label label-danger"> {{ 'commun.nd' | translate }}</span>
          </td>
          <td>
            <button style="border:none;" type="button" (click)="detailAction(commande.id)"
              class="btn btn-sm btn-outline-info">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                class="bi bi-info-circle">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </button>
          </td>
          <td>
            <button *ngIf="isAdmin || (userAutorisation == null && userRole != 'writer') || userAutorisation.editSales==true" routerLink="/commande/edit/{{commande.id}}" style="font-size: x-small;  
              text-align:center; border:none" type="button" class="btn btn-sm btn-outline-primary">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                class="bi bi-pencil-square">
                <path
                  d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
              </svg>
            </button>
          </td>
          <td>
            <button *ngIf="isAdmin || (userAutorisation == null && userRole != 'writer') || userAutorisation.deleteSales==true" style="border:none" (click)="deleteAction(commande.id)" type="button"
              class="btn btn-sm btn-outline-danger">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-trash3">
                <path
                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="container">
      <div class="page">
        <ngb-pagination (pageChange)="getPaginatedData($event)" [collectionSize]="total" [(page)]="currentPage" [maxSize]="5" [boundaryLinks]="true"
          [pageSize]="pageSize" [rotate]="true" class="d-flex justify-content-center" style="display:none;">
        </ngb-pagination>
      </div>
      <div class="nbr-page">
        <span class="float-md-left" *ngIf="!arabicLanguage">{{ 'commun.page' | translate }} {{ currentPage }} / {{ nbr_page }}</span>
        <span class="float-md-left" *ngIf="arabicLanguage">{{ currentPage }} / {{ nbr_page }} {{ 'commun.page' | translate }}</span>
      </div>
    </div>
  </div>
  </div>
</section>
<app-commande-detail *ngIf="showDetail" [commandeId]="commandeId" (closed)="showDetail = false"></app-commande-detail>
<app-commande-delete *ngIf="showAlert" [commandeId]="commandeId" (closed)="close()" (saved)="save()"></app-commande-delete>