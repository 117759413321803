import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tax } from './tax';
import { FilterDto } from 'src/app/filter.dto';

@Injectable({
  providedIn: 'root'
})
export class TaxService {

  constructor(private http: HttpClient) { }
  getTaxs(filter: FilterDto<Tax>): Observable<[Tax[], number]> {

    return this.http.get(environment.api + '/taxs?filter=' + JSON.stringify(filter)) as Observable<[Tax[], number]>;
  }

  getTaxById(ID: number): Observable<any> {
    return this.http.get(environment.api + '/tax/' + JSON.stringify(ID)) as Observable<Tax[]>;
  }

  editTax(ID: number, tax: Tax): Observable<any> {
    return this.http.patch<Tax>(environment.api + '/tax/' + JSON.stringify(ID), tax);
  }

  addTax(tax: Tax): Observable<Tax> {
    return this.http.post<Tax>(environment.api + '/tax', tax);
  }

  deleteTax(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/tax/' + JSON.stringify(ID)) as Observable<Tax[]>;
  }
}
