<h3> Modifier Blog </h3>
<div *ngIf="showAlertSuccess" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
          viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path
            d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg>
      </div>
      <span class="alert-text">
        {{msgAlert}}
      </span>
    </div>
  </div>
  <button type="button" class="close" (click)="showAlertSuccess=false" aria-label="Close">
    <clr-icon aria-hidden="true" shape="close"></clr-icon>
  </button>
</div>
<div *ngIf="showAlertError" class="alert alert-danger" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-exclamation-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path
            d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
        </svg>
      </div>
      <span class="alert-text">
        {{msgAlert}}
      </span>
    </div>
  </div>
  <button type="button" class="close" (click)="showAlertError=false" aria-label="Close">
    <clr-icon aria-hidden="true" shape="close"></clr-icon>
  </button>
</div>
<div class="alert alert-danger" role="alert" *ngIf="tailleInvalid">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{ 'commun.fv' | translate }}
        </span>
      </div>
    </div>
</div>
<div class="clr-row">
  <div class="clr-col-sm-10">
    <form clrForm>
        <clr-input-container class="clrInput">
          <label>Title *</label>
          <input placeholder="Title" clrInput [(ngModel)]="model.title" name="title"
            required />
          <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
        </clr-input-container>
        <clr-input-container class="clrInput" style="height: 150px;">
          <label>Description *</label>
          <textarea placeholder="Description..." rows="4" cols="50" [(ngModel)]="model.description"
            name="reference" clrInput required style="min-height: 150px; overflow-y: scroll; border: 0.05rem solid"></textarea>
            <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
        </clr-input-container>
        <div class="clr-row">
          <label style="font-weight:600">Image *</label>
          <div class="clr-col-12 clr-col-sm-12	clr-col-md-4	clr-col-lg-4	clr-col-xl-2">
            <a (click)="fileInput.click()" class="card">
              <clr-icon class="plus-icon" shape="plus-circle"></clr-icon>
            </a>
            <input hidden type="file" #fileInput (change)="picked($event)">
          </div>
          <div *ngIf="model.picture" class="clr-col-12 clr-col-sm-3	clr-col-md-4	clr-col-lg-4	clr-col-xl-2   img">
            <div class="card clickable">
              <div class="card-img container">
                <div class="clr-row clr-justify-content-between">
                  <div class="clr-justify-content-end">
                    <span>
                      <button type="button" class="btn btn-icon btn-warning btn-sm delete-icon" aria-label="warning"
                        (click)="deletePicAction()">
                        <clr-icon shape="times"></clr-icon>
                      </button>
                    </span>
                  </div>
                </div>
                <img [src]="model.picture">
              </div>
            </div>
          </div>
        </div>
        <clr-toggle-container>
          <label> Apparaitre *</label>
          <clr-toggle-wrapper>
              <input type="checkbox" clrToggle name="show" [(ngModel)]="model.show" />
          </clr-toggle-wrapper>
        </clr-toggle-container>
    </form>
    <div class="btn-group btn-outline-primary" style="margin-top: 50px;">
        <button class="btn" routerLink="/blogs">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
            class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
        </svg> {{ 'login.tt' | translate }}
        </button>
        <button class="btn" (click)="submit()"
        [disabled]="!model.title || !model.description || !model.picture">
        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-plus" viewBox="-1 -4 16 16">
            <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> {{ 'reg.e' | translate }}
        </button>
    </div>
  </div>
</div>