import { Component, HostListener, OnInit } from '@angular/core';
import { decodeToken, getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import jwt_decode from "jwt-decode";
import { ConfigService } from 'src/app/shared/config/config.service';
import { Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Configuration } from 'src/app/setting/setting';
import { CoreService } from '../core.service';
import { TranslateService } from '@ngx-translate/core';
import '@clr/icons/shapes/core-shapes';
import '@clr/icons/shapes/essential-shapes';
import '@clr/icons/shapes/technology-shapes';
import { SettingService } from 'src/app/setting/setting.service';
import { Points } from 'src/app/sales-point/points';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { User } from 'src/app/user/user';
import { Autorisation } from 'src/app/user/autorisation/autorisation';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  abn: boolean;
  triale: boolean;
  userRole: string
  show: boolean = true;
  filter: FilterDto<Configuration> = new FilterDto<Configuration>()
  nameComplet: string;
  nameCourt: string;
  pictureConfig: string;
  silver: boolean = false;
  showCmd: boolean = true;
  showLivraison: boolean;
  showabnLivraison: boolean;
  showabnCompany: boolean;
  currentLang: string;
  y: string;
  id_entreprise: number
  showAlert: boolean = false;
  // isAuthorized: boolean = false
  // isAuthorizedLivraison: boolean = false
  // isAuthorizedSalesPoint: boolean = false
  user_id: number
  salespoints: Points[] = []
  carriersListe: User[] = []
  salespointCarriers: Points[] = []
  isAdmin: boolean = false
  autorisationUser : Autorisation = new Autorisation()

  constructor(public translate: TranslateService, private configService: ConfigService,
    private router: Router, private serviceCore: CoreService,
    private settingService: SettingService,
     private salesPointService: SalesPointService
  ) {
    // this.reste_triale = getValueFromToken("triale")
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.y = localStorage.getItem('y');
    this.translate.use(this.currentLang);
    window.addEventListener('RefreshSalesPoints', (evt: any) => {
      this.getSalesPoints();
    });

    // window.addEventListener('RefreshSideNav',(evt:any)=> {
    //   this.getAbonnementInfo();
    // })
  }

  // @HostListener('document:click', ['$event'])
  // handleCloseDetails(event: MouseEvent) {
  //   let creditRoute = document.getElementById('credit_route');
  //   console.log(event.target)
  //   if (event.target !== creditRoute) {
  //     creditRoute.classList.remove("active")
  //     // transaction_link.classList.remove("active")
  //   } else {
  //     creditRoute.classList.add("active")
  //   }
  // }

  // @HostListener('document:click', ['$event'])
  // handleCloseMenu(event: MouseEvent) {

  // } 

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.user_id = parseInt(getValueFromToken("id"))
    this.triale = getValueFromToken('triale')
    this.userRole = getValueFromToken("role");
    this.abn = getValueFromToken('abonee')
    // this.getAbonnementInfo()
    this.isAdmin = this.userRole == "admin"
    if (this.isAdmin) {
      this.getSalesPoints()
    }
    if (!this.abn && !this.triale) {
      this.router.navigate(['/setting/company']);
      this.show = true
    }
    if (this.triale) {
      this.showabnLivraison = true
      this.showabnCompany = true
    }
    // if (this.abn) {
    //   let element = getValueFromToken('showLivraison')
    //   if (element) {
    //     var el = JSON.parse(element)
    //   }
    //   if (el != null) {
    //     this.showLivraison = el;
    //   }
    //   if (this.showLivraison) {
    //     this.showabnLivraison = true
    //     this.showabnCompany = false
    //   } else {
    //     this.showabnLivraison = false
    //     this.showabnCompany = true
    //   }
    // }
    this.getConfiguration();
  }
  // navigateToCredit(args?: string) {
  //   if (args?.length == 0) {
  //     this.router.navigate(["/finance/transaction"])
  //   } else {
  //     var element = document.getElementById("credit_route")
  //     var transaction_link = document.getElementById("transaction_route")
  //     element.classList.add("active")
  //     transaction_link.classList.remove("active")
  //     this.router.navigate(["/finance/transaction"], { queryParams: { credit: 'true' } })
  //   }
  // }
  getSalesPoints() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    filter.relations = ["userId"];
    this.salesPointService.getPoints(filter).subscribe(
      data => {
        let pt_sale: boolean;
        this.salespoints = []
        data[0].forEach(el => {
          pt_sale = true
          if (el.userId?.length > 0) {
            el.userId.forEach(element => {
              if (element.role == "carrier") {
                pt_sale = false;
              }
            })
            if (!pt_sale) {
              this.salespointCarriers.push(el);
            } else {
              this.salespoints.push(el);
            }
          } else {
            this.salespoints.push(el)
          }
        })
      }
    )
  }

  // getAbonnementInfo() {
  //   if (this.userRole == "admin") {
  //     let filter = new FilterDto()
  //     filter.where = { id: this.id_entreprise }
  //     filter.relations = ["abonnementId"]
  //     this.settingService.getAllEntreprise(filter).subscribe(
  //       data => {
  //         // let abn = data[0][0].abonnementId
  //         // if (data[0][0]?.abonnementId) {
  //         //   if (abn.livraison) {
  //         //     this.livraison = true
  //         //   } else if (abn.premuim) {
  //         //     this.premuim = true
  //         //   } else if (abn.facturation_caisse) {
  //         //     this.facturation_caisse = true
  //         //   } else if (abn.facturation_only) {
  //         //     this.facturation_only = true
  //         //   }
  //         // } else {
  //         //   if (this.reste_triale == true) {
  //         //     this.premuim = true
  //         //   }
  //         // }
  //       }
  //     )
  //   } else if (this.userRole == "responsable" || this.userRole == "writer" || this.userRole == "carrier") {
  //     let filter = new FilterDto()
  //     filter.where = { "entrepriseId": this.id_entreprise, "id": this.user_id }
  //     filter.relations = ["entrepriseId","autorisationId", "entrepriseId.abonnementId"]
  //     this.settingService.getUserInfo(filter).subscribe(
  //       data => {
  //         this.autorisationUser = data[0][0].autorisationId
  //         let abn = data[0][0].entrepriseId.abonnementId
  //         // if (data[0][0]?.entrepriseId?.abonnementId) {
  //         //   if (abn.livraison) {
  //         //     this.livraison = true
  //         //   } else if (abn.premuim) {
  //         //     this.premuim = true
  //         //   } else if (abn.facturation_caisse) {
  //         //     this.facturation_caisse = true
  //         //   } else if (abn.facturation_only) {
  //         //     this.facturation_only = true
  //         //   }
  //         // } else {
  //         //   if (this.reste_triale == true) {
  //         //     this.premuim = true
  //         //   }
  //         // }
  //       }
  //     )
  //   }
  // }
  getConfiguration() {
    this.filter.select = ["nameComplet", "nameCourt", "picture"]
    this.serviceCore.getconfiguration(this.filter).subscribe(
      data => {
        this.nameComplet = data[0][0]?.nameComplet;
        this.nameCourt = data[0][0]?.nameCourt;
        this.pictureConfig = data[0][0]?.picture;
      })
  }
  ExportData() {
    this.serviceCore.exportData(this.id_entreprise).subscribe(
    )
  }
  impoexpo() {
    this.showAlert = true
  }
  closeAction() {
    this.showAlert = false
  }
}
