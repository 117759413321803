import { Injectable } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { FilterDto } from '../filter.dto';
import { formatDate, WhereFindOperator } from '../whereFindOperator';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  whereFindOperator = new WhereFindOperator()
  whereDateOperator = new WhereFindOperator()
  values = [];
  startDate: string;
  endtDate: string;
  keyTransaction: { transactionTypeId: { type: string; value: any[]; }; };
  keyOperation: { operationTypeId: { type: string; value: any[]; }; };

  verif: boolean;
  keyCustomer: { UserId: { type: string; value: any; }; };
  keyOrder: { id: { type: string; value: any; }; };
  keyProvider: { providerId: { type: string; value: any; }; };
  keyProperty: object
  keyProperty2: object
  keyProperty3: object
  keyProperty4: object

  keyProduct: { productId: { type: string; value: any; }; };
  keyCarrier: { carrierId: { type: string; value: any; }; };
  keyTruck: { truckId: { type: string; value: any; }; };

  constructor() { }

  bind(state: ClrDatagridStateInterface) {
    let filter = new FilterDto();
    // order
    if (state.sort != null) {
      let orderType: any;
      if (state.sort.reverse == true) {
        orderType = 'ASC';
      } else {
        orderType = 'DESC';
      }
      let key: any = state.sort.by
      filter.order = { [key]: orderType }
    }

    // pagination
    if (state.page) {
      if (state.page.from != null) {
        if (0 > state.page.from) {
          filter.skip = 0;
        } else {
          filter.skip = state.page.from;
        }
        if (0 > state.page.size) {
          filter.take = 0;
        } else {
          filter.take = state.page.size;
        }

      }
    }
    let initialValue = {};

    // filter
    if (state.filters != null) {
      if ('filters' in state) {
        let i = 0
        for (let filter of state.filters) {
          if ('filterParamName' in filter) {
            if (filter.filterParamName) {
              let property = filter.filterParamName;
              if (filter.selectedItems?.length == 1) {
                initialValue=filter.where
              }
            }
            // mouvement
            if (filter.filterParamName == 'operationTypeId') {
              let id = filter.selectedItems[0].id
              let property = filter.filterParamName;
              if (filter.selectedItems.length == 1) {
                let value = id
                initialValue[property] = value;
              }
            }
          }
          //  ComboboxFilter

           if('entityName' in filter){
            initialValue = filter.filter.where 
            if(filter.saisie=="anyOne" && filter.type=="all"){
              initialValue={}
            }

           }


            
          if ('idsCustomers' in filter) {
            if (filter.idsCustomers.length == 1) {
              this.keyCustomer = { UserId: { type: "equal", value: filter.idsCustomers[0] } }
            }
            if (filter.idsCustomers.length >= 2) {
              this.keyCustomer = { UserId: { type: "in", value: filter.idsCustomers } }
            }
            if (this.keyCustomer) {
              initialValue = this.keyCustomer
            }
          }
          //  OrderIdFilter
          if ('openOrderIds' in filter) {
            if (filter.idsOperations.length == 1) {
              this.keyOrder = { id: { type: "equal", value: filter.idsOperations[0] } }
            }
            if (filter.idsOperations.length >= 2) {
              this.keyOrder = { id: { type: "in", value: filter.idsOperations } }
            }
            if (this.keyOrder) {
              initialValue = this.keyOrder
            }
          }
          //  ComboboxFilterProvider
          if ('idProviders' in filter) {
            if (filter.idProviders.length == 1) {
              this.keyProvider = { providerId: { type: "equal", value: filter.idProviders[0] } }
            }
            if (filter.idProviders.length >= 2) {
              this.keyProvider = { providerId: { type: "in", value: filter.idProviders } }
            }
            if (this.keyProvider) {
              initialValue = this.keyProvider
            }

          }

          if ('properties' in filter) {
            if (filter.properties == 'name') {
              this.keyProperty = filter.filter.where?.name
              if (this.keyProperty) {
                initialValue["name"] = this.keyProperty
              }
            } else {
              if (filter.properties == 'description') {
                this.keyProperty2 = filter.filter.where?.description
                if (this.keyProperty2) {
                  initialValue["description"] = this.keyProperty2
                }
              } else {
                if (filter.properties == 'reference') {
                  this.keyProperty3 = filter.filter.where?.reference
                  if (this.keyProperty3) {
                    initialValue["reference"] = this.keyProperty3
                  }
                }else{
                  if (filter.properties == 'type') {
                    this.keyProperty4 = filter.filter.where?.type
                    if (this.keyProperty4) {
                      initialValue["type"] = this.keyProperty4
                    }
                  }
                }
              }
            }
          }
          //  ComboboxFilterProduct
          if ('idProducts' in filter) {
            if (filter.idProducts.length == 1) {
              this.keyProduct = { productId: { type: "equal", value: filter.idProducts[0] } }
            }
            if (filter.idProducts.length >= 2) {
              this.keyProduct = { productId: { type: "in", value: filter.idProducts } }
            }
            if (this.keyProduct) {
              initialValue = this.keyProduct
              initialValue = Object.assign(initialValue.valueOf(), this.keyCustomer)
            }

          }
          if (filter.openOpFilter == true || filter.openTrFilter == true || filter.OpenPrFilter == true || filter.OpenCosFilter == true
            || filter.openBkFilter == true || filter.carrierFilter == true || filter.providerFilter == true || filter.companyFilter == true || filter.openCustomerFilter == true) {
            let aux = filter.filter.where.name
            let keyCombobox = { name: aux }
            if (keyCombobox) {
              initialValue = Object.assign(initialValue.valueOf(), keyCombobox)
            }
          }

          if ('idBannieres' in filter) {
            initialValue=filter.where
          }
          
          if (filter.accountFilter == true) {
            let aux = filter.filter.where.account
            let account = { account: aux }
            if (account) {
              initialValue = Object.assign(initialValue.valueOf(), account)
            }
          }
          if (filter.markFilter == true) {
            let aux = filter.filter.where.mark
            let mark = { mark: aux }
            if (mark) {
              initialValue = Object.assign(initialValue.valueOf(), mark)
            }
          }
          if (filter.modelFilter == true) {
            let aux = filter.filter.where.model
            let model = { model: aux }
            if (model) {
              initialValue = Object.assign(initialValue.valueOf(), model)
            }
          }
          if (filter.identifiantFilter == true) {
            let aux = filter.filter.where.identifier
            let identifier = { identifier: aux }
            if (identifier) {
              initialValue = Object.assign(initialValue.valueOf(), identifier)
            }
          }
          if (filter.openFamFilter == true) {
            let aux = filter.filter.where.famille
            let famille = { famille: aux }
            if (famille) {
              initialValue = Object.assign(initialValue.valueOf(), famille)
            }
          }

          if (filter.descritionArray && filter.descritionArray.length > 0) {
            let aux = filter.filter.where.description
            let keydescription = { description: aux }
            if (keydescription) {
              initialValue = Object.assign(initialValue.valueOf(), keydescription)
            }
          }
          if (filter.openUserFilter == true) {
            let aux = filter.filter.where.email
            let keyUser = { email: aux }
            if (keyUser) {
              initialValue = Object.assign(initialValue.valueOf(), keyUser)
            }
          }

          if (filter.cmdUserFilter == true) {
            if (filter.createdBy) {
              initialValue = Object.assign(initialValue.valueOf(), { createdBy: filter.createdBy, active: true })
            } else {
              initialValue = Object.assign(initialValue.valueOf(), { active: true })
            }
          }

          if (filter.nameUser == true) {
            let aux = filter.filter.where.name
            let keyUserName = { name: aux }
            if (keyUserName) {
              initialValue = Object.assign(initialValue.valueOf(), keyUserName)
            }
          }
          if (filter.openCusVilleFilter == true) {
            let aux = filter.filter.where.ville
            let keyVilleName = { ville: aux }
            if (keyVilleName) {
              initialValue = Object.assign(initialValue.valueOf(), keyVilleName)
            }
          }

        }

      }

      state.filters.forEach(filter => {
        if (filter.property) {
          // && filter.value a verifié
          initialValue[filter.property] = filter.value
        }
        else {
          filter.where = initialValue
        }
      });

    }
    filter.where = initialValue
    return filter
  }


  trTypeSearch(state: ClrDatagridStateInterface) {
    this.keyTransaction = { transactionTypeId: { type: "in", value: [1, 2, 3] } }
    if (state.filters != null) {
      if ('filters' in state) {
        for (let filter of state.filters) {
          if (filter.filterParamName == 'transactionTypeId') {
            this.verif = true
            this.values = filter.selectedItems.filter(item => item.checked === true).map(item => item.id);
            if (this.values.length == 1) {
              this.keyTransaction = { transactionTypeId: { type: "equal", value: this.values[0] } }
            }
            if (this.values.length >= 2) {
              this.keyTransaction = { transactionTypeId: { type: "in", value: this.values } }
            }
          }
          if (!this.verif) {
            this.keyTransaction = undefined
          }
        }
      }
    }
    this.whereFindOperator.key = this.keyTransaction
    return this.whereFindOperator
  }


  dateSearch(state: ClrDatagridStateInterface) {
    let dateFilterValue = {}
    let property:any
    if (state.filters != null) {
      if ('filters' in state) {
        for (let filter of state.filters) {
          if ('dateData' in filter) {
            const filterDateArray = []
            if (filter.dateData.startDate) {
              this.startDate = formatDate(filter.dateData.startDate)
              filterDateArray.push(this.startDate)
            }
            if (filter.dateData.endDate) {
              this.endtDate = formatDate(filter.dateData.endDate)
              filterDateArray.push(this.endtDate)
            }
            if(filter.property){
               property = filter.property;
               dateFilterValue = {}
            }else{
              property="createdAt"
            }
            
            if (filter.startDate && !filter.endDate) {
              dateFilterValue[property] = { type: "moreThanOrEqual", value: filterDateArray[0] }
            }
            if (filter.endtDate && !filter.startDate) {
              dateFilterValue[property] = { type: "lessThanOrEqual", value: filterDateArray[0] }
            }
            if (filter.endDate != undefined && filter.endDate != undefined) {
              dateFilterValue[property] = { type: "between", value: filterDateArray }
            }

            if (filterDateArray.length == 1 && filter.startDate && !filter.endDate) {
              dateFilterValue[property] = { type: "moreThanOrEqual", value: filterDateArray[0] }
            }

            if (filterDateArray.length == 1 && filter.dateData.endDate && !filter.dateData.startDate) {
              dateFilterValue[property] = { type: "lessThanOrEqual", value: filterDateArray[0] }
            }
          }
          if (!filter.dateData) {
            if (filter.filterParamName != 'active') {
              dateFilterValue = undefined
            }
          }
        }
      }
    }
    if (!state.filters || dateFilterValue == undefined) {
      this.whereDateOperator.key = undefined
    }
    else if (dateFilterValue || state.filters) {
      this.whereDateOperator.key = dateFilterValue
    }
    return this.whereDateOperator.key

  }
}
