import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogsRoutingModule } from './blogs-routing.module';
import { BlogsComponent } from './blogs/blogs.component';
import { ClarityModule } from '@clr/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BlogAddComponent } from './blogs/blog-add/blog-add.component';
import { BlogEditComponent } from './blogs/blog-edit/blog-edit.component';
import { BlogDeleteComponent } from './blogs/blog-delete/blog-delete.component';
import { BlogDetailComponent } from './blogs/blog-detail/blog-detail.component';


@NgModule({
  declarations: [
    BlogsComponent,
    BlogAddComponent,
    BlogEditComponent,
    BlogDeleteComponent,
    BlogDetailComponent
  ],
  imports: [
    CommonModule,
    BlogsRoutingModule,
    ClarityModule,
    FormsModule,
    TranslateModule,
  ]
})
export class BlogsModule { }
