import { BlogEditComponent } from './blogs/blog-edit/blog-edit.component';
import { BlogAddComponent } from './blogs/blog-add/blog-add.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessGuard } from '../auth/access.guard';
import { AuthGuard } from '../auth/auth.guard';
import { BlogsComponent } from './blogs/blogs.component';

const routes: Routes = [
  { path: '', component: BlogsComponent, data: { abonnements: ["trial", "facturation_only", "facturation_caisse", "premuim", "livraison"] }, canActivate: [AuthGuard, AccessGuard] },
  { path: 'blogs/add', component: BlogAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_caisse", "premuim", "livraison"] }, canActivate: [AuthGuard, AccessGuard] },
  { path: 'blogs/edit/:id', component: BlogEditComponent, data: { abonnements: ["trial", "facturation_only", "facturation_caisse", "premuim", "livraison"] }, canActivate: [AuthGuard, AccessGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogsRoutingModule { }
