import { Product } from "../catalog/product/product";
import { Customer, Providers } from "../parts/parts";
import { Delivery } from "../transport/delivery/delivery";
import { OperationType } from "../setting/type/type";
import { Points } from "../sales-point/points";
import { voucher } from "../sales/exit-voucher/voucher";
import { Entreprise } from "../setting/setting";
import { User } from "src/app/user/user";
export class Operation {
  constructor(
    public id?: number,
    public operationDetails: OperationDetails[] = [],
    public forDeliver?: boolean,
    public storeId?: number,
    public buyingId?: Buying,
    public planningId?: number & Delivery,
    public orderId?: number & Order,
    public voucherId?: number & voucher,
    public deliveryPrice?: number,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public isInvoiced?: boolean,
    public isCompleted?: boolean,
    public adressId?: number | Address,
    public ordreNumber?: number,
    public operationTypeId?: number,
    public entrepriseId?: number,
    public real_id?: number,
    public isDelivered?: boolean,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean,
    public expiredProduct?: boolean
  ) { }
}
export class OperationDetails {
  constructor(
    public id?: number,
    public quantity?: number,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public productId?: Product,
    public operationId?: number & Operation,
    public operationTypeId?: number & OperationType,
    public operationType?: number,
    public entrepriseId?: number,
    public real_id?: number,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean,
    public expiredProduct?: boolean,
    public buyingId ?: number
  ) { }
}
export class Buying {
  constructor(
    public id?: number,
    public totalPrice?: number,
    public providerId?: number & Providers,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,

  ) { }
}
export class Order {
  constructor(
    public id?: number,
    public amount?: string,
    public type?: string,
    public name?: string,
    public customerId?: Customer & number,
    public UserId?: User & number,
    public quantity?: string,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public totalPrice?: number,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean
  ) { }
}
export class Store {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public tel?: number,
    public adressId?: number | Address,
    public pointId?: number | Points,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public entrepriseId?: number,
    public real_id?: number,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean

  ) { }
}
export class Address {
  constructor(
    public id?: number,
    public city?: string,
    public state?: string,
    public street?: string,
    public number?: number,
    public geoCode?: number,
    public updatedAt?: Date,
    public createdAt?: Date,
    public active?: boolean,
    public entrepriseId?: number
  ) { }
}

export class Inventory {
  constructor(
    public id?: number,
    public real_id?: number,
    public active?: boolean,
    // public inventoryNumber?: string,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean,
    public createdAt?: Date,
    public createdBy?: number,
    public updatedAt?: Date,
    public updatedBy?: number,
    public entrepriseId?: number,
    public inventoryDetails?: InventoryDetail[],
    public pointId?: number & Points,
  ) { }
}

export class InventoryDetail {
  constructor(
    public id?: number,
    public real_id?: number,
    public productId?: number & Product,
    public inventoryId?: number & Inventory,
    public quantity_disp?: number,
    public ecart?: number,
    public quantity_entered?: number,
    public active?: boolean,
    public createdAt?: Date,
    public createdBy?: number,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean,
    public updatedAt?: Date,
    public updatedBy?: number,
    public entrepriseId?: number,
    public selected?: boolean
  ) { }
}

export class ExpiredProduct {
  constructor(
    public id?: number,
    public quantity?: number,
    public productId?: Product,
    public price?:number
  ) { }
}
