import { CarteFidelite } from "../carte-fidelite/models/CarteFidelite";
import { Transaction } from "../finance/finance";
import { Quotation } from "../sales/quotation/quotation";
import { Order } from "../stock/stock";
import { Contact } from "../user/user";

export class Customer {
  constructor(
    public id?: number,
    public name?: string,
    public mf?: string,
    public description?: string,
    public customerType?: string,
    public picture?: string,
    public createdBy?: number,
    public createdAt?: string,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public contacts?: Contact[],
    public adress?: Address[],
    public ville?: string,
    public entrepriseId?: number,
    public real_id?: number,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean,
    public carteFidelites?: CarteFidelite[]
  ) { }
}

export class Providers {
  constructor(
    public id?: number,
    public name?: string,
    public tel?: number,
    public description?: string,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public contacts?: Contact[],
    public adress?: Address[],
    public active?: boolean,
    public entrepriseId?: number,
    public real_id?: number,
    public mf?: string,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean
  ) { }
}
export class Companys {
  constructor(
    public id?: number,
    public name?: string,
    public tel?: number,
    public description?: string,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public contacts?: Contact[],
    public adress?: Address[],
    public active?: boolean,
    public entrepriseId?: number,
    public real_id?: number,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean
  ) { }
}


export class PostBank {
  constructor(
    public name?: string,
    public account?: string,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public entrepriseId?: number,
    public real_id?: number,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean

  ) { }
}

export class Address {
  constructor(
    public id?: number,
    public city?: string,
    public state?: string,
    public street?: string,
    public number?: number,
    public geoCode?: number,
    public updatedAt?: Date,
    public createdAt?: Date,
    public active?: boolean,
    public providerId?: number,
    public userId?: number,
    public customerId?: number,
    public entrepriseId?: number

  ) { }
}

export class HistoriqueObject {
  constructor(
    public customerId?: number,
    public all?: boolean,
    public quotations?: boolean,
    public orders?: boolean,
    public delivery?: boolean,
    public transactions?: boolean,
    public Factures?: boolean,
    public allEtat?: boolean,
    public valid?: boolean,
    public inValid?: boolean,
    public dateDebut?: string,
    public dateFin?: string,
    public isCompleted?: boolean,
    public take?: number

  ) { }
}

export class HistoriqueReception {
  constructor(
    public transactions?: Transaction[],
    public orders?: Order[],
    public quotations?: Quotation[],
    public delivery?: any[],
  ) { }
}

export class Statestiques {
  constructor(
    public solde?: number,
    public chiffAffaire?: number,
    public nbrCmd?: number,
    public nbrCmdNonPaye?: number,
    public nbrCmdPaye?: number,
    public totalDelivery?: number,
    public nonLivre?: number,
    public livre?: number

  ) { }
}

export class OrderCard {
  constructor(
    public totaleCmd?: number,
    public totPaye?: number,
    public totNonPaye?: number,
  ) { }
}

export class Cashiers {
  constructor(
    public id?: number,
    public name?: string,
    public tel?: number,
    public description?: string,
    public createdAt?: string,
    public createdBy?: number,
    public contacts?: Contact[],
    public adress?: Address[],
    public active?: boolean,
  ) { }
}

export class CategoryCustomer {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public active?: boolean,
    public createdAt?: Date,
    public createdBy?: number,
  ) { }
}