import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import jwtDecode from 'jwt-decode';
import { Crates } from 'src/app/crates/crates';
import { CratesService } from 'src/app/crates/crates.service';
import { FilterDto } from 'src/app/filter.dto';
import { Points } from 'src/app/sales-point/points';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { SettingService } from 'src/app/setting/setting.service';
import { Entreprise } from '../../entreprise';
import { Contact, PictureObject, PostUser, User } from '../../user';
import { getValueFromToken, tokenGetter, UserService } from '../../user.service';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { NgForm } from '@angular/forms';
import { Truck } from 'src/app/transport/truck/truck';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent {

  pictureArray: PictureObject[] = [];
  model: User = new User();
  fileSize: number;
  alert: {}
  url: string
  formatInvalid: boolean;
  tailleInvalid: boolean
  noImage: boolean;
  userId: number;
  stopLoading: boolean;
  userObject: User = new User();
  contact: Contact = new Contact()
  success: boolean;
  imagePicked: boolean;
  canResetPwd: boolean
  isMatchingPwd: boolean = true
  erreurMsg: string
  successMsg: string
  passwordValid: boolean = true
  validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
  current_user: number
  userArray: User[] = []
  entrepriseArray: Entreprise[] = []
  existing_mail: boolean = false
  showAddCaisse: boolean = false;
  crates: Crates[] = []
  id_entreprise: number
  filter: FilterDto
  loading: boolean = false
  saisie: string
  selectedCrates: Crates
  type_abn: string = ''
  points: Points[] = []
  usedMail: boolean;
  selectedPoint: Points;
  selectedTruck: Truck
  real_id: number
  showAlert: boolean;
  addNew: boolean;
  noCnx: boolean
  showAddPoint: boolean = false
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private cratesService: CratesService, private quotationService: QuotationService, private route: ActivatedRoute, private settingService: SettingService, private userService: UserService, private salespointService: SalesPointService, private router: Router) {
  }
  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.current_user = parseInt(getValueFromToken("id"))
    this.type_abn = getValueFromToken("type_abn")
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = +params.get('id')
    })
    this.getUser(this.userId);
    this.getUsers({});
    this.getEntrepriseAccounts({});
  }
  getUsers(filter: FilterDto) {
    this.quotationService.getUser(filter).subscribe(
      data => {
        this.userArray = data[0]
      }
    )
  }
  CloseAction() {
    this.showAddCaisse = false;
  }
  getCaisses() {
    this.CloseAction()
    this.getCrates({ where: { "entrepriseId": this.id_entreprise } })
  }
  getCrates(filter) {
    this.cratesService.getCrates(filter).subscribe(
      data => {
        this.crates = data[0];
      }
    )
  }
  itemChange(item) {
    this.selectedCrates = item
  }
  onSearchCrates(args) {
    this.filter = new FilterDto()
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.take = 10;
      this.filter.where = { name: { type: "like", value: this.saisie }, "entrepriseId": this.id_entreprise, active: true }
    }
    else {
      this.filter.take = 15;
      this.filter.where = { active: true, "entrepriseId": this.id_entreprise }
    }
    this.filter.order = { "createdAt": 'DESC' };
    this.filter.select = ["id", "name", "createdAt"];
    //this.filter.where = { "entrepriseId": this.id_entreprise }
    this.getCrates(this.filter)
  }
  getEntrepriseAccounts(filter: FilterDto) {
    this.quotationService.getEntreprise(filter).subscribe(
      data => {
        this.entrepriseArray = data[0]
      }
    )
  }
  getPoints(filter: FilterDto) {
    this.salespointService.getPoints(filter).subscribe(
      data => {
        this.points = data[0]
      }
    )
  }
  pointChange(item) {
    this.selectedPoint = item
  }
  AddPointPopUp() {
    this.showAddPoint = true
  }
  submit(userForm: PostUser, form: NgForm) {
    this.validateBtnState = ClrLoadingState.LOADING;
    const existsInBd = this.userArray.filter(element => element.email === userForm.email)
    const index_entrep = this.entrepriseArray.filter(x => x.email == userForm.email)
    if (existsInBd.length > 0 || index_entrep.length > 0) {
      this.usedMail = true
      this.erreurMsg = 'Cette adresse mail a été utilisée !'
      this.validateBtnState = ClrLoadingState.DEFAULT;
    } else {
      userForm.entrepriseId = this.id_entreprise
      userForm.createdBy = this.current_user
      this.contact.firstName = userForm.name
      this.contact.email = userForm.email
      userForm.contactId = this.contact
      userForm.picture = this.url
      if (userForm.role == 'writer') {
        userForm.cratesId = this.selectedCrates
      } else if (userForm.role == "responsable" || userForm.role == "carrier") {
        userForm.pointId = this.selectedPoint
      }
      if (userForm.role == "carrier") {
        userForm.truckId = this.selectedTruck
      }
      // if (this.idAbonnement != 0) {
      //   userForm["abonnementId"] = this.idAbonnement
      // }
      userForm.real_id = this.real_id
      this.settingService.addUser(userForm)
        .subscribe(
          data => {
            this.success = true
            this.showAlert = true
            this.addNew = true
            this.validateBtnState = ClrLoadingState.SUCCESS;
            setTimeout(() => {
              this.success = false
              this.router.navigate(["/user/users"])
            }, 3000)
          },
          err => {
            console.error('Observer got an error: ' + err)
            this.noCnx = true
            this.erreurMsg = 'Erreur de téléversement d' + 'image , vérifier votre connexion !'
            this.validateBtnState = ClrLoadingState.DEFAULT;
            this.success = false
          }
        )
    }
  }
  submitAction() {
    let index_user = this.userArray.filter(x => x.email == this.userObject.email)
    let index_entreprise = this.entrepriseArray.filter(x => x.email == this.userObject.email)
    if (index_user.length > 1 || index_entreprise.length > 0) {
      this.erreurMsg = "Adresse mail est deja utilisé"
      this.existing_mail = true
    } else {
      this.contact.firstName = this.userObject.name
      this.contact.email = this.userObject.email
      this.userObject.contactId = this.contact
      this.userObject.picture = this.url
      this.userObject.updatedBy = this.current_user
      if (this.userObject.role == "responsable" || this.userObject.role == "carrier"){
        this.userObject.pointId = this.selectedPoint
      } else if (this.userObject.role == 'writer') {
        this.userObject.cratesId = this.selectedCrates
      }
      this.userObject.cratesId = this.selectedCrates
      this.settingService.editUser(this.userObject.id, this.userObject).subscribe(
        data => {
          this.success = true
          this.successMsg = 'Utilisateur a été modifier avec succès !'
          this.getUser(this.userId)
        },
        err => {
          console.error('Observer got an error: ' + err)
          this.success = false
          this.erreurMsg = 'Cette adresse mail a été utilisée !'
        },
      )
    }
  }

  changePwd() {
    let user: PostUser = new PostUser();
    user.oldPassword = this.userObject.oldPassword
    this.userService.VerifResetPaswword(this.userId, user).subscribe(
      data => {
        this.passwordValid = data
        if (data) {
          this.isPasswordMatching();
          if (this.isMatchingPwd) {
            this.userObject.updatedBy = this.current_user
            this.settingService.editUser(this.userObject.id, this.userObject).subscribe(
              data => {
                this.success = true
                this.successMsg = 'Votre mot de passe est changé avec succès'
              },
              err => {
                console.error('Observer got an error: ' + err)
                this.success = false
              },
            )
          } else {
            this.erreurMsg = 'les mots de passe ne sont pas identiques  '
          }

        } else {
          this.erreurMsg = 'password invalid  '
        }
      }
    )
  }


  getUser(id: number) {
    let filter = new FilterDto<User>()
    filter.where = { "id": id }
    filter.relations = ["cratesId", "contactId"]
    this.settingService.getUserInfo(filter).subscribe(
      data => {
        this.userObject = data[0][0]
        this.contact.firstName = this.userObject.name
        this.contact.email = this.userObject.email
        this.contact.tel = this.userObject.contactId?.tel
        this.contact.fax = this.userObject.contactId?.fax
        this.contact.id = this.userObject.contactId?.id
        this.stopLoading = true
      },
      err => {
        console.error('Observer got an error: ' + err)
      },
    )
  }
  AddCaissePopUp() {
    this.showAddCaisse = true
  }

  isPasswordMatching() {
    if (this.canResetPwd && this.userObject.password != undefined && this.userObject.repassword != undefined
      && this.userObject.password == this.userObject.repassword && this.userObject.password.length == this.userObject.repassword.length) {
      this.isMatchingPwd = true
    } else {
      this.isMatchingPwd = false
      this.erreurMsg = 'Mots de passe ne sont pas identiques'
    }
  }

  annulerReset() {
    this.userObject.oldPassword = undefined
    this.canResetPwd = false
  }

  picked(event) {
    this.imagePicked = true
    this.tailleInvalid = false
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      if (this.fileSize > 0.5) {
        this.tailleInvalid = true;
        return
      }
      this.handleInputChange(file);
    }
    else {
      this.noImage = true
    }
  }

  handleInputChange(files) {
    this.formatInvalid = false
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.formatInvalid = true
      this.erreurMsg = 'Format de fichier invalide !'
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e) {
    const picture: PictureObject = new PictureObject()
    picture.base64Data = e.target.result;
    picture.url = e.target.result;
    this.pictureArray[0] = picture
    this.url = picture.url
    this.userObject.picture = this.pictureArray[0].base64Data
  }
  openResetPwd() {
    this.canResetPwd = true
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
