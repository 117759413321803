import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CategoryClientService } from '../category-client.service';
import { categoryClient } from '../categoryClient';

@Component({
  selector: 'app-category-client-add',
  templateUrl: './category-client-add.component.html',
  styleUrls: ['./category-client-add.component.css']
})
export class CategoryClientAddComponent implements OnInit {
  showAlert: boolean;
  creerNouveau: boolean = false;

  constructor(private categoryClientService: CategoryClientService) { }

  ngOnInit(): void {
  }

  submit(category: categoryClient, form: NgForm) {
    this.categoryClientService.addCategoryClient(category)
      .subscribe(
        data => {
          this.showAlert = true;
          if (this.creerNouveau == true) {
            this.resetForm(form);
          } else {
            this.creerNouveau = true;
          }
        })
  }
  resetForm(form: NgForm) {
    this.creerNouveau = false;
    form.resetForm();
    this.showAlert = false
  }
}
