import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import jwt_decode from 'jwt-decode';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterDto } from 'src/app/filter.dto';
import { Points } from 'src/app/sales-point/points';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { Configuration } from 'src/app/setting/setting';
import { SettingService } from 'src/app/setting/setting.service';
import { ConfigService } from 'src/app/shared/config/config.service';
import { User } from 'src/app/user/user';
import { CoreService } from '../core.service';
import { Subject } from 'rxjs';
import { Autorisation } from 'src/app/user/autorisation/autorisation';

@Component({
  selector: 'app-side-nav-right',
  templateUrl: './side-nav-right.component.html',
  styleUrls: ['./side-nav-right.component.css']
})
export class SideNavRightComponent implements OnInit {
  abn: boolean;
  triale: boolean;
  userRole: string
  show: boolean = true;
  filter: FilterDto<Configuration> = new FilterDto<Configuration>()
  nameComplet: string;
  nameCourt: string;
  pictureConfig: string;
  silver: boolean = false;
  showCmd: boolean = true;
  showLivraison: boolean;
  showabnLivraison: boolean;
  showabnCompany: boolean;
  currentLang: string;
  y: string;
  id_entreprise: number
  showAlert: boolean = false;
  // isAuthorized: boolean = false
  // isAuthorizedLivraison: boolean = false
  // isAuthorizedSalesPoint: boolean = false
  facturation_only: boolean = false
  facturation_caisse: boolean = false
  premuim: boolean = false
  livraison: boolean
  reste_triale: boolean = false
  user_id: number
  salespoints: Points[] = []
  carriersListe: User[] = []
  salespointCarriers: Points[] = []
  isAdmin: boolean = false
  // sidebarButton: boolean = false
  articleButton:boolean = false
  serviceButton:boolean = false
  ptsVentesButton:boolean = false
  caisseButton:boolean = false
  documentButton:boolean = false
  suiviLivraisonButton:boolean = false
  livraisonButton:boolean = false
  stockButton:boolean = false
  transactionButton:boolean = false
  comptabiliteButton:boolean = false
  relationButton:boolean = false
  parametresButton:boolean = false
  heighArticlesMenu:number = 0
  heighServicesMenu:number = 0
  heighPtsVentesMenu:number = 0
  heighCaisseMenu:number = 0
  heighDocumentsMenu:number = 0
  heighSuiviLivraisonMenu:number = 0
  heighLivraisonMenu:number = 0
  heighStockMenu:number = 0
  heighTransactionMenu:number = 0
  heighComptabiliteMenu:number = 0
  heighRelationsMenu:number = 0
  heighParametresMenu:number = 0
  openSideBar:boolean = false
  autorisationUser : Autorisation = new Autorisation()

  @Input('openSidebarArabic') openSidebarArabic:Subject<any>;
  constructor(public translate: TranslateService, private configService: ConfigService,
    private router: Router, private serviceCore: CoreService,
    private settingService: SettingService, private salesPointService: SalesPointService
  ) { 
    this.reste_triale = jwt_decode(tokenGetter())["triale"]
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.y = localStorage.getItem('y');
    this.translate.use(this.currentLang);
  }
  
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.user_id = parseInt(getValueFromToken("id"))
    this.triale = JSON.parse(getValueFromToken('triale'))
    this.userRole = getValueFromToken("role");
    this.abn = JSON.parse(getValueFromToken('abonee'))
    this.getAbonnementInfo()
    this.isAdmin = this.userRole == "admin"
    if (this.isAdmin) {
      this.getSalesPoints()
    }
    if (!this.abn && !this.triale) {
      this.router.navigate(['/setting/company']);
      this.show = true
      // false
    }
    if (this.triale) {
      this.showabnLivraison = true
      this.showabnCompany = true
    }
    if (this.abn) {
      let element = getValueFromToken('showLivraison')
      if (element) {
        var el = JSON.parse(element)
      }
      if (el != null) {
        this.showLivraison = el;
      }
      if (this.showLivraison) {
        this.showabnLivraison = true
        this.showabnCompany = false
      } else {
        this.showabnLivraison = false
        this.showabnCompany = true
      }
    }
    this.openSidebarArabic.subscribe(e => {
      this.openSideBar = true;
    });

    // if (this.silver) {
    //   this.showCmd = false
    // }
    this.getConfiguration();
  }
  ngOnDestroy() {
    this.openSidebarArabic.unsubscribe();
  }
  getSalesPoints() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise }
    filter.relations = ["userId"];
    this.salesPointService.getPoints(filter).subscribe(
      data => {
        let pt_sale: boolean;
        data[0].forEach(el => {
          pt_sale = true
          if (el.userId?.length > 0) {
            el.userId.forEach(element => {
              if (element.role == "carrier") {
                pt_sale = false;
              }
            })
            if (!pt_sale) {
              this.salespointCarriers.push(el);
            } else {
              this.salespoints.push(el);
            }
          } else {
            this.salespoints.push(el)
          }
        })
      }
    )
  }

  // getAbonnementInfo() {
  //   if (this.userRole == "admin") {
  //     let filter = new FilterDto()
  //     filter.where = { id: this.id_entreprise }
  //     filter.relations = ["abonnementId"]
  //     this.settingService.getAllEntreprise(filter).subscribe(
  //       data => {
  //         let abn = data[0][0].abonnementId
  //         if (data[0][0]?.abonnementId) {
  //           if (abn.livraison) {
  //             this.livraison = true
  //           } else if (abn.premuim) {
  //             this.premuim = true
  //           } else if (abn.facturation_caisse) {
  //             this.facturation_caisse = true
  //           } else if (abn.facturation_only) {
  //             this.facturation_only = true
  //           }
  //         } else {
  //           if (this.reste_triale == true) {
  //             this.premuim = true
  //           }
  //         }
  //       }
  //     )
  //   } else if (this.userRole == "responsable" || this.userRole == "writer" || this.userRole == "carrier") {
  //     let filter = new FilterDto()
  //     filter.where = { "entrepriseId": this.id_entreprise, "id": this.user_id }
  //     filter.relations = ["entrepriseId", "entrepriseId.abonnementId"]
  //     this.settingService.getUserInfo(filter).subscribe(
  //       data => {
  //         let abn = data[0][0].entrepriseId.abonnementId
  //         if (data[0][0]?.entrepriseId?.abonnementId) {
  //           if (abn.livraison) {
  //             this.livraison = true
  //           } else if (abn.premuim) {
  //             this.premuim = true
  //           } else if (abn.facturation_caisse) {
  //             this.facturation_caisse = true
  //           } else if (abn.facturation_only) {
  //             this.facturation_only = true
  //           }
  //         } else {
  //           if (this.reste_triale == true) {
  //             this.premuim = true
  //           }
  //         }
  //       }
  //     )
  //   }
  // }
  getAbonnementInfo() {
    if (this.userRole == "admin") {
      let filter = new FilterDto()
      filter.where = { id: this.id_entreprise }
      filter.relations = ["abonnementId"]
      this.settingService.getAllEntreprise(filter).subscribe(
        data => {
          let abn = data[0][0].abonnementId
          if (data[0][0]?.abonnementId) {
            if (abn.livraison) {
              this.livraison = true
            } else if (abn.premuim) {
              this.premuim = true
            } else if (abn.facturation_caisse) {
              this.facturation_caisse = true
            } else if (abn.facturation_only) {
              this.facturation_only = true
            }
          } else {
            if (this.reste_triale == true) {
              this.premuim = true
            }
          }
        }
      )
    } else if (this.userRole == "responsable" || this.userRole == "writer" || this.userRole == "carrier") {
      let filter = new FilterDto()
      filter.where = { "entrepriseId": this.id_entreprise, "id": this.user_id }
      filter.relations = ["entrepriseId","autorisationId", "entrepriseId.abonnementId"]
      this.settingService.getUserInfo(filter).subscribe(
        data => {
          this.autorisationUser = data[0][0].autorisationId
          let abn = data[0][0].entrepriseId.abonnementId
          if (data[0][0]?.entrepriseId?.abonnementId) {
            if (abn.livraison) {
              this.livraison = true
            } else if (abn.premuim) {
              this.premuim = true
            } else if (abn.facturation_caisse) {
              this.facturation_caisse = true
            } else if (abn.facturation_only) {
              this.facturation_only = true
            }
          } else {
            if (this.reste_triale == true) {
              this.premuim = true
            }
          }
        }
      )
    }
  }
   

  getConfiguration() {
    this.filter.select = ["nameComplet", "nameCourt", "picture"]
    this.serviceCore.getconfiguration(this.filter).subscribe(
      data => {
        this.nameComplet = data[0][0]?.nameComplet;
        this.nameCourt = data[0][0]?.nameCourt;
        this.pictureConfig = data[0][0]?.picture;
      })
  }
  ExportData() {
    this.serviceCore.exportData(this.id_entreprise).subscribe(
    )
  }
  impoexpo() {
    this.showAlert = true
  }
  closeAction() {
    this.showAlert = false
  }
  SidebarHide() {
    this.openSideBar = false
  }
  ArticleButton(event) {
    if (event) {
      this.articleButton = false
    } else {
      this.articleButton = !this.articleButton
    }
    let element=document.getElementById("articlesMenu")
    if (this.articleButton) {
      this.ServiceButton(true);
      this.PtsVentesButton(true);
      this.CaisseButton(true);
      this.DocumentButton(true);
      this.SuiviLivraisonButton(true);
      this.LivraisonButton(true);
      this.StockButton(true);
      this.TransactionButton(true);
      this.ComptabiliteButton(true);
      this.RelationButton(true);
      this.ParametresButton(true);
      this.heighArticlesMenu = element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighArticlesMenu=0
    }
    element.style.maxHeight = `${this.heighArticlesMenu}px`;
  }
  ServiceButton(event) {
    if (event) {
      this.serviceButton = false
    } else {
      this.serviceButton = !this.serviceButton
    }
    let element=document.getElementById("servicesMenu")
    if (this.serviceButton) {
      this.ArticleButton(true)
      this.PtsVentesButton(true);
      this.CaisseButton(true);
      this.DocumentButton(true);
      this.SuiviLivraisonButton(true);
      this.LivraisonButton(true);
      this.StockButton(true);
      this.TransactionButton(true);
      this.ComptabiliteButton(true);
      this.RelationButton(true);
      this.ParametresButton(true);
      this.heighServicesMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighServicesMenu=0
    }
    element.style.maxHeight = `${this.heighServicesMenu}px`;
  }
  PtsVentesButton(event) {
    if (event) {
      this.ptsVentesButton = false
    } else {
      this.ptsVentesButton = !this.ptsVentesButton
    }
    let element=document.getElementById("ptsVentesMenu")
    if (this.ptsVentesButton) {
      this.ArticleButton(true)
      this.ServiceButton(true);
      this.CaisseButton(true);
      this.DocumentButton(true);
      this.SuiviLivraisonButton(true);
      this.LivraisonButton(true);
      this.StockButton(true);
      this.TransactionButton(true);
      this.ComptabiliteButton(true);
      this.RelationButton(true);
      this.ParametresButton(true);
      this.heighPtsVentesMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighPtsVentesMenu=0
    }
    element.style.maxHeight = `${this.heighPtsVentesMenu}px`;
  }
  CaisseButton(event) {
    if (event) {
      this.caisseButton = false
    } else {
      this.caisseButton = !this.caisseButton
    }
    let element=document.getElementById("caisseMenu")
    if (this.caisseButton) {
      this.ArticleButton(true)
      this.ServiceButton(true);
      this.PtsVentesButton(true);
      this.DocumentButton(true);
      this.SuiviLivraisonButton(true);
      this.LivraisonButton(true);
      this.StockButton(true);
      this.TransactionButton(true);
      this.ComptabiliteButton(true);
      this.RelationButton(true);
      this.ParametresButton(true);
      this.heighCaisseMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighCaisseMenu=0
    }
    element.style.maxHeight = `${this.heighCaisseMenu}px`;
  }
  DocumentButton(event) {
    if (event) {
      this.documentButton = false
    } else {
      this.documentButton = !this.documentButton
    }
    let element=document.getElementById("documentsMenu")
    if (this.documentButton) {
      this.ArticleButton(true)
      this.ServiceButton(true);
      this.PtsVentesButton(true);
      this.CaisseButton(true);
      this.SuiviLivraisonButton(true);
      this.LivraisonButton(true);
      this.StockButton(true);
      this.TransactionButton(true);
      this.ComptabiliteButton(true);
      this.RelationButton(true);
      this.ParametresButton(true);
      this.heighDocumentsMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighDocumentsMenu=0
    }
    element.style.maxHeight = `${this.heighDocumentsMenu}px`;
  }
  SuiviLivraisonButton(event) {
    if (event) {
      this.suiviLivraisonButton = false
    } else {
      this.suiviLivraisonButton = !this.suiviLivraisonButton
    }
    let element=document.getElementById("suiviLivraisonMenu")
    if (this.suiviLivraisonButton) {
      this.ArticleButton(true)
      this.ServiceButton(true);
      this.PtsVentesButton(true);
      this.CaisseButton(true);
      this.DocumentButton(true);
      this.LivraisonButton(true);
      this.StockButton(true);
      this.TransactionButton(true);
      this.ComptabiliteButton(true);
      this.RelationButton(true);
      this.ParametresButton(true);
      this.heighSuiviLivraisonMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighSuiviLivraisonMenu=0
    }
    element.style.maxHeight = `${this.heighSuiviLivraisonMenu}px`;
  }
  LivraisonButton(event) {
    if (event) {
      this.livraisonButton = false
    } else {
      this.livraisonButton = !this.livraisonButton
    }
    let element=document.getElementById("livraisonMenu")
    if (this.livraisonButton) {
      this.ArticleButton(true)
      this.ServiceButton(true);
      this.PtsVentesButton(true);
      this.CaisseButton(true);
      this.DocumentButton(true);
      this.SuiviLivraisonButton(true);
      this.StockButton(true);
      this.TransactionButton(true);
      this.ComptabiliteButton(true);
      this.RelationButton(true);
      this.ParametresButton(true);
      this.heighLivraisonMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighLivraisonMenu=0
    }
    element.style.maxHeight = `${this.heighLivraisonMenu}px`;
  }
  StockButton(event) {
    if (event) {
      this.stockButton = false
    } else {
      this.stockButton = !this.stockButton
    }
    let element=document.getElementById("stockMenu")
    if (this.stockButton) {
      this.ArticleButton(true)
      this.ServiceButton(true);
      this.PtsVentesButton(true);
      this.CaisseButton(true);
      this.DocumentButton(true);
      this.SuiviLivraisonButton(true);
      this.LivraisonButton(true);
      this.TransactionButton(true);
      this.ComptabiliteButton(true);
      this.RelationButton(true);
      this.ParametresButton(true);
      this.heighStockMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighStockMenu=0
    }
    element.style.maxHeight = `${this.heighStockMenu}px`;
  }
  TransactionButton(event) {
    if (event) {
      this.transactionButton = false
    } else {
      this.transactionButton = !this.transactionButton
    }
    let element=document.getElementById("transactionMenu")
    if (this.transactionButton) {
      this.ArticleButton(true)
      this.ServiceButton(true);
      this.PtsVentesButton(true);
      this.CaisseButton(true);
      this.DocumentButton(true);
      this.SuiviLivraisonButton(true);
      this.LivraisonButton(true);
      this.StockButton(true);
      this.ComptabiliteButton(true);
      this.RelationButton(true);
      this.ParametresButton(true);
      this.heighTransactionMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighTransactionMenu=0
    }
    element.style.maxHeight = `${this.heighTransactionMenu}px`;
  }
  ComptabiliteButton(event) {
    if (event) {
      this.comptabiliteButton = false
    } else {
      this.comptabiliteButton = !this.comptabiliteButton
    }
    let element=document.getElementById("comptabiliteMenu")
    if (this.comptabiliteButton) {
      this.ArticleButton(true)
      this.ServiceButton(true);
      this.PtsVentesButton(true);
      this.CaisseButton(true);
      this.DocumentButton(true);
      this.SuiviLivraisonButton(true);
      this.LivraisonButton(true);
      this.StockButton(true);
      this.TransactionButton(true);
      this.RelationButton(true);
      this.ParametresButton(true);
      this.heighComptabiliteMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighComptabiliteMenu=0
    }
    element.style.maxHeight = `${this.heighComptabiliteMenu}px`;
  }
  RelationButton(event) {
    if (event) {
      this.relationButton = false
    } else {
      this.relationButton = !this.relationButton
    }
    let element=document.getElementById("relationsMenu")
    if (this.relationButton) {
      this.ArticleButton(true)
      this.ServiceButton(true);
      this.PtsVentesButton(true);
      this.CaisseButton(true);
      this.DocumentButton(true);
      this.SuiviLivraisonButton(true);
      this.LivraisonButton(true);
      this.StockButton(true);
      this.TransactionButton(true);
      this.ComptabiliteButton(true);
      this.ParametresButton(true);
      this.heighRelationsMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighRelationsMenu=0
    }
    element.style.maxHeight = `${this.heighRelationsMenu}px`;
  }
  ParametresButton(event) {
    if (event) {
      this.parametresButton = false
    } else {
      this.parametresButton = !this.parametresButton
    }
    let element=document.getElementById("parametresMenu")
    if (this.parametresButton) {
      this.ArticleButton(true)
      this.ServiceButton(true);
      this.PtsVentesButton(true);
      this.CaisseButton(true);
      this.DocumentButton(true);
      this.SuiviLivraisonButton(true);
      this.LivraisonButton(true);
      this.StockButton(true);
      this.TransactionButton(true);
      this.ComptabiliteButton(true);
      this.RelationButton(true);
      this.heighParametresMenu=element.getElementsByTagName("LI").length * 36;
    }else {
      this.heighParametresMenu=0
    }
    element.style.maxHeight = `${this.heighParametresMenu}px`;
  }
}
