import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/sales/order/order.service';
import { PartsService } from '../../parts.service';
import { FilterDto, Order } from '../../../filter.dto';

@Component({
  selector: 'app-customer-delete',
  templateUrl: './customer-delete.component.html',
  styleUrls: ['./customer-delete.component.css']
})
export class CustomerDeleteComponent implements OnInit {

  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  @Input() customerId: number;
  deleteAlert: boolean;
  alertError: boolean;
  completedOrd = []
  delivredOrd = []
  filter: FilterDto<any> = new FilterDto<any>()
  real_id: number

  constructor(private partsService: PartsService, private router: Router, private orderService: OrderService) { }


  async ngOnInit() {
    this.partsService.getUser(this.customerId).subscribe(
      data => {
        this.real_id = data[0].real_id;
      }
    )
    await this.canDelete()
  }
  async canDelete() {
    this.filter.where = { customerId: this.customerId }
    this.filter.relations = ["operations"]
    await this.orderService.getOrders(this.filter).subscribe(
      data => {
        data[0].forEach(async element => {
          if (element.isCompleted) {
            await this.completedOrd.push(element)
          }
          element.operations.forEach(async element => {
            if (element.forDeliver && element.isCompleted) {
              await this.delivredOrd.push(element)
            }
          });
        });
      }
    )
  }
  deleteProvider() {
    if (!this.deleteAlert) {
      this.partsService.deleteUser(this.customerId).subscribe(
        data => {
          this.saved.emit(true)
          this.closed.emit(true);
          // this.router.navigate(['/parts/customer'])
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/parts/customer']);
          });
        },
        err => {
          console.error('Observer got an error: ' + err)
          this.alertError = true
        }
      )
    }
  }
  closeAction() {
    this.closed.emit(true);
  }

}
