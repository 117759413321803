import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { Points } from 'src/app/sales-point/points';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { SettingService } from 'src/app/setting/setting.service';
import { PriceType } from 'src/app/setting/type/type';
import { Store } from 'src/app/stock/stock';
import { StockService } from 'src/app/stock/stock.service';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Category } from '../../category/category';
import { CategoryService } from '../../category/category.service';
import { Detail } from '../../detail/detail';
import { DetailService } from '../../detail/detail.service';
import { Mark } from '../../mark/mark';
import { MarkService } from '../../mark/mark.service';
import { Tax } from '../../tax/tax';
import { TaxService } from '../../tax/tax.service';
import { Unity } from '../../unity/unity';
import { UnityService } from '../../unity/unity.service';
import { Detailproduct, Product, ProductPicture, Productprice } from '../product';
import { ProductService } from '../product.service';
import { Configuration } from 'src/app/setting/setting';
import { ConfigService } from 'src/app/shared/config/config.service';
import { ClrStepper } from '@clr/angular';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.css']
})
export class ProductAddComponent implements OnInit {
  showAlert: boolean;
  model: Product = new Product();
  modelProductprice: Productprice = new Productprice();
  modelDetailProduct: Detailproduct = new Detailproduct()

  filter: FilterDto = new FilterDto()
  categories: Category[];
  marks: Mark[];
  unities: Unity[];
  taxs: Tax[];
  priceTypes: PriceType[];
  details: Detail[];

  pictureArray: ProductPicture[] = [];
  allpicture: ProductPicture = new ProductPicture();
  pictureString: ProductPicture = new ProductPicture();
  filename: string;
  pictureIndex: number = -1;
  desableDefCheck: boolean;
  isPictureChecked: boolean;
  pictureDetails: boolean;
  untouched: boolean = true;
  alert: {}
  filesize: number;
  loading: boolean = false;
  saisie: string;
  editCateory: boolean = false;
  tailleInvalid: boolean

  selectedUnity: string
  selectedMark: string
  selectedTax: string
  selectedType: string
  selectedDetail: string

  initialStep: string = "step1"
  creerNouveau: boolean = false;
  showAddCategory: boolean;
  showAddMarque: boolean;
  showAddUnite: boolean;
  showAddTaxe: boolean
  id_entreprise: number
  real_id: number
  store_id: number
  userRole: string
  isAdmin: boolean = false
  configuration: Configuration = new Configuration();
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  @ViewChild('stepper', { static: false }) stepper: ClrStepper;


  constructor(private settingService: SettingService, private categoryService: CategoryService,
    private markService: MarkService, private unityService: UnityService,
    private taxService: TaxService, private detailService: DetailService, private stockService: StockService,
    private productService: ProductService, private salesPointService: SalesPointService,
    private configService: ConfigService) {
    this.model.productPrices = [];
    this.model.productDetails = [];
  }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.getStoreId()
    this.getConfiguration();

    console.log(" this.pictureArray == ", this.pictureArray)
  
  }
  ngAfterViewInit(){
    this.showtab()
  }
  showtab(){
    var elements = Array.from(document.getElementsByClassName('clr-col-md-10'))
    elements.forEach(el=>{ if (el && el instanceof HTMLElement && el.style) {
      (el as HTMLElement).style.maxWidth='65%';
      (el as HTMLElement).style.flex='0 0 65%';
    }})
  }
  getConfiguration() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise }
    this.configService.getConfiguration(filter).subscribe(
      data => {
        this.configuration = data[0][0]
        if (this.configuration?.id == null) {
          this.configuration = new Configuration();
          this.configuration.entrepriseId = this.id_entreprise
          this.configuration.timbre_fiscale = true
          this.configuration.price_calc = false
          this.configuration.real_id = 1
          this.configService.addConfiguration(this.configuration).subscribe(
            data => {
              this.configuration = data[0][0]
            }
          )
        }
      }
    )
  }

  getStoreId() {
    if (this.isAdmin) {
      let filter = new FilterDto()
      filter.where = { "entrepriseId": this.id_entreprise, name: "point de vente principale" }
      filter.relations = ["storeId"]
      this.salesPointService.getPoints(filter).subscribe(
        data => {
          if (data[1] == 0) {
            let storeModel = new Store()
            storeModel.real_id = 1
            storeModel.entrepriseId = this.id_entreprise
            storeModel.name = "Depot de la point de vente principale "
            this.stockService.addStore(storeModel).subscribe(
              data => {
                this.store_id = data.id
                let pointModel = new Points()
                pointModel.real_id = 1
                pointModel.storeId = data.id
                pointModel.name = "point de vente principale"
                pointModel.entrepriseId = this.id_entreprise
                this.salesPointService.addPoints(pointModel).subscribe(
                  data => {
                    this.filter = new FilterDto()
                    this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
                    this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
                    this.filter.relations = ["taxId"];
                    this.getProducts(this.store_id, this.filter);
                  }
                )
              }
            )
          } else {
            this.store_id = data[0][0].storeId.id
            this.filter = new FilterDto()
            this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
            this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
            this.filter.relations = ["taxId"];
            this.getProducts(this.store_id, this.filter);
          }
        }
      )
    } else {
      const id_user = parseInt(getValueFromToken("id"))
      let filter = new FilterDto()
      filter.where = { "id": id_user, "entrepriseId": this.id_entreprise }
      if (this.userRole == "writer") {
        filter.relations = ["cratesId","cratesId.pointid", "cratesId.pointid.storeId"]
        this.settingService.getUserInfo(filter).subscribe(
          data => {
            if (data[1] > 0) {
              this.store_id = data[0][0].cratesId.pointid.storeId.id
              this.filter = new FilterDto()
              this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
              this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
              this.filter.relations = ["taxId"];
              this.getProducts(this.store_id, this.filter);
            }
          }
        )
      } else if (this.userRole == "responsable") {
        filter.relations = ["pointId", "pointId.storeId"]
        this.settingService.getUserInfo(filter).subscribe(
          data => {
            if (data[1] > 0) {
              this.store_id = data[0][0].pointId.storeId.id
              this.filter = new FilterDto()
              this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
              this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
              this.getProducts(this.store_id, this.filter);
            }
          }
        )
      }
    }
  }
  getProducts(store_id: number, filter: FilterDto<Product>) {
    this.productService.getInfoProducts(store_id, filter).subscribe(
      data => {
        //this.products = data[0];
        this.real_id = data[1] + 1;
      },
      err => console.error('Observer got an error: ', err),
    );
  }
  AddTaxePopUp() {
    this.showAddTaxe = true;
  }
  AddCategoriePopUp() {
    this.showAddCategory = true;
  }
  AddUnityPopUp() {
    this.showAddUnite = true;
  }
  AddMarquePopUp() {
    this.showAddMarque = true;
  }
  AddUnity(event: any) {
    this.selectedUnity = event.name
    this.getUnities(this.filter);
  }
  AddMarque(event: any) {
    this.selectedMark = event.name
    this.getMarks({});
  }
  AddCategory(event: any) {
    this.getCategories({});
    this.model.categoryId = event
  }
  AddTaxe() {
    this.getTaxs(this.filter);
  }
  CloseAction() {
    this.showAddMarque = false;
    this.showAddCategory = false;
    this.showAddUnite = false;
    this.showAddTaxe = false;
  }
  onSearchChange(args: string, table: string) {
    this.filter = new FilterDto()
    this.loading = true
    this.filter.where = {}
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.take = 10;
      if (table == "tax") {
        this.filter.where = { percentage: { type: "equal", value: +args }, "entrepriseId": this.id_entreprise, active: true }
      } else {
        if (table == "detail") {
          this.filter.where = { type: { type: "like", value: this.saisie }, active: true }
        } else {
          this.filter.where = { name: { type: "like", value: this.saisie }, active: true }
        }
      }
    }
    else {
      this.filter.take = 15;
      this.filter.where = { active: true }
    }
    if (table == "category") {
      this.getCategories(this.filter)
    } else {
      if (table == "mark") {
        this.getMarks(this.filter)
      } else {
        if (table == "unity") {
          this.getUnities(this.filter)
        } else {
          if (table == "tax") {
            this.getTaxs(this.filter)
          } else {
            if (table == "priceType") {
              this.getPriceTypes(this.filter)

            } else {
              if (table == "detail") {
                this.getDetails(this.filter)
              }
            }
          }
        }
      }
    }
  }

  itemChanged(item: any, tableItem: string) {
    if (tableItem == "category") {
      this.model.categoryId = item
    } else {
      if (tableItem == "mark") {
        this.model.markId = item
      } else {
        if (tableItem == "unity") {
          this.model.unityId = item
        } else {
          if (tableItem == "tax") {
            this.model.taxId = item
          } else {
            if (tableItem == "priceType") {
              this.modelProductprice.priceTypeId = item
            } else {
              if (tableItem == "detail") {
                this.modelDetailProduct.detailId = item
              }
            }
          }
        }
      }
    }
  }

  getCategories(filter: FilterDto) {
    filter.relations = ["details"]
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.categoryService.getCategories(filter).subscribe(
      data => {
        this.categories = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getMarks(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.markService.getMarks(filter).subscribe(
      data => {
        this.marks = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getUnities(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.unityService.getUnities(filter).subscribe(
      data => {
        this.unities = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getTaxs(filter: FilterDto) {
    filter.where = { "active": true }
    this.taxService.getTaxs(filter).subscribe(
      data => {
        this.taxs = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getPriceTypes(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.settingService.getPricesTypes(filter).subscribe(
      data => {
        this.priceTypes = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getDetails(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    let idDetails = []
    this.model.categoryId?.details.forEach(element => {
      idDetails.push(element.id)
    })
    filter.where = Object.assign(filter.where, { id: { type: "in", value: idDetails } })
    filter.where = { "entrepriseId": this.id_entreprise }
    this.detailService.getDetails(filter).subscribe(
      data => {
        this.details = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }
  ajoutProductprice() {
    this.modelProductprice.entrepriseId = this.id_entreprise
    this.model.productPrices.push(this.modelProductprice)
    this.modelProductprice = new Productprice()
  }

  deleteProductPrice(index: number) {
    this.model.productPrices.splice(index, 1);
  }

  ajoutDetailProduct() {
    this.modelDetailProduct.entrepriseId = this.id_entreprise
    this.model.productDetails.push(this.modelDetailProduct)
    this.modelDetailProduct = new Detailproduct()
  }

  deleteProductDetails(index: number) {
    this.model.productDetails.splice(index, 1);
  }

  picked(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.filename = file.name;
      this.filesize = file.size;
      if (this.filesize > 100000) {
        this.tailleInvalid = true;
        return
      }
      this.tailleInvalid = false;
      this.handleInputChange(file);
    }
    else {
      alert("No file selected");
    }
  }

  handleInputChange(files) {
    var file = files;
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.alert = { type: 'danger', message: "format d'image invalide " };
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e) {
    let allpicture: ProductPicture = new ProductPicture()
    allpicture.url = await e.target.result;
    allpicture.defaults = await false
    await this.pictureArray.push(allpicture)
    const found = await this.pictureArray.filter(item => item.defaults === true);
    if (found.length == 0) {
      this.pictureArray[0].defaults = await true
    }
    this.desableDefCheck = false
  }

  async deletePicAction(item) {
    var pos = await this.pictureArray.indexOf(item);
    await this.pictureArray.splice(pos, 1);
    if (item.defaults == true) {
      this.pictureArray[0].defaults = await true
    }
  }

  pictureDetailAction(item: ProductPicture, index: number) {
    if (this.untouched) {
      this.pictureString = item
      this.pictureDetails = true
      this.pictureIndex = index
      if (this.pictureArray.length == 1) {
        this.desableDefCheck = true
      }
      if (this.pictureString.defaults == true) {
        this.isPictureChecked = true
      } else {
        this.isPictureChecked = false
      }
    }
  }

  submitPictureDetails() {
    this.pictureDetails = false
    if (this.pictureArray[this.pictureIndex].defaults == true && this.pictureIndex != 0) {
      this.pictureArray[0].defaults = false
      let item = this.pictureArray[this.pictureIndex]
      this.pictureArray.splice(this.pictureIndex, 1)
      this.pictureArray.unshift(item)

    }
  }

  submit() {
    this.model.entrepriseId = this.id_entreprise
    this.model.real_id = this.real_id
    this.model.byAdmin = this.isAdmin
    if (this.configuration.price_calc) {
      this.model.priceBuy = this.model.priceBuyProduct * (1 - (this.model.remise_product / 100))
      this.model.price = this.model.priceBuy * (1 + (this.model.percentage / 100))
    }
    this.productService.addProduct(this.model)
      .subscribe(
        product => {
          this.pictureArray.forEach(picture => { 
            picture.entrepriseId = this.id_entreprise
            picture.productId = product.id
            this.productService.addPicture(picture)
              .subscribe(
                picture => {
                  this.showAlert = true;
                })
          })
          this.showAlert = true;
          if (this.creerNouveau == true) {
            this.model = new Product();
            this.modelProductprice = new Productprice();
            this.modelDetailProduct = new Detailproduct()
            this.pictureArray = []
            this.initialStep = "step1"
            this.creerNouveau = false;
            this.selectedMark = null
            this.selectedUnity = null
            this.selectedTax = null
            this.selectedType = null
            this.selectedDetail = null
          } else {
            this.creerNouveau = true;
          }
        })
  }

  annuler() {
    this.model = new Product();
    this.modelProductprice = new Productprice();
    this.modelDetailProduct = new Detailproduct()
    this.pictureArray = []
    this.initialStep = "step1"
    this.creerNouveau = false
    this.selectedMark = null
    this.selectedUnity = null
    this.selectedTax = null
    this.selectedType = null
    this.selectedDetail = null
    this.showAlert = false
    this.stepper.form.reset();
  }
  clrInitialStep(initialStep: string) {
    this.initialStep = initialStep
  }

  editCategory() {
    this.showtab()
    if (this.model.productDetails.length > 0 || this.pictureArray.length > 0) {
      this.editCateory = true;
    } else {
      this.editCateory = false;
      this.model.categoryId = null;
    }
  }
  cancelEditCategory() {
    this.editCateory = false;
  }
  activeEditCategory() {
    this.model.categoryId = null;
    this.pictureArray = []
    this.model.productDetails = []
    this.editCateory = false
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }












  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      // Vous pouvez gérer le fichier ici, par exemple en l'affectant à votre modèle
      this.model.image = file;
      // Si vous avez besoin d'afficher un aperçu de l'image, vous pouvez utiliser FileReader ici
    }
  }

}
