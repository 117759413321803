import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserModule } from './user/user.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthGuard } from './auth/auth.guard';
import { DashboardModule } from './dashboard/dashboard.module';
import { CdsModule } from '@cds/angular';
import { ActivitesComponent } from './activites/activites.component';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgxPrintModule } from 'ngx-print';
import { InterceptorService } from './core/loader/interceptor.service';
import { RetenueSourceComponent } from './comptabilite/retenue-source/retenue-source.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommandeModule } from './commande/commande.module';
import { DropdownModule } from 'primeng/dropdown';
import { BlogsModule } from './blogs/blogs.module';

@NgModule({
  declarations: [
    AppComponent,
    ActivitesComponent,
    RetenueSourceComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    NgxPrintModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    UserModule,
    DashboardModule,
    DropdownModule,
    FormsModule,
    NgbModule,
    CdsModule,
    CommandeModule,
    BlogsModule,
    TranslateModule.forRoot({
      defaultLanguage: "fr",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
