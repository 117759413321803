<section>
    <div class="wrapper">
    <h3>Liste des vente soumis à la retenues à la source </h3>

    <div>
        <table class="table table-hover" style="margin-top: 40px;">

            <thead>
                <tr>
                    <th style="text-align: center">Id vente </th>
                    <th style="text-align: center">Date Creation</th>
                    <th style="text-align: center">Total de la vente</th>
                    <th style="text-align: center">Valeur de retenue 1%</th>
                    <th style="text-align: center">Valeur de retenue 25%</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of orders">
                    <td scope="row">
                        {{item.order.real_id}}
                    </td>
                    <td>
                        {{item.order.createdAt | date:' dd-MM-yyyy à HH:mm'}}
                    </td>
                    <td>
                        {{item.order.totalPrice.toFixed(2)}} {{devise}}
                    </td>
                    <td>
                        <span *ngIf="item.retenue1!=-1">{{(item.order.totalPrice
                            *(item.order.retenues[item.retenue1]?.value/100)).toFixed(2)}}
                            {{devise}}</span>
                        <span *ngIf="item.retenue1 == -1">0.00 {{devise}}</span>
                    </td>
                    <td>
                        <span *ngIf="item.retenue2 != -1">
                            {{item.retenue2.toFixed(2)}}
                            {{devise}}
                        </span>
                        <span *ngIf="item.retenue2 == -1">0.00 {{devise}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>
</section>