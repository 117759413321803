<section>
    <h3>
        {{ 'commun.mod' | translate }} {{ 'article.Mcatg.catg' | translate }}
        <span class="spinner spinner-sm" *ngIf="!category"></span>
    </h3>
    <div *ngIf="showMsg" class="alert alert-success" role="alert">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                </div>
                <span class="alert-text">
                    {{ 'commun.mds' | translate }}
                </span>
            </div>
        </div>
    </div>
    <br>
    <div class="alert alert-danger" role="alert" *ngIf="tailleInvalid">
        <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
            </div>
            <span class="alert-text">
            {{ 'commun.fv' | translate }}
            </span>
        </div>
        </div>
    </div>
    <br>
    <form clrForm *ngIf="category" (ngSubmit)="submit(categoryForm.value)" #categoryForm="ngForm">
        <clr-input-container>
            <label> {{ 'commun.id' | translate }} </label>
            <input clrInput type="text" name="id" [(ngModel)]="category.id" disabled />
        </clr-input-container>
        <clr-input-container>
            <label> {{ 'article.deta.nom' | translate }}*</label>
            <input clrInput type="text" name="name" [(ngModel)]="category.name" required />
            <clr-control-error> {{ 'reg.ob' | translate }} </clr-control-error>
        </clr-input-container>
        <!-- <clr-combobox-container>
            <label> {{ 'commun.type' | translate }} </label>
            <clr-combobox [(ngModel)]="category.details" name="details" clrMulti="true" required [clrLoading]="loading"
                (clrInputChange)="fetchDetails($event)" (clrOpenChange)="$event ? fetchDetails() : null">
                <ng-container *clrOptionSelected="let selected">
                    <clr-icon shape="details"></clr-icon>
                    {{selected?.name}}
                </ng-container>
                <clr-options>
                    <clr-option *clrOptionItems="let detail of details; field:'name'" [clrValue]="detail">
                        <clr-icon shape="details"></clr-icon>
                        {{detail.name}}
                    </clr-option>
                </clr-options>
            </clr-combobox>
            <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
        </clr-combobox-container> -->
        <clr-textarea-container>
            <label> {{ 'Points.desc' | translate }} </label>
            <textarea clrTextarea name="description" [(ngModel)]="category.description"></textarea>
        </clr-textarea-container>
        <div class="row">
            <label style="margin-top: 1.2rem; font-weight: 600;">Image</label>
            <div class="clr-col-12 clr-col-sm-12	clr-col-md-4	clr-col-lg-4	clr-col-xl-2">
                <a (click)="fileInput.click()" class="card">
                    <clr-icon class="plus-icon" shape="plus-circle"></clr-icon>
                </a>
                <input hidden type="file" #fileInput (change)="picked($event)">
            </div>
            <div class="clr-col-12 clr-col-sm-3	clr-col-md-4 clr-col-lg-4 clr-col-xl-2 img" *ngIf="pictureCategory">
                <div class="clickable">
                    <div class="card-img container">
                        <div class="clr-row clr-justify-content-between">
                            <div class="clr-col-4"></div>
                            <div class="clr-justify-content-end">
                                <span>
                                    <button type="button" class="btn btn-icon btn-warning btn-sm delete-icon" aria-label="warning"
                                        (click)="deletePicAction()">
                                        <clr-icon shape="times"></clr-icon>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <img [src]="pictureCategory">
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="btn-group btn-sm btn-outline-primary">
            <button class="btn" routerLink="../../">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                    class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                    <path
                        d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                </svg> {{ 'login.tt' | translate }}
            </button>
            <button type="submit" class="btn" [disabled]="categoryForm.invalid">
                <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-plus" viewBox="-1 -4 16 16">
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg> {{ 'reg.e' | translate }}
            </button>
        </div>
    </form>
</section>