import { Component, HostListener, OnInit } from '@angular/core';
import { User } from '../user';
import { QuotationService } from '../../sales/quotation/quotation.service';
import { FilterDto } from 'src/app/filter.dto';
import { ClrDatagridStateInterface } from '@clr/angular';
import { FilterService } from 'src/app/shared/filter.service';
import { ConfigService } from 'src/app/shared/config/config.service';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import {  getValueFromToken, tokenGetter } from '../user.service';
import { Abonnement } from 'src/app/setting/setting';
import { SettingService } from 'src/app/setting/setting.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  userArray: User[] = []
  tmpList: User[] = []
  showUser: User[] = []
  showAlert: boolean;
  filter: FilterDto = new FilterDto()
  count: number;
  userObject = new User();
  userId: number;
  openUserFilter: boolean;
  nameUser: boolean;
  alertUser: boolean;
  nbCount: number;
  triale: boolean;
  abonnee: boolean;
  id_entreprise: number
  current_user: number
  userRole: string
  abonnementData: Abonnement
  currentPage: number = 1
  nbr_page: number = 1
  total: number
  pageSize: number = 5
  insertIndex: number = 0
  type: string
  types: string[] = ["autorisation", "nom"]
  autorisations: string[] = ["responsable", "caissier", "livreur"]
  search_text: string
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private quotationService: QuotationService, private filterService: FilterService,
    private configService: ConfigService, private router: Router, private translate:TranslateService,
    private settingService: SettingService
  ) {
    this.userRole = getValueFromToken("role")
  }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.current_user = parseInt(getValueFromToken("id"))
    this.userId = getValueFromToken('id')
    this.triale = JSON.parse(getValueFromToken('triale'))
    this.abonnee = JSON.parse(getValueFromToken('abonee')) 
    this.filter.where = {}
    this.filter.take = this.pageSize
    this.getEntrepriseInfo();
    this.getQuotation()
  }
  search_user(event:Event) {
    let saisie = ((event.target as HTMLInputElement).value)
    if (this.search_text.length == 0) {
      this.userArray = []
      this.total = 1
      this.filter.where = {}
      this.getPaginatedData(1)
    } else {
      this.userArray = []
      this.tmpList.filter(x => {
        if (x.name.toLowerCase().indexOf(this.search_text.toLowerCase().trim()) != -1) {
          this.userArray.push(x);
        }
      })  
      this.total = this.userArray.length
      if (this.total > 0) {
        this.nbr_page = Math.ceil(this.total / this.pageSize)
      } else {
        this.nbr_page = 1
      }
      this.showUser = this.userArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
      if (this.userArray.length == 0 && saisie[saisie.length - 1] !== ' ') {
        this.filter.order = { "id": "ASC" }
        this.filter.take = this.pageSize;
        this.filter.where = { name: {
          type: "like",
          value: "*" + this.search_text.trim() + "*"
        }}
        this.getQuotation()
      }
    }
  }

  typeChanged(item: string) {
    this.type = this.translateItem(item)
    this.userArray = this.tmpList
    this.total = this.userArray.length
    if (this.total > 0) {
      this.nbr_page = Math.ceil(this.total / this.pageSize)
    } else {
      this.nbr_page = 1
    }
  }

  getEntrepriseInfo() {
    let filter = new FilterDto()
    filter.where = { "id": this.id_entreprise }
    // filter.relations = ["abonnementId"]
    this.settingService.getAllEntreprise(filter).subscribe(
      data => {
        // this.abonnementData = data[0][0].abonnementId
      }
    )
  }

  refresh(state: ClrDatagridStateInterface) {
    this.filter = this.filterService.bind(state);
    this.filter.relations = ["contactId"]
    this.filter.take = 10
    this.filter.order = { "id": "DESC" }
    let data = { active: true }
    if (!state.filters) {
      this.filter.where = data
    }
    else {
      this.filter.where = Object.assign(this.filter.where.valueOf(), data)
    }
    this.getQuotation();
  }
  getQuotation() {
    if (!this.userArray[this.insertIndex]){
      this.filter.where =Object.assign(this.filter.where.valueOf(), { 
        "entrepriseId": this.id_entreprise, "active": true 
      })
      this.quotationService.getUser(this.filter).subscribe(
        data => {
          data[0].forEach((el, i) => {
            this.userArray[this.insertIndex + i] = el;
          })
          this.count = data[1]
          this.total = data[1]
          this.showUser = this.userArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
          this.tmpList = this.userArray
          if (this.total > 0) {
            this.nbr_page = Math.ceil(this.total / this.pageSize)
          } else {
            this.nbr_page = 1
          }
        }
      )
    } else {
      this.showUser = this.userArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
    }
  }
  getPaginatedData(currentPage) {
    this.filter.skip = (currentPage - 1) * this.pageSize
    this.insertIndex = this.filter.skip ? this.filter.skip : 0
    this.getQuotation()
  }
  initialVariables(){
    this.userArray = []
    this.insertIndex = 0
    this.currentPage = 1
    this.filter.skip = 0
  }
  autorisationChanged(item: string) {
    if (item == "responsable") {
      this.filter.where = { "role": "responsable" };
    } else if (item == "caissier") {
      this.filter.where = { "role": "writer" };
    } else if (item == "livreur") {
      this.filter.where = { "role": "carrier" };
    }
    this.initialVariables()
    this.getQuotation()
  }
  addUser() {
    this.router.navigate(['/user/users/add-user']);
  }
  deleteAction(item) {
    this.showAlert = true;
    this.userObject = item
  }
  closeAction() {
    this.showAlert = false;
  }
  openFilter() {
    this.openUserFilter = true
  }
  nameUserFilter() {
    this.nameUser = true
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
  translateItem(item: string): string {
    return this.translate.instant(`sharedSales.${item}`);
  }
}
