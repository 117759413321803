import { Component, OnInit } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { CategoryClientService } from './category-client.service';
import { categoryClient } from './categoryClient';

@Component({
  selector: 'app-category-client',
  templateUrl: './category-client.component.html',
  styleUrls: ['./category-client.component.css']
})
export class CategoryClientComponent implements OnInit {
  filter: FilterDto<categoryClient> = new FilterDto<categoryClient>()
  categories: categoryClient[] = [];
  showCatClient: categoryClient[] = []
  loading: boolean;
  total: number;
  currentPage: number = 1
  pageSize: number = 5
  nbr_page: number
  insertIndex: number = 0
  selectedCategory: categoryClient
  showDetail: boolean;
  showAlert: boolean;
  constructor(private categoryClientService: CategoryClientService,) { }

  ngOnInit() {
    this.filter = new FilterDto()
    this.filter.where = { "active": true }
    this.filter.take = this.pageSize
    this.getCategoriesClient();
  }

  getCategoriesClient() {
    if (!this.categories[this.insertIndex]){
      this.categoryClientService.getCategoriesClient(this.filter).subscribe(
        data => {
          for (let i = 0; i < data[0].length; i++) {
            this.categories[this.insertIndex + i] = data[0][i];
          }
          this.showCatClient = this.categories.slice(this.insertIndex, this.insertIndex + this.pageSize)
          this.total = data[1];
          if (this.total > 0) {
            this.nbr_page = Math.ceil(this.total / this.pageSize)
          } else {
            this.nbr_page = 1
          }
        },
        err => console.error('Observer got an error: ' + err),
        () => this.loading = false
      );
    } else {
      this.showCatClient = this.categories.slice(this.insertIndex, this.insertIndex + this.pageSize)
    }
  }
  getPaginatedData(currentPage) {
    this.filter.skip = (currentPage - 1) * this.pageSize
    this.insertIndex = this.filter.skip ? this.filter.skip : 0
    this.getCategoriesClient()
  }
  detail(category: categoryClient) {
    this.selectedCategory = category
    this.showDetail = true;
  }
  delete(category: categoryClient) {
    this.selectedCategory = category
    this.showAlert = true;
  }
  close() {
    this.getCategoriesClient();
    this.showAlert = false;
    this.showDetail = false;
  }
  saved() {
    this.categories = []
    this.getCategoriesClient()
    this.showAlert = false;
  }
}
