<section>
  <div class="wrapper">
  <div class="alert alert-success" role="alert" *ngIf="success">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{ 'login.sc' | translate }}
        </span>
      </div>
    </div>
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="tailleInvalid || noCnx || usedMail || formatInvalid || noImage">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{erreurMsg}}
        </span>
      </div>
    </div>
  </div>
  <h3> {{ 'commun.jt' | translate }} {{ 'Paramtrage.Utilisateurs.u' | translate }}</h3>
  <form clrForm #userForm="ngForm">
    <clr-input-container>
      <label> {{ 'login.VotreNom' | translate }}*</label>
      <input clrInput type="text" ngModel name="name" required />
      <clr-control-error *clrIfError="'required'"> {{ 'reg.ob' | translate }} </clr-control-error>
    </clr-input-container>
    <clr-input-container>
      <label> {{ 'login.VotreEmail' | translate }}*</label>
      <input clrInput type="text" class="form-control" name="email" ngModel required email />
      <clr-control-error *clrIfError="'required'">{{ 'reg.ob' | translate }} </clr-control-error>
      <clr-control-error>
        {{ 'commun.ad' | translate }} {{ 'commun.nv' | translate }}
      </clr-control-error>
    </clr-input-container>

    <!-- <clr-input-container> -->
    <div class="telInput">
      <label>Tel *</label>
      <input id="phone" type="tel" required
        pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" [(ngModel)]="phoneModel" [ngModelOptions]="{standalone: true}"/>
      <!-- <clr-control-error *clrIfError="'required'">{{ 'reg.ob' | translate }} </clr-control-error>
      <clr-control-error *clrIfError="'pattern'">
        {{ 'commun.nui' | translate }}
        {{ 'commun.nui' | translate }}</clr-control-error> -->
    </div>
    <!-- </clr-input-container> -->
    <!-- <clr-input-container>
      <label>    {{ 'commun.fax' | translate }} </label>
      <input clrInput ngModel clrInput type="number" name="fax"   pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" />
      <clr-control-error *clrIfError="'pattern'">   {{ 'commun.nui' | translate }}</clr-control-error>
    </clr-input-container> -->
    <clr-password-container>
      <label> {{ 'login.MDP' | translate }}*</label>
      <input clrPassword type="Password" placeholder="saisir votre mot de passe" ngModel name="password" required
        minlength="4" />
      <clr-control-error *clrIfError="'required'">{{ 'reg.ob' | translate }}</clr-control-error>
      <clr-control-helper> {{ 'commun.mic' | translate }} </clr-control-helper>
    </clr-password-container>
    <clr-password-container>
      <label> {{ 'commun.cmp' | translate }}*</label>
      <input clrPassword type="Password" placeholder="confimer votre mot de passe" ngModel name="repassword" required
        minlength="4" />
      <clr-control-error *clrIfError="'required'">{{ 'reg.ob' | translate }} </clr-control-error>
      <clr-control-helper> {{ 'commun.mic' | translate }} </clr-control-helper>
    </clr-password-container>
    <clr-radio-container clrInline>
      <label> {{ 'commun.Auto' | translate }}*</label>
      <clr-radio-wrapper>
        <input type="radio" clrRadio name="role" value="responsable" ngModel required />
        <label> {{ 'Paramtrage.Utilisateurs.resp' | translate }}</label>
      </clr-radio-wrapper>
      <clr-radio-wrapper *ngIf="type_abn == 'facturation_caisse' || type_abn=='trial' || type_abn =='premuim' ">
        <input type="radio" clrRadio name="role" value="writer" ngModel required />
        <label> {{ 'commun.tre' | translate }}</label>
      </clr-radio-wrapper>
      <clr-radio-wrapper *ngIf="type_abn == 'livraison' || type_abn=='trial' || type_abn=='premuim'">
        <input type="radio" clrRadio name="role" value="carrier" ngModel required />
        <label> Livreur</label>
      </clr-radio-wrapper>
      <!-- <clr-radio-wrapper>
        <input type="radio" clrRadio name="role" value="admin" ngModel required />
        <label> {{ 'commun.add' | translate }}</label>
      </clr-radio-wrapper> -->
      <clr-control-error>{{ 'reg.ob' | translate }}!</clr-control-error>
    </clr-radio-container>
    <div *ngIf="userForm.value.role == 'writer'">
      <clr-combobox-container>
        <label>Caisse *</label>
        <clr-combobox name="cratesid" required [clrLoading]="loading" (clrInputChange)="onSearchCrates($event)"
          placeholder="Caisse">
          <clr-options *ngIf="crates">
            <clr-option *ngFor="let item of crates" clrValue="{{item.name}}" (click)="itemChange(item)">
              {{item.name}}
            </clr-option>
          </clr-options>
          <clr-control-error>Ce champ est obligatoire!</clr-control-error>
        </clr-combobox>
      </clr-combobox-container>
      <clr-control-helper>
        <button id="btn_caisse" class="btn btn-link btn-sm" (click)="AddCaissePopUp()">
          Ajouter Caisse
        </button>
      </clr-control-helper>
    </div>
    <div *ngIf="userForm.value.role == 'responsable' || userForm.value.role == 'carrier' ">
      <clr-combobox-container>
        <label *ngIf="userForm.value.role == 'responsable'">Point de vente *</label>
        <label *ngIf="userForm.value.role=='carrier'">Lieu de ramassage (pick-up) *</label>
        <clr-combobox name="pointId" required [clrLoading]="loading" (clrInputChange)="onSearchPoint($event)"
          placeholder="point de vente">
          <clr-options *ngIf="points">
            <clr-option *ngFor="let item of points" clrValue="{{item.name}}" (click)="pointChange(item)">
              {{item.name}}
            </clr-option>
          </clr-options>
          <clr-control-error>Ce champ est obligatoire !</clr-control-error>
        </clr-combobox>
      </clr-combobox-container>
      <clr-control-helper>
        <button id="btn_caisse" class="btn btn-link btn-sm" (click)="AddPointPopUp()">
          <span *ngIf="userForm.value.role == 'responsable'">Ajouter point de vente</span>
          <span *ngIf="userForm.value.role == 'carrier'">Ajouter un lieu de ramassage (pick-up)</span>
        </button>
      </clr-control-helper>
    </div>
    <div *ngIf="userForm.value.role == 'carrier' ">
      <clr-combobox-container>
        <label>Véhicule *</label>
        <clr-combobox name="truckId" required [clrLoading]="loading" (clrInputChange)="onSearchTruck($event)"
          placeholder="vehicule">
          <clr-options *ngIf="trucks">
            <clr-option *ngFor="let item of trucks" clrValue="{{item.mark}}" (click)="truckChange(item)">
              {{item.mark}}
            </clr-option>
          </clr-options>
          <clr-control-error>Ce champ est obligatoire !</clr-control-error>
        </clr-combobox>
      </clr-combobox-container>
      <clr-control-helper>
        <button id="btn_caisse" class="btn btn-link btn-sm" (click)="AddTruckPopUp()">
          Ajouter véhicule
        </button>
      </clr-control-helper>
    </div>
    <div class="clr-row">
      <div class="clr-col-md-2">
        <clr-input-container>
          <label> {{ 'commun.ig' | translate }} {{ 'Paramtrage.Utilisateurs.u' | translate }} </label>
        </clr-input-container>
      </div>
      <div class="clr-col-md-6">
        <div class="img" *ngIf="url">
          <div class="card">
            <div class="card-img container">
              <img [src]="url">
            </div>
          </div>
        </div>
        <br>
        <cds-form-group layout="vertical">
          <cds-file>
            <label></label>
            <input type="file" (change)="picked($event)" />
          </cds-file>
        </cds-form-group>
        <br>
      </div>
    </div>
    <br><br>
    <div class="btn-sm btn-group btn-outline-primary">
      <button class="btn" routerLink="../">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
          class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
          <path
            d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
        </svg> {{ 'login.tt' | translate }}
      </button>
      <button [clrLoading]="validateBtnState" type="submit" (click)="submit(userForm.value,userForm)" class="btn"
        *ngIf="!addNew">
        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-plus" viewBox="-1 -4 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> {{ 'commun.jt' | translate }}
      </button>
      <button [clrLoading]="validateBtnState" (click)="resetForm(userForm)" class="btn" *ngIf="addNew"
        [disabled]="userForm.invalid">
        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-plus" viewBox="-1 -4 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> {{ 'commun.crn' | translate }}
      </button>
      <button class="btn" *ngIf="addNew" (click)="resetForm(userForm)">
        <clr-icon shape="times"></clr-icon> {{ 'commun.nn' | translate }}
      </button>
    </div>
  </form>
  </div>
</section>
<app-add-caisse-modal *ngIf="showAddCaisse" (saved)="getCaisses()" (closed)="CloseAction()"></app-add-caisse-modal>
<app-add-sales-point-modal *ngIf="showAddPoint" (saved)="getSalesPoint()" (closed)="CloseAction()"
  [type]="userForm.value.role"></app-add-sales-point-modal>
<app-add-modal-vehicule *ngIf="showAddTruck" (saved)="getNewTrucks()"
  (closed)="showAddTruck = false "></app-add-modal-vehicule>