import { Address, Customer } from "../parts/parts";
import { CostsType } from '../setting/type/type';
import { TransactionType } from "../setting/type/type";
import { Bank, Buying, Provider } from '../buying/buying';
import { Points } from "../sales-point/points";
import { Order } from "../sales/order/order";
import { User } from "../user/user";

export class Transaction {
  constructor(
    public id?: number,
    public amount?: number,
    public isCompleted?: boolean,
    public isCredit?: boolean,
    public isPayed?: boolean,
    public sourceId?: number,
    public ban?: string,
    public checkNumber?: string,
    public checkImage?: string,
    public dueDate?: Date,
    public isOver?: boolean,
    public operationNumber?: number,
    public createdAt?: string,
    public createdBy?: number,
    public active?: boolean,
    public description?: string,
    public updatedAt?: string,
    public name?: string,
    public updatedBy?: number,
    public ordreNumber?: number,
    public cratesId?: number,
    public transactionTypeId?: number & TransactionType,
    public customerId?: number & Customer,
    public UserId?: number & User,
    public userId?: number,
    public providerId?: number & Provider,
    public orderId?: number & Order,
    public bankId?: number | Bank,
    public buyingId?: number & Buying,
    public planningId?: number,
    public adressId?: number | Address,
    public costTypeId?: number & CostsType,
    public isVirement?: boolean,
    public dateVirement?: Date,
    public virementImage?: string,
    public TypeTransaction?: string,
    public entrepriseId?: number,
    public real_id?: number,
    public pointId?: number & Points,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean,
    public avance ?:number,
    public reste ?:number
  ) { }
}

export class TransactionDetail {
  constructor(
    public id?: number,
    public amount?: number,
    public isCompleted?: boolean,
    public updatedAt?: string,
    public name?: string,
    public createdAt?: string,
    public active?: boolean,
    public cratesId?: number,
    public transactionTypeId?: number,
    public customerId?: number,
    public providerId?: number,
    public orderId?: number,
    public bankId?: number,
    public createdBy?: number,
    public checkImage?: string,
    public checkNumber?: number,
    public dueDate?: Date,
    public updatedBy?: number,
    public buyingId?: number,
  ) { }
}
export class CardData {
  constructor(
    public transactionAmount?: number,
    public transactionNumber?: number,
    public incomeToday?: number,
  ) { }
}

export class ChequeCard {
  constructor(
    public expiredAmount?: number,
    public expiredNumber?: number,
    public notPayedAmount?: number,
    public notPayedNumber?: number,
  ) { }
}

export class TransactionCredit {
  transaction?: Transaction;
  restCredit?: number
}

export class ChiffreAffaire {
  constructor(
    public month?: number,
    public amount?: number
  ) { }
}

