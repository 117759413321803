import { BlogsService } from './../../blogs.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-blog-delete',
  templateUrl: './blog-delete.component.html',
  styleUrls: ['./blog-delete.component.css']
})
export class BlogDeleteComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  @Input() idBlog: number;
  real_id: number
  constructor(private blogsService: BlogsService) { }

  ngOnInit(): void {
    this.blogsService.getBlogs({where: { "id": this.idBlog }, select: ["real_id"]}).subscribe(
      data => {
        this.real_id = data[0][0].real_id;
      }
    )
  }
  save() {
    this.blogsService.deleteBlog(this.idBlog).subscribe(
      res => {
        this.saved.emit(true)
      }
    )
  }
  close() {
    this.closed.emit(true)
  }
}
