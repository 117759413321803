import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkComponent } from './mark/mark.component';
import { CatalogRoutingModule } from './catalog-routing.module';
import { ClarityModule } from '@clr/angular';
import { MarkAddComponent } from './mark/mark-add/mark-add.component';
import { MarkDetailComponent } from './mark/mark-detail/mark-detail.component';
import { MarkEditComponent } from './mark/mark-edit/mark-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkDeleteComponent } from './mark/mark-delete/mark-delete.component';
import { ProductDeleteComponent } from './product/product-delete/product-delete.component';
import { ProductAddComponent } from './product/product-add/product-add.component';
import { ProductDetailComponent } from './product/product-detail/product-detail.component';
import { ProductEditComponent } from './product/product-edit/product-edit.component';
import { ProductComponent } from './product/product.component';
import { TaxComponent } from './tax/tax.component';
import { TaxAddComponent } from './tax/tax-add/tax-add.component';
import { TaxDetailComponent } from './tax/tax-detail/tax-detail.component';
import { TaxEditComponent } from './tax/tax-edit/tax-edit.component';
import { TaxDeleteComponent } from './tax/tax-delete/tax-delete.component';
import { UnityComponent } from './unity/unity.component';
import { UnityAddComponent } from './unity/unity-add/unity-add.component';
import { UnityDetailComponent } from './unity/unity-detail/unity-detail.component';
import { UnityEditComponent } from './unity/unity-edit/unity-edit.component';
import { UnityDeleteComponent } from './unity/unity-delete/unity-delete.component';
import { DetailComponent } from './detail/detail.component';
import { DetailAddComponent } from './detail/detail-add/detail-add.component';
import { DetailDetailComponent } from './detail/detail-detail/detail-detail.component';
import { DetailDeleteComponent } from './detail/detail-delete/detail-delete.component';
import { DetailEditComponent } from './detail/detail-edit/detail-edit.component';
import { CategoryComponent } from './category/category.component';
import { CategoryAddComponent } from './category/category-add/category-add.component';
import { CategoryDetailComponent } from './category/category-detail/category-detail.component';
import { CategoryEditComponent } from './category/category-edit/category-edit.component';
import { CategoryDeleteComponent } from './category/category-delete/category-delete.component';
import { SharedModule } from '../shared/shared.module';
import { PrintProductListComponent } from './product/print-product-list/print-product-list.component';
import { AddCategoryModalComponent } from './product/add-category-modal/add-category-modal.component';
import { AddMarqueModalComponent } from './product/add-marque-modal/add-marque-modal.component';
import { AddUnityModalComponent } from './product/add-unity-modal/add-unity-modal.component';
import { AddTaxModalComponent } from './product/add-tax-modal/add-tax-modal.component';

import { TranslateModule } from '@ngx-translate/core';
import { AddTypeModalComponent } from './product/add-type-modal/add-type-modal.component';
import { AddModalTypeComponent } from './category/add-modal-type/add-modal-type.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeletePackComponent } from '../pack/delete-pack/delete-pack.component';
import { PackEditComponent } from '../pack/pack-edit/pack-edit.component';
import { PackAddComponent } from '../pack/pack-add/pack-add.component';
import { PackEditProductComponent } from '../pack/pack-edit-product/pack-edit-product.component';
import { DetailPackComponent } from '../pack/detail-pack/detail-pack.component';
import { PackComponent } from '../pack/pack.component';
import { ModalAddProductComponent } from '../pack/pack-add/modal-add-product/modal-add-product.component';
import { AlertSuccessModalComponent } from './mark/alert-success-modal/alert-success-modal.component';
import { CategoryServiceComponent } from './category-service/category-service.component';
import { ServiceComponent } from './service/service.component';
import { CategoryServiceAddComponent } from './category-service/category-service-add/category-service-add.component';
import { CategoryServiceDetailComponent } from './category-service/category-service-detail/category-service-detail.component';
import { CategoryServiceDeleteComponent } from './category-service/category-service-delete/category-service-delete.component';
import { CategoryServiceEditComponent } from './category-service/category-service-edit/category-service-edit.component';
import { ServiceAddComponent } from './service/service-add/service-add.component';
import { ServiceDetailComponent } from './service/service-detail/service-detail.component';
import { ServiceDeleteComponent } from './service/service-delete/service-delete.component';
import { ServiceEditComponent } from './service/service-edit/service-edit.component';
import { AddCategoryServiceModalComponent } from './service/add-category-service-modal/add-category-service-modal.component';
import { CategoryClientComponent } from './category-client/category-client.component';
import { CategoryClientAddComponent } from './category-client/category-client-add/category-client-add.component';
import { CategoryClientEditComponent } from './category-client/category-client-edit/category-client-edit.component';
import { CategoryClientDetailComponent } from './category-client/category-client-detail/category-client-detail.component';
import { CategoryClientDeleteComponent } from './category-client/category-client-delete/category-client-delete.component';

@NgModule({
  declarations: [MarkComponent, MarkAddComponent, MarkDetailComponent, MarkEditComponent, MarkDeleteComponent,
    ProductComponent, ProductAddComponent, ProductDetailComponent, ProductEditComponent, ProductDeleteComponent,
    TaxComponent, TaxAddComponent, TaxDetailComponent, TaxEditComponent, TaxDeleteComponent,
    UnityComponent, UnityAddComponent, UnityDetailComponent, UnityEditComponent, UnityDeleteComponent,
    DetailComponent, DetailAddComponent, DetailDetailComponent, DetailDeleteComponent, DetailEditComponent,
    CategoryComponent, AddTypeModalComponent,
    CategoryAddComponent,
    AddModalTypeComponent,
    CategoryDetailComponent, CategoryEditComponent, CategoryDeleteComponent, PrintProductListComponent, AddCategoryModalComponent, AddMarqueModalComponent, AddUnityModalComponent, AddTaxModalComponent,
    PrintProductListComponent,
    AddCategoryModalComponent, AddMarqueModalComponent,
    AddUnityModalComponent, AddTaxModalComponent,
    DeletePackComponent,
    PackEditComponent,
    PackAddComponent,
    PackEditProductComponent,
    DetailPackComponent,
    PackComponent,
    ModalAddProductComponent,
    AlertSuccessModalComponent,
    CategoryServiceComponent,
    ServiceComponent,
    CategoryServiceAddComponent,
    CategoryServiceDetailComponent,
    CategoryServiceDeleteComponent,
    CategoryServiceEditComponent,
    ServiceAddComponent,
    ServiceDetailComponent,
    ServiceDeleteComponent,
    ServiceEditComponent,
    AddCategoryServiceModalComponent,
    CategoryClientComponent,
    CategoryClientAddComponent,
    CategoryClientEditComponent,
    CategoryClientDetailComponent,
    CategoryClientDeleteComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    CatalogRoutingModule,
    SharedModule,
    TranslateModule,
    NgbModule
  ],
  exports: [
    ProductComponent,
    CategoryComponent
  ]
})
export class CatalogModule { }
